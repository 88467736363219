<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>

<!-- cabeçalho da tela de cadastro -->
<app-header-step [activeStepHeader]="getCurrentStep()" [pulouMarcacaoDePulo]="!naoPulouMarcacaoDePulos()"
  (onNextStep)="handleNextStep()" (onPreviousStep)="handlePreviousStep()"></app-header-step>
<!-- passo 1 -->
<div *ngIf="getCurrentStep() == 1">
  <app-pesquisas-configuracoes [stepModel]="survey" (onChange)="handleStepChange()" (onDone)="handleStepDone()"
    (onCancel)="handleStepCancel()" (onSave)="handleStep1Save($event)" (onNewSave)="handleNewDataSurveyToSave($event)"
    (onCanNotSaveButton)="handleCanNotSaveButton($event)"></app-pesquisas-configuracoes>
</div>
<!-- passo 2 -->
<div *ngIf="getCurrentStep() == 2">
  <app-pesquisas-questionario [activeSectionElementData]="activeSectionElementData"
    [viewToken]="survey?.configuracaoPesquisa.tokenVisualizacao" [surveyTitle]="survey?.descricaoPesquisa.titulo"
    [stepModels]="extractQuestionnaireModelFromSurvey(survey)" (onChange)="handleStepChange()"
    (onDone)="handleStepDone()" (onCancel)="handleStepCancel()" (onSave)="handleStep2Save($event)"
    (onCanNotSaveButton)="handleCanNotSaveButton($event)">
  </app-pesquisas-questionario>
</div>
<!-- passo 3 -->
<div *ngIf="getCurrentStep() == 3">
  <app-pesquisas-logica [input]="survey" [currentIndex]="getCurrentStep()" (output)="handlePagesChanges($event)"
    (onNextStep)="handleNextStep()" (onChange)="handleStepChange()" (onDone)="handleStepDone()"
    (onCancel)="handleStepCancel()" (onSave)="handleStep3Save($event)"
    (onCanNotSaveButton)="handleCanNotSaveButton($event)">
  </app-pesquisas-logica>
</div>
<!-- passo 4 -->
<div *ngIf="getCurrentStep() == 4">
  <!-- atributo da tag app-pesquisas-localidades comentada -- (output)="handleChangeLocalidade($event)" -->
  <app-pesquisas-localidades [input]="pLocationOtp" [survey]="survey" [loadingSave]="loadingSave"
    (onDone)="handleStepDone()" (onChange)="handleStepChange()" (onCancel)="handleStepCancel()"
    (onSave)="handleStep4Save($event)" (onNewSave)="handleNewDataSurveyToSave($event)"
    (onCanNotSaveButton)="handleCanNotSaveButton($event)">
  </app-pesquisas-localidades>
</div>
<!-- passo 5 -->
<div *ngIf="getCurrentStep() == 5">
  <app-pesquisas-vinculos [survey]="survey" [localidade]="pLocationOtp" (onChange)="handleStepChange()"
    (onDone)="handleStepDone()" (onCancel)="handleStepCancel()" (onSave)="handleStep5Save($event)"
    (onNewSave)="handleNewDataSurveyToSave($event)" (onCanNotSaveButton)="handleCanNotSaveButton($event)">
  </app-pesquisas-vinculos>
</div>
<!-- passo 6 -->
<div *ngIf="getCurrentStep() == 6">
  <app-pesquisas-conclusao [surveyTitle]="survey.descricaoPesquisa.titulo"
    (selectedTitle)="handleSurveyStatusChange($event)" [research]="survey" (onChange)="handleStepChange()"
    (onDone)="handleStepDone()" (onCancel)="handleStepCancel()"></app-pesquisas-conclusao>
</div>
<!-- botões de voltar e próximo/finalizar -->
<div class="footer-buttons">
  <!-- previous -->
  <button class="footer-previous-button" [disabled]="!previousButtonEnabled" (click)="handlePreviousStep()">
    VOLTAR
  </button>
  <!--
    - existe uma verificação para o disabled no PASSO 3 é feita
    - uma validação para na ação do usuário de cadastrar uma lógica
    - desabilita o botão de avançar para evitar bugs
   -->
  <button *ngIf="getCurrentStep() !== 6" class="footer-next-button"
    [disabled]="getCurrentStep() === 3 ? canNotSaveButton : canNotSaveButton && !nextButtonEnabled"
    (click)="handleSave(getCurrentStep())">
    <span>AVANÇAR</span>
  </button>
  <!-- finish -->
  <button *ngIf="getCurrentStep() === 6" class="footer-next-button" (click)="handleFinishSteps()">
    <span *ngIf="surveyStatus === 'RASCUNHO'">FINALIZAR</span>
    <span *ngIf="surveyStatus !== 'RASCUNHO' && surveyMode === 1">EXECUTAR</span>
    <span *ngIf="surveyStatus !== 'RASCUNHO' && surveyMode === 2">FINALIZAR</span>
  </button>
</div>

<app-loading *ngIf="loadingSave"></app-loading>
<section class="main-container">
  <h3>{{questionData.ordem}} - {{ questionData.enunciado }}</h3>
  <app-questionnaire-badge [model]="badgeModel"></app-questionnaire-badge>

  <div class="question-wrapper">
    <div class="alternative-wrapper" *ngFor="let alternativa of questionData.alternativas; index as i">
      <app-alternativa-fechada *ngIf="alternativa.tipo_alternativa === tipoAlternativa.FECHADA"
        [renderType]="isMultiAnswers() ? 'checkbox' : 'radio'" [isChecked]="isChecked(alternativa.id)"
        (onCheck)="handleCheckProxy($event, tipoAlternativa.FECHADA, i)" [alternativa]="alternativa"
        [preSelect]="withDefaultAnswer(alternativa.id, alternativa.tipo_alternativa)"
        [selecionavel]="usuarioPodeSelecionarAlternativa"
        [actionsBlockedBySituation]="actionsBlockedBySituation"></app-alternativa-fechada>

      <div class="group-answers" *ngIf="alternativa.tipo_alternativa === tipoAlternativa.ABERTA_TEXTO">
        <app-alternativa-aberta-texto [renderCheckbox]="!isOnlyText" [selecionavel]="usuarioPodeSelecionarAlternativa"
          [isChecked]="isChecked(alternativa.id)" [renderType]="isMultiAnswers() ? 'checkbox' : 'radio'"
          (onChange)="handleChangeOpenAlternativeProxy($event, tipoAlternativa.ABERTA_TEXTO)"
          (onCheck)="handleCheckProxy($event, tipoAlternativa.ABERTA_TEXTO, i)" [alternativa]="alternativa"
          [value]="getAnswer(alternativa.id, alternativa.tipo_alternativa)"
          [preSelected]="withDefaultAnswer(alternativa.id, alternativa.tipo_alternativa)"
          [actionsBlockedBySituation]="actionsBlockedBySituation"></app-alternativa-aberta-texto>
      </div>

      <div class="group-answers" *ngIf="alternativa.tipo_alternativa === tipoAlternativa.ABERTA_NUMERO">
        <app-alternativa-aberta-numero
          (onChange)="handleChangeOpenAlternativeProxy($event, tipoAlternativa.ABERTA_NUMERO)"
          (onCheck)="handleCheckProxy($event, tipoAlternativa.ABERTA_NUMERO, i)" [isChecked]="isChecked(alternativa.id)"
          [alternativa]="alternativa" [value]="getAnswer(alternativa.id, alternativa.tipo_alternativa)"
          [selecionavel]="usuarioPodeSelecionarAlternativa"
          [actionsBlockedBySituation]="actionsBlockedBySituation"></app-alternativa-aberta-numero>
      </div>
    </div>
  </div>
  <app-questionnaire-answer-reset *ngIf="hasChanged" (reset)="resetAnswers()"></app-questionnaire-answer-reset>
</section>
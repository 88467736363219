import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IAlternative } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";

type RenderTypes = "checkbox" | "radio";

@Component({
  selector: "app-alternativa-fechada",
  templateUrl: "./fechada.component.html",
  styleUrls: ["./fechada.component.scss"],
})
export class AlternativaFechadaComponent implements OnInit {
  @Input() alternativa = {} as IAlternative;
  @Input() preSelect: boolean = false;
  @Input() renderType: RenderTypes = "radio";
  @Input() selecionavel: boolean = true;
  @Input() actionsBlockedBySituation: boolean = false;

  @Output() onCheck = new EventEmitter<{ id: number }>(null);

  @Input() isChecked: boolean = false;

  constructor() {}

  handleCheck(altId: number) {
    if (!this.selecionavel && !this.isChecked) {
      return;
    }

    this.onCheck.emit({ id: altId });
  }

  ngOnInit(): void {}
}

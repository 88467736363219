import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { PesquisaCadastro } from "app/modulos/pesquisa-old/cadastro/model/pesquisaCadastro";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { PesquisaSelecao } from "../pesquisaSelecao";

@Injectable({
  providedIn: "root",
})
export class PesquisaService extends CrudService<PesquisaCadastro> {
  public baseUrl: string = this.resourcePath;
  public apiLocationUrl: string = apiLocation;

  constructor(protected requestService: RequestService) {
    super(requestService, `${apiLocation}/pesquisas`);
  }

  private toQueryParams(statuses: string[] = [], arquivada: boolean = false) {
    const queryParams: any = { arquivada };
    if (statuses !== null || statuses.length !== 0) {
      queryParams.status = statuses;
    }

    return queryParams;
  }

  // tslint:disable-next-line: max-line-length
  buscarPorPalavrasChavesEStatus(
    palavraChave: PalavraChave,
    statuses: string[],
    arquivada: boolean,
    dadosPaginacao?: DadosPaginacao
  ): Observable<any[]> {
    const hasJoin = Array.isArray(statuses) && statuses.length > 0 && arquivada;
    let requestUrl = `${this.baseUrl}/filtrar/palavra-chave`;
    if (hasJoin) {
      requestUrl = `${this.baseUrl}/filtrar/palavra-chave/agrupado`;
    }
    const queryParams = this.toQueryParams(statuses, arquivada);
    const response = <Observable<any[]>>(
      this.requestService.post(
        requestUrl,
        palavraChave,
        true,
        dadosPaginacao,
        queryParams
      )
    );

    return response;
  }

  // tslint:disable-next-line: max-line-length
  getTotalPesquisasComStatus(
    statuses: string[],
    arquivada: boolean,
    palavraChave?: PalavraChave
  ): Observable<number> {
    const hasJoin = Array.isArray(statuses) && statuses.length > 0 && arquivada;
    let requestUrl = `${this.baseUrl}/total-registros`;
    if (hasJoin) {
      requestUrl = `${this.baseUrl}/filtrar/palavra-chave/agrupado/total-registros`;
    }
    const queryParams = this.toQueryParams(statuses, arquivada);
    return <Observable<number>>(
      this.requestService.post(
        requestUrl,
        palavraChave,
        true,
        null,
        queryParams
      )
    );
  }

  /**
   * Recupera as pesquisas que serão utilizadas para fins
   * de seleção, como em um select
   */
  getPesquisasSelecao(): Observable<PesquisaSelecao[]> {
    const requestUrl = `${apiLocation}/pesquisas/listar-pesquisas`;

    return this.requestService.get(requestUrl);
  }

  getPesquisasByStatus(status: string): Observable<any> {
    const requestUrl = `${apiLocation}/pesquisas/total?status=${status}`;

    return this.requestService.get(requestUrl);
  }

  /**
   * @author edmar
   * @returns retorna todas as RespostasPesquisa somente com seus status de auditada
   */
  getRespostaPesquisasByStatusAuditada(): Observable<any> {
    const requestUrl = `${apiLocation}/resposta-pesquisa`;

    return this.requestService.get(requestUrl);
  }

  /**
   * duplica uma pesquisa, dado o identificador da pesquisa original
   * @param id identificador da pesquisa a ser duplicada
   */
  postDuplicarPesquisa(id: number): Observable<any> {
    const requestUrl = `${apiLocation}/pesquisas/copia/${id}`;

    return <Observable<any>>this.requestService.post(requestUrl, true);
  }

  auditarPesquisa(id: number, auditada: boolean): Observable<{}> {
    const uri = `${apiLocation}/pesquisas/${id}/auditoria`;

    return this.requestService.put(
      uri,
      {},
      {
        auditada,
      }
    );
  }

  buscarEditar(id: any): Observable<PesquisaCadastro> {
    const requestPath = `${this.baseUrl}/buscar-editar/${id}`;
    return this.requestService.get(requestPath);
  }

  /**
   * função que retorna o token em formato amigável
   *
   * @param token: string
   * @returns {token_amigavel: string}
   */
  getFriendlyToken(token: string): Observable<any> {
    const requestPath = `${apiLocation}/generate-friendly-token?surveyToken=${token}`;
    return this.requestService.get(requestPath);
  }

  buscarReferencia(id: any): Observable<PesquisaSelecao> {
    const requestPath = `${apiLocation}/pesquisas/${id}/referencia`;
    return this.requestService.get(requestPath);
  }

  /**
   * Realiza uma solicitação de arquivamento de um conjunto
   * de pesquisas.
   * @param ids identificadores das pesquisas a serem arquivadas.
   */
  arquivarPesquisas(ids: number[]): Observable<{}> {
    const uri = `${apiLocation}/pesquisas/arquivar`;

    const payload = {
      pesquisas: ids,
    };

    return this.requestService.post(uri, payload);
  }

  /**
   * Atualiza o status da pesquisa
   */
  atualizarPesquisa(research: PesquisaCadastro) {
    const requestPath = `${this.baseUrl}/${research.id}`;
    const response = <Observable<any>>(
      this.requestService.put(requestPath, research)
    );
    return response;
  }

  removerPesquisas(ids: number[]) {
    const idsStr = ids.join(",");
    const requestPath = `${this.baseUrl}/${idsStr}`;
    const response = <Observable<any>>this.requestService.delete(requestPath);
    return response;
  }

  exportarPesquisa(
    idPesquisa: number,
    tipoExportacao: string,
    type: string
  ): Subject<boolean> {
    // /api/pesquisas/1/entrevistas/csv?tipo=CODIGO
    let uri = `${apiLocation}/pesquisas/${idPesquisa}/entrevistas/exportar?tipo=${tipoExportacao}&formato=${type}`;

    const showLoadingSubject: Subject<boolean> = new ReplaySubject();
    showLoadingSubject.next(true);

    this.requestService.getBlob(uri).subscribe({
      next: (response: HttpResponse<any>) => {
        const blob = new Blob([response.body], {
          type: response.headers.get("Content-Type"),
        });

        const contentDisposition = response.headers.get("Content-Disposition");

        let fileName = "entrevistas";

        const warnMessage = `
          A resposta à requisição de exportação
          das entrevistas em formato CSV não definiu
          um nome para o arquivo para download. Verificar
          no servidor se o cabeçalho "Content-Disposition=attachment;filename=FILE_NAME"
          está sendo definido de forma correta.
        `;

        // regex com o pattern: {qualquer_string}filename={NOME_DO_ARQUIVO}
        const contentDispositionPattern = /.*filename=([\s\S]*)/;

        // nenhum content-disposition foi definido ou não está no formato esperado
        if (
          !contentDisposition ||
          !contentDispositionPattern.test(contentDisposition)
        ) {
          console.warn(warnMessage);
        } else {
          const groups = contentDispositionPattern.exec(contentDisposition);

          if (groups.length === 1) {
            console.warn(warnMessage);
          }

          fileName = groups[1].trim();
        }

        const url = window.URL.createObjectURL(blob);
        const aElement = document.createElement("a");
        document.body.appendChild(aElement);
        aElement.href = url;
        aElement.download = fileName;
        aElement.click();
        window.URL.revokeObjectURL(url);
        aElement.remove();

        showLoadingSubject.next(false);
        // tslint:disable-next-line:align
      },
      error: (error) => {
        showLoadingSubject.next(false);
        throw new Error(`Erro no download do conteúdo exposto na uri ${uri}`);
      },
    });

    return showLoadingSubject;
  }

  /**
   * Exporta a pesquisa direto para o analyze
   * @param surveyId: id da pesquisa que será exportada
   */
  exportSurveyToAnalyze(surveyId: number): Observable<any> {
    const requestUrl = `${apiLocation}/pesquisas/${surveyId}/entrevistas/exportar-analyze`;

    return this.requestService.post(requestUrl, null);
  }
}

import { SituacaoListagemDeColetasParams, SituacaoPesquisa } from "../constant";
import { ICriterio } from "./audit-collections-section";

export interface IFindedLocationResponse {
  latitude: number;
  longitude: number;
  raio: number;
  geoId: string;
}

export interface IFindedCollectsResponse {
  id: number;
  latitude: number;
  longitude: number;
}

export interface ISurveyMapDetailsResponse {
  query?: {
    localidades_encontradas: number[];
    localidades_nao_encontradas: number[];
  };
  localidades: IFindedLocationResponse[];
  coletas: IFindedCollectsResponse[];
}

export interface ISurveyMapQuery {
  surveyId: number;
  id_operador?: number; // operator id
  localidades?: number[]; // location id's
  situacao?: SituacaoListagemDeColetasParams[]; // situação das coletas
  periodo_inicio?: string;
  periodo_fim?: string;
}

export interface ISurveyResumoColetaResponse {
  id: number;
  hora_inicio: string;
  hora_fim: string;
  data: string;
  situacao: string;
  operador: {
    nome: string;
  };
  localidade: {
    nome: string;
  };
  localizacao: {
    latitude: number;
    longitude: number;
  };
  criterios: ICriterio[];
  pesquisa?: {
    // opcional por enquanto porque só será implementado futuramente.
    situacao: SituacaoPesquisa;
    titulo_curto: string;
  };
}

export interface ICollectionSummaryQuery {
  surveyId: number;
  collectionId: number;
}

export interface IOperatorsListResponse {
  [key: number]: string;
}

export interface ILocationListResponse {
  id: number;
  nome: string;
}

export interface IDateListResponse {
  data_coleta: string;
}

export interface IOperatorsListModel {
  id: number;
  nome: string;
}

interface IColetas {
  id: number;
  latitude: number;
  longitude: number;
}

interface ILocalidades {
  latitude: number;
  longitude: number;
  raio: number;
  geoId: string;
}

interface IQuery {
  localidades_encontradas: Array<number>;
  localidades_nao_encontradas: Array<number>;
}

export interface IMapResponsePayload {
  coletas: IColetas[];
  localidades: ILocalidades[];
  query: IQuery;
}

export enum SituacaoTooltipMap {
  APROVADA = "Aprovada",
  EM_ANALISE = "Em Análise",
  NAO_AUDITADA = "Não Auditada",
  REPROVADA = "Reprovada",
}

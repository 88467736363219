import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IQuestion } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { AnswerOutput } from "../questions.component";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";

@Component({
  selector: "app-photo",
  templateUrl: "./photo.component.html",
  styleUrls: ["./photo.component.scss"],
})
export class PhotoComponent implements OnInit {
  @Input() questionData: IQuestion;
  @Output() onChange = new EventEmitter<AnswerOutput>(null);

  constructor() {}

  ngOnInit(): void {
    this.emitAnswer();
  }

  private emitAnswer() {
    this.onChange.emit({
      type: TipoPergunta.FOTO,
      questionId: this.questionData.id,
      answer: {
        texto: this.questionData.respostas.entrevistador as string,
        respondida: !!this.questionData.respostas.entrevistador
      },
    });
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { formsLocation } from "app/infraestrutura/apiLocation";
import { PesquisaService } from "app/modulos/pesquisa-beta/listagem/servico/pesquisas";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { ClipboardService } from "ngx-clipboard";
import { Status } from "../../../../../modulos/pesquisa-beta/listagem/pesquisas.component";
import { PesquisaRequest } from "../../pesquisas-cadastro.model";
import { SafeUrl } from "@angular/platform-browser";
import { TipoTokenPesquisa } from "./enum/tokenType";

interface SelectEvent {
  target: {
    value: Status;
  };
}

@Component({
  selector: "app-pesquisas-conclusao",
  templateUrl: "./pesquisas-conclusao.component.html",
  styleUrls: ["./pesquisas-conclusao.component.scss"],
})
export class PesquisasConclusaoComponent implements OnInit, OnChanges {
  //  Input para receber o titulo da pesquisa
  @Input() surveyTitle: string;
  @Input() research: PesquisaRequest;
  @Output() selectedTitle: EventEmitter<string> = new EventEmitter();
  @Output() onChange: EventEmitter<void> = new EventEmitter();

  // Estado usado para controlar valor do seletor de status da pesquisa
  selectStatusOption: Status = "RASCUNHO";

  // Estado para controlar a visibilidade da informação adicional do icone de informação
  isInfoVisible: boolean = false;

  // Estado temporário fake para representar a informção se a pesquisa tem coletas online ou não
  pesquisaTemColetasPresenciais: boolean = true;

  // Link do formulário online de ESCRITA
  writeFormLink: string = `${formsLocation}?token=`;

  // Link do formulário online de VISUALIZAÇÃO
  readOnlyFormLink: string = `${formsLocation}?token=`;

  // Variável para verificar se pesquisa não tem amostra online
  isOnlyPresencial: boolean = false;

  qrCodeDownloadLink: SafeUrl = "";

  constructor(
    private pesquisaService: PesquisaService,
    private clipboardService: ClipboardService,
    private notificatorService: NotificatorService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  /**
   * Atualiza as propriedades writeFormLink e readOnlyFormLink, de acordo com o tipo do token
   * passado por parâmetro
   * @param token token da pesquisa
   * @param tokenType tipo do token (READ, WRITE)
   * @returns 
   */
  private updateFriendlyToken(token: string, tokenType: TipoTokenPesquisa): Promise<{ success: boolean, error: any }> {
    return new Promise((resolve) => {

      const generateUrl = (token) => {
        return `${formsLocation}/${token.token_amigavel}`;
      };
  
      const buildStatusObject = (success, error) => {
        return {
          success,
          error
        };
      };

      const handleGeneratedFT = (token) => {
        if (TipoTokenPesquisa.WRITE.equals(tokenType)) {
          this.writeFormLink = generateUrl(token);
        } else {
          this.readOnlyFormLink = generateUrl(token);
        }

        resolve(buildStatusObject(true, null));
      };

      this.pesquisaService.getFriendlyToken(token).subscribe({
        next: (token) => handleGeneratedFT(token),
        error: (err) => {
          resolve(buildStatusObject(false, err));
        },
      });
    });

   
  }
  /**
   * Recupera o token para criar o link amigavel
   */
  ngOnChanges(): void {
    if (this.research) {
      const tokenWrite = this.research.configuracaoPesquisa.token;
      const tokenRead = this.research.configuracaoPesquisa.tokenVisualizacao;

      if (tokenWrite) {        
        this.updateFriendlyToken(tokenWrite, TipoTokenPesquisa.WRITE)
          .then((status) => {
            if (!status.success) {
              this.errorHandlerService.handleError(status.error, "Erro ao copiar link");

              return;
            }

            this.clipboardService.copy(this.writeFormLink);

            /**
             * O link de visualização deve ser gerado somente após a criação do link de escrita,
             * pois se as duas requisições ocorressem em paralelo, isso poderia gerar um
             * PSQLException duplicate-key value, pois o back-end pode gerar o mesmo friendly-token para o
             * token de visualização e de escrita, e a tabela que armazena esses FT tem uma constraint UNIQUE
             * onde valores repetidos não podem ser armazenados
             */
            if (tokenRead) {
              this.updateFriendlyToken(tokenRead, TipoTokenPesquisa.READ)
                .then((status) => {
                  if (!status.success) {
                    this.errorHandlerService.handleError(
                      status.error, 
                      "Ocorreu um erro ao gerar o link de visualização do questionário..."
                    );

                    return;
                  }
                });
            }
          });
      }

      
    }
  }

  ngOnInit() {
    if (
      this.research &&
      this.research.configuracaoPesquisa.amostrasOnline === 0
    ) {
      this.isOnlyPresencial = true;
    }
  }

  /**
   * Obtem a url para download do arquivo de qrcode
   * @param url: url da pesquisa
   */
  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  // Função para alterar o evento de seleção de uma nova opção do select
  handleSelectOption(event: any) {
    this.selectStatusOption = event.target.value;
    if (this.selectStatusOption === "EXECUCAO") {
      this.onChange.emit();
    }
    this.selectedTitle.emit(this.selectStatusOption);
  }

  // Função para alterar visibilidade da informação
  toggleInfoVisibility() {
    this.isInfoVisible = !this.isInfoVisible;
  }

  // função usada para copiar o link
  copyToClipboard() {
    this.clipboardService.copy(this.writeFormLink);
    this.notificatorService.showInfo(
      "Link copiado",
      "Compartilhe sua pesquisa"
    );
  }
}

<div class="questions-container">
  <ng-container *ngFor="let question of questionList">
    <container-element class="item-container" [ngSwitch]="question.tipo_pergunta">
      <ng-container *ngSwitchCase="'GRADE_UNICA'">
        <app-grid [actionsBlockedBySituation]="actionsBlockedBySituation" [questionData]="question"
          (onChange)="onChangeAnswer($event)"></app-grid>
      </ng-container>
      <ng-container *ngSwitchCase="'GRADE_MULTIPLA'">
        <app-grid [actionsBlockedBySituation]="actionsBlockedBySituation" [questionData]="question"
          (onChange)="onChangeAnswer($event)"></app-grid>
      </ng-container>
      <ng-container *ngSwitchCase="'ESCALA_NUMERICA'">
        <app-numeric-scale-question [questionData]="question" [actionsBlockedBySituation]="actionsBlockedBySituation"
          (onChange)="onChangeAnswer($event)"></app-numeric-scale-question>
      </ng-container>
      <ng-container *ngSwitchCase="'UNICA'">
        <app-generic-response-question [questionData]="question" [actionsBlockedBySituation]="actionsBlockedBySituation"
          (onChange)="onChangeAnswer($event)"></app-generic-response-question>
      </ng-container>
      <ng-container *ngSwitchCase="'MULTIPLA'">
        <app-generic-response-question [questionData]="question" [actionsBlockedBySituation]="actionsBlockedBySituation"
          (onChange)="onChangeAnswer($event)"></app-generic-response-question>
      </ng-container>
      <ng-container *ngSwitchCase="'DATA'">
        <app-date [questionData]="question" [actionsBlockedBySituation]="actionsBlockedBySituation"
          (onChange)="onChangeAnswer($event)"></app-date>
      </ng-container>
      <ng-container *ngSwitchCase="'FOTO'">
        <app-photo [questionData]="question" (onChange)="onChangeAnswer($event)"></app-photo>
      </ng-container>
      <ng-container *ngSwitchCase="'VIDEO'">
        <app-video-question [model]="question" (onChange)="onChangeAnswer($event)"></app-video-question>
      </ng-container>
      <ng-container *ngSwitchCase="'HORARIO'">
        <app-pergunta-horario [questionData]="question" [actionsBlockedBySituation]="actionsBlockedBySituation"
          (onChange)="onChangeAnswer($event)"></app-pergunta-horario>
      </ng-container>
    </container-element>
    <span class="line"></span>
  </ng-container>
</div>
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuditoriaObservationsModalService } from "app/componentes/audit-observations/modal-observations.service";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import {
  FilterConfigs,
  SearchInputConfig,
} from "app/util/componente/genericTable/interfaces/filters";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import {
  TableEvents,
  TableGlobalConfig,
} from "app/util/componente/genericTable/interfaces/table";
import {
  iconCellFactory,
  statusCellFactory,
  textCellFactory,
} from "app/util/componente/genericTable/utils/cellFactory";
import { tHeadFactory } from "app/util/componente/genericTable/utils/tHeadFactory";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";
import { IOperadorData } from "../interfaces";
import { ProdutividadeBetaService } from "../services/produtividade-beta.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { errorMessages } from "../constants/listagem-operadores";
import {
  CollectionSortFilter,
  IFilter,
  IOperatorCollection,
  IPagination,
  ProdutividadeSituacaoOperadorColetas,
  ProdutividadeSituacaoOperadorColetasNomeAmigavel,
} from "../interfaces/listagem";
import { tRowFactory } from "app/util/componente/genericTable/utils/tRowFactory";
import { DirectionOrderTypes } from "app/modulos/cliente-beta/constants/listagem";
import { getFirstAndLastDate } from "../utils/extractCollectionDate";
import dates from "app/util/misc/dates";

@Component({
  selector: "app-detalhes-operador",
  templateUrl: "./detalhes-operador.component.html",
  styleUrls: ["./detalhes-operador.component.scss"],
})
export class DetalhesOperadorComponent implements OnInit {
  surveyId: number;
  collectionId: number;
  operatorId: number;
  surveyTitle: string = null;
  dataBreadcrumb: IItemBreadcrumb[] = [];
  observationsModal: ModalData = null; // Estado da modal de observações.
  operador: IOperadorData = null;
  isLoading: boolean = false;
  collections: IOperatorCollection[] = [];
  tableData: TableRow[] = [];
  filtersFlag: boolean = false;
  totalCollections: number = 0;

  filters: IFilter = {
    sorting: { direction: null, sort: null },
    keywords: [""],
    status: [],
    date: { periodo_inicio: null, periodo_fim: null },
  };

  pagination: IPagination = {
    current: 1,
    previus: 1,
  };

  tHeadOperadorColetas: TableHead[] = [
    tHeadFactory("ID").build(),
    tHeadFactory("Localidade").build(),
    tHeadFactory("Data").build(),
    tHeadFactory("Início").build(),
    tHeadFactory("Fim").build(),
    tHeadFactory("Duração").build(),
    tHeadFactory("Situação").build(),
    tHeadFactory("Observações")
      .cellAlign("center")
      .cellStyles((cs) => {
        if (cs.metadata === 0) {
          return {
            color: "var(--gray200)",
            "pointer-events": "none",
          };
        } else {
          return null;
        }
      })
      .build(),
  ];

  tableConfig: TableGlobalConfig = {
    headerHeight: "60px",
    colWidths: ["0.06", "0.25", "0.09", "0.09", "0.09", "0.1", "0.19", "0.13"],
    rowStyles: {
      fontSize: "14px",
    },
  };

  tableFilter: FilterConfigs[];

  searchConfig: SearchInputConfig = {
    placeholder: "Busque por Id ou localidade",
  };

  constructor(
    private route: ActivatedRoute,
    private produtividadeService: ProdutividadeBetaService,
    private observationsModalService: AuditoriaObservationsModalService,
    private errorService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.initObservationsModalData();
    this.surveyId = +this.route.snapshot.paramMap.get("surveyId");
    this.operatorId = +this.route.snapshot.paramMap.get("entrevistadorId");
    this.getAllOperatorCollections();
    this.getTotalCollections();
    this.getOperatorData();
    this.retrieveSurveyTitle();
  }

  // Atualiza o breadCrumb
  updateBreadCrumb() {
    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Produtividade",
        itemLink: "/produtividade-beta",
        active: false,
      },
      {
        itemName: this.surveyTitle,
        itemLink: `/produtividade-beta/visao-geral/${this.surveyId}/operadores/operador/${this.operatorId}`,
        active: true,
      },
    ];
  }

  startFilters() {
    const { inicial, final } = getFirstAndLastDate(this.collections);

    this.tableFilter = [
      {
        type: FilterTypes.CHECK,
        placeholder: "Situação",
        icon: FilterIcon.FUNNEL,
        options: [
          {
            id: 1,
            label: "Aprovada",
            key: ProdutividadeSituacaoOperadorColetas.APROVADA,
          },
          {
            id: 2,
            label: "Reprovada",
            key: ProdutividadeSituacaoOperadorColetas.REPROVADA,
          },
          {
            id: 3,
            label: "Não auditada",
            key: ProdutividadeSituacaoOperadorColetas.NAO_AUDITADA,
          },
          {
            id: 4,
            label: "Em análise",
            key: ProdutividadeSituacaoOperadorColetas.EM_ANALISE,
          },
        ],
      },
      {
        type: FilterTypes.RANGE_DATE,
        placeholder: "Data",
        icon: FilterIcon.CALENDAR,
        selectConfig: {
          intervalDate: {
            startDate: inicial,
            endDate: final,
          },
        },
      },
      {
        type: FilterTypes.RADIO,
        placeholder: "Ordenar",
        icon: FilterIcon.SORTING,
        options: [
          {
            id: 1,
            label: "Coletas mais recentes",
            key: DirectionOrderTypes.DESC,
            filter: CollectionSortFilter.DATA_REGISTRO,
          },
          {
            id: 2,
            label: "Coletas mais antigas",
            key: DirectionOrderTypes.ASC,
            filter: CollectionSortFilter.DATA_REGISTRO,
          },
          {
            id: 3,
            label: "Maior duração",
            key: DirectionOrderTypes.DESC,
            filter: CollectionSortFilter.TEMPO_DURACAO,
          },
          {
            id: 4,
            label: "Menor duração",
            key: DirectionOrderTypes.ASC,
            filter: CollectionSortFilter.TEMPO_DURACAO,
          },
        ],
      },
    ];

    this.filtersFlag = true;
  }

  getTotalCollections(): void {
    const { keywords, date } = this.filters;
    const payload = { palavrasChave: [...keywords] };
    this.isLoading = true;

    this.produtividadeService
      .getTotalOperatosCollections(
        this.surveyId,
        this.operatorId,
        payload,
        date
      )
      .subscribe({
        next: (data) => {
          if (typeof data === "number") {
            this.totalCollections = data;
          }
          this.isLoading = false;
        },
        error(err) {
          this.errorService.handleError(
            err,
            errorMessages.CollectionsTotalError.title
          );
          this.isLoading = false;
        },
      });
  }

  // recupera todas as coletas do operador
  getAllOperatorCollections() {
    this.isLoading = true;
    const { keywords, sorting, status, date } = this.filters;

    this.produtividadeService
      .getOperatorCollectionsList(
        this.surveyId,
        this.operatorId,
        this.pagination.current - 1,
        keywords,
        date,
        status,
        sorting.sort,
        sorting.direction
      )
      .subscribe({
        next: (resp: IOperatorCollection[]) => {
          const allCollections = resp && this.buildCollectionRow(resp);
          this.tableData = allCollections || [];
          this.collections = resp;
          this.isLoading = false;
        },
        error: (err) => {
          this.errorService.handleError(
            err,
            errorMessages.operatorListCollectionError.title
          );
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          if (!this.filtersFlag) {
            this.startFilters();
          }
        },
      });
  }

  // controi cada linha da tabela
  buildCollectionRow(collections: IOperatorCollection[]): TableRow[] {
    return collections.map((collection: IOperatorCollection) =>
      tRowFactory(
        textCellFactory(String(collection.id)).build(),
        textCellFactory(collection.localidade.nome).build(),
        textCellFactory(collection.data_registro).build(),
        textCellFactory(collection.inicio_coleta).build(),
        textCellFactory(collection.fim_coleta).build(),
        textCellFactory(collection.tempo_duracao).build(),
        statusCellFactory(
          this.setStatusCell(collection.situacao),
          ProdutividadeSituacaoOperadorColetasNomeAmigavel[collection.situacao],
          ""
        ).build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button").build()
      )
        .id(collection.id)
        .metadata({})
        .build()
    );
  }

  // estiliza a celula de status de acordo com a situação
  setStatusCell(situacao: ProdutividadeSituacaoOperadorColetas) {
    switch (situacao) {
      case ProdutividadeSituacaoOperadorColetas.REPROVADA:
        return "var(--status-error)";
      case ProdutividadeSituacaoOperadorColetas.APROVADA:
        return "var(--status-sucess)";
      case ProdutividadeSituacaoOperadorColetas.EM_ANALISE:
        return "var(--status-in-progress)";
      default:
        return "var(--gray200)";
    }
  }

  /**
   * Endpoint chamado para recuperar o titulo da pesquisa
   */
  retrieveSurveyTitle() {
    this.isLoading = true;
    this.produtividadeService.getSurveyTitle(this.surveyId).subscribe({
      next: ({ titulo }) => {
        this.surveyTitle = titulo;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.errorService.handleError(
          err,
          "Erro ao recuperar o titulo da pesquisa"
        );
      },
      complete: () => {
        this.updateBreadCrumb();
      },
    });
  }

  /**
   * Recupera os dados do operador
   */
  getOperatorData() {
    this.isLoading = true;
    this.produtividadeService
      .getOperatorData(this.surveyId, this.operatorId)
      .subscribe({
        next: (operator: IOperadorData) => {
          if (operator) {
            this.buildOperatorHeader(operator);
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.errorService.handleError(
            err,
            errorMessages.operatorHeadData.title
          );
          this.isLoading = false;
        },
      });
  }

  // constroi o objeto de operador para renderiar o header
  buildOperatorHeader(operator: IOperadorData) {
    this.operador = {
      id: operator.id,
      foto: operator.foto,
      nome: operator.nome,
      matricula: operator.matricula,
      coletas: {
        cumpridas: operator.coletas.cumpridas,
        auditadas: operator.coletas.auditadas,
        aprovadas: operator.coletas.aprovadas,
        reprovadas: operator.coletas.reprovadas,
      },
      dias_trabalhados: operator.dias_trabalhados,
      avaliacao_geral: operator.avaliacao_geral,
      tempo_medio: operator.tempo_medio,
    };
  }

  initObservationsModalData() {
    this.observationsModal = this.observationsModalService.modalData;
  }

  handleEventTable($event) {
    const { eventType } = $event;
    const eventTable = {
      ["CELL_ICON_CLICK"]: this.handleClickView.bind(this),
      ["CHECK_FILTER_CHANGED"]: this.handleChangeSituationFilter.bind(this),
      ["SEARCH_FILTER_CHANGED"]: this.handleTextChange.bind(this),
      ["RADIO_FILTER_CHANGED"]: this.handleChangeSortingFilter.bind(this),
      ["PAGINATION_CHANGED"]: this.changePage.bind(this),
      ["DATE_FILTER_CHANGED"]: this.selectRange.bind(this),
    };
    if (Object.keys(eventTable).includes(eventType)) {
      eventTable[eventType]($event);
    }
  }

  // abre a modal de observação
  handleClickView(event): void {
    this.observationsModal.show = true;
    this.collectionId = +event.rowId;
  }

  // metodo que realiza a filtragem pelo status da coleta
  handleChangeSituationFilter({ filterValue: { currentState } }): void {
    this.filters.status = [...currentState.map((v) => v.key)];
    this.getAllOperatorCollections();
    this.getTotalCollections();
  }

  // metodo que recebe o texto emitido pelo componente de input de texto
  public handleTextChange({ filterValue }): void {
    this.filters.keywords = [filterValue];
    this.getAllOperatorCollections();
    this.getTotalCollections();
  }

  // manipula filtro de ordenação
  public handleChangeSortingFilter({ filterValue }: TableEvents) {
    const [item] = filterValue;
    this.filters.sorting.direction = filterValue.length ? item.key : null;
    this.filters.sorting.sort = filterValue.length ? item.filter : null;
    this.getAllOperatorCollections();
  }

  // controla a paginação
  changePage({ requestedPage, previousPage }) {
    this.pagination.current = requestedPage;
    this.pagination.previus = previousPage;
    this.getAllOperatorCollections();
  }

  /**
   * Obtem a data inicial e final selecionada pelo usuário no filtro range_date
   */
  selectRange({ filterValue: { start, end } }) {
    this.filters.date.periodo_inicio = start
      ? dates.formatDateToString(start)
      : null;
    this.filters.date.periodo_fim = end ? dates.formatDateToString(end) : null;

    this.getAllOperatorCollections();
    this.getTotalCollections();
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { EventoTabela } from "app/util/componente/tabela/evento/eventoTabela";
import { TipoEventoTabela } from "app/util/componente/tabela/evento/tipoEventoTabela";
import { TabelaComponent } from "app/util/componente/tabela/tabela.component";
import { TrBody } from "app/util/componente/tabela/trbody/trBody";
import { Observable, of, Subject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { Entrevista } from "../../model/entrevista";
import { SortingOptions } from "../../model/sortingOptions";
import { EntrevistaService } from "../../service/entrevista.service";
import { TableHeader } from "../../tableHeader";
import { EntrevistaTableHeaders } from "./entrevistaTableHeaders";
import { EntrevistaTrBody } from "./model/entrevistaTrBody";
// tslint:disable-next-line: import-name
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import dates from "app/util/misc/dates";
import { PesquisaItem } from "../../../pesquisa-old/pesquisa-select/pesquisaItem";
import { PesquisaService } from "../../../pesquisa-old/servico/pesquisa.service";
import { LocalidadesModalComponent } from "../localidades-modal/localidades-modal.component";
import EventoTabelaEntrevista from "./eventoTabelaEntrevista";
import { TipoEventoTabelaEntrevista } from "./tipoEventoTabelaEntrevista";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";

@Component({
  selector: "app-entrevista-listagem-tabela",
  templateUrl: "./entrevista-listagem-tabela.component.html",
  styleUrls: ["./entrevista-listagem-tabela.component.scss"],
})
export class EntrevistaListagemTabelaComponent extends TabelaComponent {
  @Input() entrevistaService: EntrevistaService;
  tableHeaders: TableHeader[];

  @Input() pesquisa: PesquisaItem;

  ultimoDadoPaginacao: DadosPaginacao;
  totalResults: number = 0;

  @Input() pagina: number = DadosPaginacao.INITIAL_PAGE;

  buscaPalavrasChavesControl: FormControl;
  buscaPalavrasChavesTermos: string[] = [];

  @Output() eventEmitting: EventEmitter<EventoTabela> = new EventEmitter();
  @Output() onEventoTabelaEntrevista: EventEmitter<EventoTabelaEntrevista> =
    new EventEmitter();

  /**
   * event bus que este componente se subscreve para receber
   * eventos de refresh na tabela. O dado trafegado
   * trata-se apenas de um boolean indicando se o refresh
   * deve manter a paginação ou reiniciar toda a tabela.
   */
  @Input() atualizarTabelaSubject: Subject<boolean>;
  private atualizarTabelaSubscription: Subscription;

  pesquisaFormGroup: FormGroup;

  constructor(
    private pesquisaService: PesquisaService,
    private dialog: MatDialog
  ) {
    super();
    this.tableHeaders = this.carregarTrHeaders();
    this.buscaPalavrasChavesControl = new FormControl("");
  }

  ngOnInit() {
    this.initPesquisaSelecionadaFormGroup(this.pesquisa.id);

    if (this.atualizarTabelaSubject) {
      this.atualizarTabelaSubscription = this.atualizarTabelaSubject.subscribe(
        (manterPaginacao) => {
          // tslint:disable-next-line: max-line-length

          if (manterPaginacao) {
            this.recarregarTabelaMantendoPaginacao();
          } else {
            this.inicializarDadosTabela();
          }

          this.initPesquisaSelecionadaFormGroup(this.pesquisa.id);
        }
      );
    }

    this.registrarBuscaPalavraChaveEvento();
  }

  ngOnDestroy() {
    if (this.atualizarTabelaSubscription) {
      this.atualizarTabelaSubscription.unsubscribe();
    }
  }

  recarregarTabelaMantendoPaginacao() {
    this.inicializarDadosTabela(true);
    this.getTotalResultados().subscribe((total) => {
      this.totalResults = total;
      this.atualizarPaginacao(total, this.pagina, false);
    });
  }

  handlePaginaChanges() {
    this.ultimoDadoPaginacao = new DadosPaginacao(
      DadosPaginacao.DEFAULT_SIZE,
      this.pagina
    );
    this.recarregarTabelaMantendoPaginacao();
  }

  handlePesquisaChanges(changes) {
    const pesquisaChange: SimpleChange = changes.pesquisa;
    // tslint:disable-next-line: max-line-length
    this.initPesquisaSelecionadaFormGroup(pesquisaChange.currentValue.id);
    this.inicializarDadosTabela();
  }

  /**
   * Sobrescrita do onPaginationChange que apenas envia o evento de paginação delegando
   * para os componentes de cima que naveguem para a pagina (url) desejada, para atualizar a tabela.
   */
  onPaginationChange(eventoPaginacao: DadosPaginacao) {
    this.enviarEventoPaginacao(eventoPaginacao);
  }

  /**
   * Captura mudanças nos inputs deste componente
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.pagina && changes.pesquisa) {
      const pesquisaChange: SimpleChange = changes.pesquisa;
      this.initPesquisaSelecionadaFormGroup(pesquisaChange.currentValue.id);
      this.handlePaginaChanges();
      return;
    }

    if (changes.pagina) {
      this.handlePaginaChanges();
    }

    /**
     * Caso haja mudança na pesquisa selecionada,
     * ré-inicialize os dados da tabela.
     */
    if (changes.pesquisa) {
      this.handlePesquisaChanges(changes);
    }
  }

  /**
   * inicializa o formulário de auditoria da pesquisa (o toggle button
   * de auditada/não auditada da pesquisa).
   * @param idPesquisa identificador da pesquisa.
   */
  initPesquisaSelecionadaFormGroup(idPesquisa: number) {
    this.pesquisaService.buscarReferencia(idPesquisa).subscribe((pesquisa) => {
      this.pesquisaFormGroup = new FormGroup({
        auditada: new FormControl(pesquisa.auditada),
      });
    });
  }

  buildTrBody(entrevista: Entrevista): TrBody {
    const trBody = new EntrevistaTrBody();
    trBody.selected = false;
    trBody.id = entrevista.id.toString();
    trBody.dataColeta = this.toDate(entrevista.inicioColeta);
    trBody.inicioColeta = this.toTime(entrevista.inicioColeta);
    trBody.fimColeta = this.toTime(entrevista.fimColeta);
    trBody.operador = entrevista.nomeOperador;
    trBody.localidade = entrevista.nomeLocalidade;
    trBody.aprovado = entrevista.aprovada;
    trBody.auditado = entrevista.auditada;
    trBody.entrevista = entrevista;

    // tslint:disable-next-line: max-line-length
    const duracao = dates.periodBetween(
      entrevista.inicioColeta,
      "DD/MM/YYYY - HH:mm:ss",
      entrevista.fimColeta,
      "DD/MM/YYYY - HH:mm:ss"
    );
    trBody.duracao = duracao;

    return trBody;
  }

  /**
   * @override
   * carrega as entrevistas em uma lista de trBody
   */
  carregarTrBodies(): Observable<TrBody[]> {
    return this.search().pipe(
      map((entrevistas) => {
        const trBodies: TrBody[] = entrevistas.map((entrevista: Entrevista) => {
          return this.buildTrBody(entrevista);
        });
        return trBodies;
      })
    );
  }

  /**
   * extrai somente o tempo "hh:mm:ss" da expressao temporal passada.
   * @param dateTime expressao temporal em string no formato "DD/MM/YYYY hh:mm:ss"
   */
  toTime(dateTime: string): string {
    const onlyTime = dates.parse(dateTime).format("HH:mm:ss");
    return onlyTime;
  }

  /**
   * extrai somente a data "DD/MM/YYYY" da expressao temporal
   * passada.
   * @param dateTime expressao temporal em string no formato "DD/MM/YYYY hh:mm:ss"
   */
  toDate(dateTime: string): string {
    const onlyDate = dates.parse(dateTime).format("DD/MM/YYYY");
    return onlyDate;
  }

  /**
   * @override
   * recupera a quantidade total de resultados baseado nos filtros selecionados
   */
  getTotalResultados() {
    // tslint:disable-next-line: max-line-length
    return this.entrevistaService.getTotalEntidades(this.pesquisa.id, {
      palavrasChave: this.buscaPalavrasChavesTermos,
    });
  }

  search() {
    // tslint:disable-next-line:max-line-length
    const sort: SortingOptions[] = this.tableHeaders
      .filter((th) => th.sortingOptions)
      .map((th) => th.sortingOptions);

    const searchArgs = {
      sort,
      paginacao: this.ultimoDadoPaginacao,
      palavrasChaves: {
        palavrasChave: this.buscaPalavrasChavesTermos,
      },
    };

    if (!this.pesquisa || !this.pesquisa.id) {
      return of([]);
    }

    // tslint:disable-next-line:max-line-length
    const observable: Observable<Entrevista[]> =
      this.entrevistaService.aplicarFiltro(
        this.pesquisa.id,
        searchArgs.palavrasChaves,
        searchArgs.paginacao,
        searchArgs.sort
      );

    return observable;
  }

  aplicarFiltro(args: string) {
    const strTerms = args.trim();
    let terms = [];

    if (strTerms === "") {
      terms = [];
    } else {
      terms = strTerms.split(" ");
    }

    this.buscaPalavrasChavesTermos = terms;
  }

  sort(th: TableHeader) {
    if (!th.sorts) {
      return;
    }

    if (th.sortingOptions) {
      th.sortingOptions.switchOrientation();
    } else {
      const sortingOptions: SortingOptions = new SortingOptions(th.fieldPath);
      th.sortingOptions = sortingOptions;
    }
    // disabilitando ordenação combada até segunda ordem.
    this.disableOtherSortingTableHeaders(th);

    super.ngOnInit();
  }

  private disableOtherSortingTableHeaders(currentSortingTh: TableHeader) {
    this.tableHeaders = this.tableHeaders.map((currentTh) => {
      if (currentTh.key !== currentSortingTh.key) {
        currentTh.sortingOptions = null;
      }
      return currentTh;
    });
  }

  carregarTrHeaders(): TableHeader[] {
    const tableHeaderKeys: string[] = Object.keys(EntrevistaTableHeaders);
    // tslint:disable-next-line:max-line-length
    const tableHeaders: TableHeader[] = tableHeaderKeys.map(
      (k) => EntrevistaTableHeaders[k]
    );
    // tslint:disable-next-line:max-line-length
    return tableHeaders;
  }

  registrarBuscaPalavraChaveEvento() {
    this.buscaPalavrasChavesControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => {
        this.aplicarFiltro(val);
        this.inicializarDadosTabela();
      });
  }

  aprovarEntrevista(idEntrevista: number | string) {
    const aprovarEntrevistaEvento: EventoTabelaEntrevista = {
      tipo: TipoEventoTabelaEntrevista.APROVAR_ENTREVISTA,
      payload: idEntrevista,
    };

    this.onEventoTabelaEntrevista.emit(aprovarEntrevistaEvento);
  }

  rejeitarEntrevista(idEntrevista: number | string) {
    const rejeitarEntrevistaEvento: EventoTabelaEntrevista = {
      tipo: TipoEventoTabelaEntrevista.REJEITAR_ENTREVISTA,
      payload: idEntrevista,
    };

    this.onEventoTabelaEntrevista.emit(rejeitarEntrevistaEvento);
  }

  enviarEventoExclusaoItensSelecionados() {
    const idsEntrevistasARemover: string[] =
      this.getTodosIdsTrBodiesSelecionados();
    const eventoExclusao: EventoTabela = {
      tipo: TipoEventoTabela.EXCLUIR,
      payload: idsEntrevistasARemover,
    };
    // tslint:disable-next-line:max-line-length
    this.eventEmitter.emit(eventoExclusao);
  }

  enviarEventoDownload() {
    const idsEntrevistasABaixar: string[] =
      this.getTodosIdsTrBodiesSelecionados();

    const downloadEntrevistasEvento: EventoTabelaEntrevista = {
      tipo: TipoEventoTabelaEntrevista.DOWNLOAD_ENTREVISTAS,
      payload: {
        idsEntrevistas: idsEntrevistasABaixar,
        all: this.selecionarTodos,
      },
    };

    this.onEventoTabelaEntrevista.emit(downloadEntrevistasEvento);
  }

  /**
   * Callback executado quando o usuário clica no botão de visualizar uma entrevista.
   * A cada visualização, é enviado o evento com o identificador da entrevista e a ordem em que
   * a mesma se encontra
   * @param idEntrevista identificador da entrevista (e.g. RespostaPesquisa)
   * @param ordemEntrevista ordem específica da entrevista selecionada
   */
  onVisualizarEntrevista(
    idEntrevista: number | string,
    ordemEntrevista: number
  ) {
    const visualizarEntrevistaEvento: EventoTabelaEntrevista = {
      tipo: TipoEventoTabelaEntrevista.VISUALIZAR_ENTREVISTA,
      payload: idEntrevista,
      ordem: ordemEntrevista,
    };

    this.onEventoTabelaEntrevista.emit(visualizarEntrevistaEvento);
  }

  alterarStatusPesquisa(event: MatSlideToggleChange) {
    this.onEventoTabelaEntrevista.emit({
      tipo: TipoEventoTabelaEntrevista.AUDITAR_PESQUISA,
      payload: {
        auditada: event.checked,
      },
    });
  }

  /**
   * Verifica se a pesquisa selecionada possui entrevistas a serem exibidas
   */
  possuiEntrevistas() {
    if (this.trBodies.length > 0) {
      return true;
    }

    return (
      this.trBodies.length === 0 && this.buscaPalavrasChavesTermos.length !== 0
    );
  }

  /**
   * Exibe as entrevistas selecionadas num mapa.
   */
  showLocalidades() {
    const idsEntrevistasAExibir: string[] =
      this.getTodosIdsTrBodiesSelecionados();

    const entrevistasSelecionadas: Entrevista[] = this.trBodies
      // filtrando apenas as entrevistas selecionadas na tabela
      .filter(
        (tr: EntrevistaTrBody) => idsEntrevistasAExibir.indexOf(tr.id) > -1
      )
      // mapeando as trBody para aoenas as entrevistas
      .map((tr: EntrevistaTrBody) => {
        return {
          ...tr.entrevista,
          duracao: tr.duracao,
        };
      });

    // abrindo o modal passando as entrevistas selecionadas
    this.dialog.open(LocalidadesModalComponent, {
      data: {
        entrevistasSelecionadas,
        idPesquisa: this.pesquisa.id,
      },
    });
  }

  // Recupera todas as coletas para renderização no mapa
  getAllCollectionsForMap() {
    this.entrevistaService
      .aplicarFiltro(
        this.pesquisa.id,
        new PalavraChave([""]),
        new DadosPaginacao(this.totalResults, 0),
        []
      )
      .subscribe({
        next: (collectionsList) => this.showAllCollectionInMap(collectionsList),
      });
  }

  // Renderiza todas as coletas independente da seleção na listagem de auditoria
  showAllCollectionInMap(collectionsList: Entrevista[]) {
    const trBodies = collectionsList?.map((entrevista: Entrevista) => {
      const trBody = this.buildTrBody(entrevista) as EntrevistaTrBody;
      return {
        ...trBody.entrevista,
        duracao: trBody.duracao,
      };
    });
    this.dialog.open(LocalidadesModalComponent, {
      data: {
        entrevistasSelecionadas: trBodies,
        idPesquisa: this.pesquisa.id,
      },
    });
  }
}

<section [ngClass]="{
  'main-container': true,
  'blockActions': actionsBlockedBySituation
}">
  <h3>{{questionData.ordem}} - {{ questionData.tipo_pergunta }}</h3>
  <app-questionnaire-badge [model]="badgeModel"></app-questionnaire-badge>
  <div style="display: flex; gap: 16px;">
    <div #containerRef class="time-container">
      <div class="input-wrapper">
        <input #fullTimeInput class="hour-input" type="text" [(ngModel)]="value" (keyup)="handleDateInputChange()"
          (blur)="checkTimeConsistence()" />
        <div (click)="handleOpenSelector()">
          <i class="fa fa-clock-o clock-icon"></i>
        </div>
      </div>

      <div *ngIf="timePickerIsVisible" class="timepicker-container">
        <div class="timepicker-header">
          <span>Selecione o horário</span>
        </div>

        <div class="timeselector-wrapper">
          <div class="labels">
            <span>Horas</span>
            <span>Minutos</span>
          </div>

          <div class="time-inputs">
            <div style="position: relative">
              <section class="chevron-wrapper">
                <input #hourInput type="text" class="time-input" (focus)="handleHourInputFocus()"
                  (blur)="handleInputBlurProxy($event, 'hour')" [(ngModel)]="selectedHour"
                  (keyup)="handleHourInput()" />
                <span [class]="!hourSelectorIsVisible ?  'chevron' : 'chevron-rotate' ">
                  <i class="fa-regular fa-chevron-down"></i>
                </span>
              </section>
              <div class="time-selector" *ngIf="hourSelectorIsVisible">
                <option (mousedown)="handleOnSelectHour($event, hour)" *ngFor="let hour of hours; index as i"
                  [value]="hour">
                  {{ hour }} horas
                </option>
              </div>
            </div>

            <div class="time-input-separator">
              <span></span>
              <span></span>
            </div>

            <div style="position: relative">
              <section class="chevron-wrapper">
                <input #minuteInput type="text" class="time-input" (focus)="handleMinuteInputFocus()"
                  (keyup)="handleMinuteInput()" (blur)="handleInputBlurProxy($event, 'minute')"
                  [(ngModel)]="selectedMinute" />
                <span [class]="!minuteSelectorIsVisible ?  'chevron' : 'chevron-rotate' ">
                  <i class="fa-regular fa-chevron-down"></i>
                </span>
              </section>
              <div class="time-selector" *ngIf="minuteSelectorIsVisible">
                <option (mousedown)="handleOnSelectMinute($event, minute)" *ngFor="let minute of minutes; index as i"
                  [value]="minute">
                  {{ minute }} min
                </option>
              </div>
            </div>
          </div>
        </div>

        <div class="actions-wrapper">
          <app-button [btnStructure]="{
              fontWeight: 400,
              color: 'var(--gray400)',
              height: '24px',
              width: '73px',
              fontSize: '12px'
            }" [btnStyle]="'outline'" [textContent]="'Confirmar'" (clickEvent)="handleConfirm()"></app-button>
        </div>
      </div>
    </div>
  </div>
  <app-questionnaire-answer-reset *ngIf="hasChanged" (reset)="resetAnswer()"></app-questionnaire-answer-reset>
</section>
import { IMessages } from "../../auditoria-beta/interfaces";

export const errorMessages: IMessages = {
  interviewersListError: {
    title: "Erro ao carregar a listagem de entrevistadores",
    message: null,
  },
  interviewersTotalError: {
    title: "Erro ao recuperar total de entrevistadores",
    message: null,
  },
  CollectionsTotalError: {
    title: "Erro ao recuperar total de coletas",
    message: null,
  },
  operatorHeadData: {
    title: "Erro ao recuperar os dados do header do entrevistador",
    message: null,
  },
  operatorListCollectionError: {
    title: "Erro ao recuperar as coletas do entrevistador",
    message: null,
  },
};

export enum AssessmentColor {
  GREEN = "green-background",
  YELLOW = "yellow-background",
  RED = "red-background",
}

<section class="progress-container">
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1082 267" preserveAspectRatio="none" width="100%">
    <g opacity="0.3" clip-path="url(#clip0_3894_9213)">
      <path opacity="0.3"
        d="M1082.27 0H1059.64C1056.15 50.7126 1029.93 97.3721 985.506 128.43C966.502 141.712 945.7 151.226 923.481 156.988C923.001 193.588 925.174 230.547 929.919 267H1082.27V0Z"
        class="dynamic-fill" />
      <path opacity="0.1"
        d="M923.285 156.818C945.268 151.039 965.867 141.582 984.692 128.421C1028.8 97.5837 1054.97 51.3555 1058.76 1.05804C1058.76 0.699933 1058.76 0.349966 1058.77 0C1036.55 5.77038 1015.75 15.2764 996.746 28.5589C952.87 59.2339 926.761 105.128 922.756 155.117C852.096 173.266 767.51 154.335 681.28 99.4149C635.719 70.384 588.652 48.3198 541 33.4259V267H929.853C925.109 230.49 922.895 193.483 923.285 156.818Z"
        class="dynamic-fill" />
    </g>
    <g opacity="0.3" clip-path="url(#clip1_3894_9213)">
      <path opacity="0.3"
        d="M0.00021387 0H22.6259C26.1175 50.7126 52.3406 97.3721 96.7619 128.43C115.766 141.712 136.568 151.226 158.787 156.988C159.267 193.588 157.094 230.547 152.35 267H0.00021387V0Z"
        class="dynamic-fill" />
      <path opacity="0.1"
        d="M158.983 156.818C137 151.039 116.401 141.582 97.5757 128.421C53.4637 97.5837 27.2976 51.3555 23.5131 1.05804C23.5131 0.699933 23.5049 0.349966 23.4968 0C45.7156 5.77038 66.5182 15.2764 85.5222 28.5589C129.398 59.2339 155.507 105.128 159.512 155.117C230.172 173.266 314.758 154.335 400.988 99.4149C446.549 70.384 493.616 48.3198 541.268 33.4259V267H152.415C157.16 230.49 159.373 193.483 158.983 156.818Z"
        class="dynamic-fill" />
    </g>
    <defs>
      <clipPath id="clip0_3894_9213">
        <rect width="541.268" height="267" fill="white" transform="translate(541)" />
      </clipPath>
      <clipPath id="clip1_3894_9213">
        <rect width="541.268" height="267" fill="white" transform="matrix(-1 0 0 1 541.268 0)" />
      </clipPath>
    </defs>
  </svg>

  <span class="content">
    <div class="progress-info">
      <div class="info">
        <section>
          <h3>{{ progressInfo.title }}</h3>
          <div class="collections-data">
            <span>{{ auditedCollections }}</span>
            <span>{{ collectionsObjective }}</span>
          </div>
        </section>
        <p>{{ progressInfo.text }}</p>
      </div>
      <div class="bar">
        <p>{{auditCollectionsPercent}}%</p>
        <div class="progress-bar" (mouseenter)="handleShowTooltip($event)" (mouseleave)="handleHideTooltip()">
          <div class="progress-bar-value" [style.width.%]="auditCollectionsPercentBar"></div>
        </div>
      </div>
    </div>

    <div class="complete-audit">
      <h3>{{ renderAuditStats() }}</h3>
      <button *ngIf="!this.auditComplete" [disabled]="!checksConclusionIsValid()"
        (click)="handleConcludesAudit()">Concluir auditoria</button>
      <i *ngIf="this.auditComplete" class="fa-regular fa-check-double conclude-svg" alt="audit-conclude-icon"></i>
    </div>
  </span>

</section>

<app-tooltip-text></app-tooltip-text>
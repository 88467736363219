import { Component, Input } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import validadorArrayLengthMaiorMenor from "app/util/validador/validadorArrayLengthMaiorMenor";
import { AlternativaSelecionada } from "../../resposta-pergunta-listagem-tabela/model/alternativaSelecionada";
import { PerguntaEsquema } from "../../resposta-pergunta-listagem-tabela/model/perguntaEsquema";
import { RespostaPergunta } from "../../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { TituloGrade } from "../../resposta-pergunta-listagem-tabela/model/tituloGrade";
import { TipoRespostaPergunta } from "../../tipoRespostaPergunta";
import { RespostaComponent } from "../resposta.component";

@Component({
  selector: "app-grade-multipla",
  templateUrl: "./grade-multipla.component.html",
  styleUrls: ["./grade-multipla.component.scss"],
})
export class RespostaGradeMultiplaComponent extends RespostaComponent {
  @Input() private respostaPergunta: RespostaPergunta;
  @Input() pergunta: PerguntaEsquema;
  @Input() private alternativasSelecionadasFormGroup: UntypedFormGroup;
  @Input() private alternativasSelecionadas: UntypedFormArray;

  alternativasSelecionadasGrade: UntypedFormGroup;

  /**
   *
   * formGroup = {
   *    idTituloGrade: [ 1, 2, 3],
   *    idTituloGrade: []
   * }
   *
   */

  /**
   * Validadores que verificam a quantidade de alternativas selecionadas e conferem se batem a quantidade maxima ou minima permitida.
   */
  private maiorDoQueDeveria: any;
  private menorDoQueDeveria: any;

  ngOnInit() {
    super.ngOnInit();

    this.inicializarValidadores();

    this.alternativasSelecionadasGrade = this.toAlternativasSelecionadasGrade(
      this.pergunta.titulosGrades
    );

    this.alternativasSelecionadasGrade.valueChanges.subscribe((val) => {});
  }

  private inicializarValidadores() {
    this.maiorDoQueDeveria = validadorArrayLengthMaiorMenor(
      this.pergunta.qtdMaximaSelecionada,
      true
    );
    this.menorDoQueDeveria = validadorArrayLengthMaiorMenor(
      this.pergunta.qtdMinimaSelecionada,
      false
    );
  }

  private toAlternativasSelecionadasGrade(
    titulosGrade: TituloGrade[]
  ): UntypedFormGroup {
    const controls = titulosGrade
      .map((t) => {
        let controls = {};
        const idsAlternativas = this.getIdAlternativasByIdTituloGrade(t.id);
        controls[t.id] = new UntypedFormControl(idsAlternativas, [
          this.menorDoQueDeveria,
          this.maiorDoQueDeveria,
        ]);

        return controls;
      })
      .reduce((prev, next) => ({ ...prev, ...next }));

    const alternativasSelecionadasGrade = new UntypedFormGroup({
      ...controls,
    });

    return alternativasSelecionadasGrade;
  }

  private getAlternativasByIdTituloGrade(
    idTituloGrade: number
  ): AlternativaSelecionada[] {
    return this.respostaPergunta.alternativasSelecionadas.filter(
      (alternativa: AlternativaSelecionada) => {
        return alternativa.idTituloGrade === idTituloGrade;
      }
    );
  }

  private getIdAlternativasByIdTituloGrade(idTituloGrade: number): number[] {
    return this.alternativasSelecionadas.controls
      .filter((fg) => fg.value.idTituloGrade === idTituloGrade)
      .map((fg: UntypedFormGroup) => {
        return fg.value.idAlternativa;
      });
  }

  /**
   * Recupera o control de idAlternativas selecionadas de um titulo grade.
   * @param idTituloGrade
   */
  getTituloGradeControl(idTituloGrade: number): UntypedFormControl | any {
    const idsAlternativasSelecionadas = this.alternativasSelecionadasGrade.get(
      idTituloGrade.toString()
    );
  }

  hasErrors(idTituloGrade: number) {
    return (
      this.alternativasSelecionadasGrade.get(idTituloGrade.toString())
        .status === "INVALID"
    );
  }

  /**
   * @override
   * Sobrescrevendo método de RespostaComponent,
   * com o comportamento especializado deste componente
   * durante o disparo da ação "Próxima Pergunta"
   */
  onProximaPergunta() {
    if (this.alternativasSelecionadasGrade.invalid) {
      return;
    }
    const idsTitulosGrade: string[] = Object.keys(
      this.alternativasSelecionadasGrade.value
    );
    const alternativasSelecionadas: AlternativaSelecionada[] = idsTitulosGrade
      .map((idTituloGrade: string) => {
        const idsAlternativas: number[] =
          this.alternativasSelecionadasGrade.value[idTituloGrade];

        const alternativa = this.getAlternativasByIdTituloGrade(
          Number(idTituloGrade)
        );

        return idsAlternativas.map((idAlternativa: number, index: number) => {
          return <AlternativaSelecionada>{
            idTituloGrade: Number(idTituloGrade),
            idAlternativa: idAlternativa,
            id: alternativa[index]?.id,
            tipo: TipoRespostaPergunta.GRADE,
            ordem: alternativa[index]?.ordem,
          };
        });
      })
      .reduce(
        (prev: AlternativaSelecionada[], next: AlternativaSelecionada[]) =>
          prev.concat(next)
      );

    const respostaPergunta = new RespostaPergunta(
      this.pergunta,
      alternativasSelecionadas,
      this.respostaPergunta.num
    );

    this.notifyRespostaPergunta(respostaPergunta);
  }
}

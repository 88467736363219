import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { HeaderDateSelectComponent } from "app/componentes/filter-select/components/range-date/header-date-select/header-date-select.component";
import { IQuestion } from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import moment, { Moment } from "moment";
import { AnswerOutput } from "../questions.component";
import { CustomDateAdapter } from "./CustomAdapter";
import { dateFormatter } from "app/util/misc/dateFormatter";

const appDateFormat = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
  },
};

@Component({
  selector: "app-date",
  templateUrl: "./date.component.html",
  styleUrls: ["./date.component.scss"],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: appDateFormat,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class DateComponent implements OnInit, AfterViewInit {
  @ViewChild(MatDatepicker) picker: MatDatepicker<any>;

  @Input() questionData: IQuestion;
  @Output() onChange = new EventEmitter<AnswerOutput>(null);
  @Input() actionsBlockedBySituation: boolean = false;

  header = HeaderDateSelectComponent;
  selectedDate: Moment = null;
  isAnswerReseted: boolean = false;

  hasChanged: boolean = false;

  constructor() {}

  private get auditorAnswer() {
    const date: string = this.questionData.respostas.auditor as string;
    return dateFormatter.yMdToDmY(date) || null;
  }

  private get interviewerAnswer() {
    const date: string = this.questionData.respostas.entrevistador as string;
    return dateFormatter.yMdToDmY(date) || null;
  }

  private getAnswer(defaultAnswer?: string) {
    return defaultAnswer !== undefined
      ? defaultAnswer
      : this.auditorAnswer || this.interviewerAnswer;
  }

  private setHasChangedState(state: boolean) {
    if (this.hasChanged !== state) {
      this.hasChanged = state;
    }
  }

  private initAnswers(defaultAnswer?: string) {
    this.selectedDate = moment(
      this.getAnswer(defaultAnswer) as string,
      "DD/MM/YYYY"
    );

    this.emitAnswer();
  }

  public resetAnswer() {
    this.isAnswerReseted = true;
    this.setHasChangedState(false);
    this.initAnswers(this.interviewerAnswer as string);
  }

  ngOnInit(): void {
    this.initAnswers();
    this.selectedDate = moment(this.getAnswer() as string, "DD/MM/YYYY");

    if (typeof this.auditorAnswer === "string") {
      this.setHasChangedState(true);
    }
  }

  ngAfterViewInit() {
    this.picker.openedStream.subscribe(() => {
      setTimeout(() => {
        this.picker[
          "_componentRef"
        ].instance._calendar._userSelection.subscribe((event) => {
          this.selectedDate = event.value;
          this.handleConfirm();
          this.picker.close();
        });
      }, 0);
    });
  }

  emitAnswer() {
    this.onChange.emit({
      type: TipoPergunta.DATA,
      questionId: this.questionData.id,
      answer: {
        value: this.selectedDate
          ? this.selectedDate.format("DD/MM/YYYY")
          : null,
        respondida: !this.isAnswerReseted,
      },
    });
  }

  /**
   * Emite o valor selecionado ou digitado para o componente pai
   * e define o valor se hasSwaped para true para controlar quando
   * o valor inicial é alterado
   */
  handleConfirm() {
    this.isAnswerReseted = false;
    this.setHasChangedState(true);
    this.emitAnswer();
  }

  /**
   * Limpa a seleção e define como alterado para mostrar o valor original
   */
  handleClear() {
    this.isAnswerReseted = false;
    this.selectedDate = null;
    this.setHasChangedState(true);
    this.emitAnswer();
  }

  /**
   * Controla quando o usuário muda pelo input o valor da data, e emite
   * para o componente pai o novo valor
   */
  onChangeDate($event) {
    this.isAnswerReseted = false;
    this.setHasChangedState(true);
    this.emitAnswer();
  }
}

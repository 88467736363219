import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrCodeGeneratorService {
  private showQrCodeSubject = new BehaviorSubject<boolean>(false);
  private downloadQrCodeSubject = new BehaviorSubject<string>(null);
  
  public showQrCodeObservable = this.showQrCodeSubject.asObservable();
  public downloadQrCodeObservable = this.downloadQrCodeSubject.asObservable();

  constructor() {}

  mostrarQrCode() {
    this.showQrCodeSubject.next(true);
  }

  esconderQrCode() {
    this.showQrCodeSubject.next(false);
  }

  baixarQrCode(url: string) {
    this.downloadQrCodeSubject.next(url);
  }
  
}

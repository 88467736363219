<section>
  <header>
    <h3>Passo 2</h3>
    <p>Crie citações ou selecione um grupo já criado para consolidar</p>
  </header>
  <hr />
  <article>
    <label>Corrigir respostas selecionas por</label>
    <div class="input-container">
      <input (input)="onChange($event)" type="text" [ngClass]="{ error: errorStatus || groupTitle.length > 140 }"
        maxlength="141" [value]="groupTitle" />
      <div *ngIf="groupTitle.length && !choosedGroup && handleFilteredGroups().length" class="groups-suggest">
        <div *ngFor="let group of handleFilteredGroups()" class="group" (click)="setTitleOfGroup(group)">
          <p>{{ group.title }}</p>
          <label> {{ group.citation.length }} citações</label>
        </div>
      </div>
    </div>
    <div class="info-container">
      <span [ngClass]="{ 'span-error': errorStatus || groupTitle.length > 140 }" [style.visibility]="
          errorStatus || groupTitle.length > 140 ? 'visible' : 'hidden'
        ">
        {{ errorMessage }}
      </span>
      <span>{{ groupTitle.length }}/140</span>
    </div>
    <button [ngClass]="{ 'disabled-btn': btnBlockFlow()}" (click)="handleMatch()">
      Consolidar
    </button>
  </article>
  <footer>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 633 103" fill="none" width="100%" height="100%">
      <mask height="103" width="633" y="0" x="0" maskUnits="userSpaceOnUse" style="mask-type:alpha"
        id="mask0_4304_2415">
        <rect fill="#D9D9D9" height="103" width="633"></rect>
      </mask>
      <g mask="url(#mask0_4304_2415)">
        <g style="mix-blend-mode:multiply">
          <path d="M208.977 102.92H369.831V39.1751H105.3V166.656H208.977V102.92Z" class="svg-accent"></path>
        </g>
        <g style="mix-blend-mode:multiply">
          <path d="M83.358 39.1751H-5V166.648H83.358V39.1751Z" class="svg-accent"></path>
        </g>
        <g style="mix-blend-mode:multiply">
          <path d="M105.308 -0.657089H83.358V39.1752H105.308V-0.657089Z" class="svg-accent"></path>
        </g>
        <g style="mix-blend-mode:multiply">
          <path d="M633 39.1751H471.968V102.912H633V39.1751Z" class="svg-accent"></path>
        </g>
        <g style="mix-blend-mode:multiply">
          <path d="M471.976 -0.657089H369.831V39.1752H471.976V-0.657089Z" class="svg-accent"></path>
        </g>
      </g>
    </svg>
  </footer>
</section>
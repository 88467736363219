export const environment = {
  production: true,
  apiUrl: "https://beta.tensai.com.br/tensai/api",
  analyzeUrl: "https://beta.tensai.com.br/analyze",
  reporterUrl: "https://beta.tensai.com.br/reporter",
  formsUrl: "https://beta.tensai.com.br/forms",
  nominatinUrl: (busca) => `https://beta.tensai.com.br/nominatim/search?format=json&addressdetails=true&polygon_geojson=1&q=${busca}`,
  nominatimLookupUrl: (osm_ids: string) =>
    `https://beta.tensai.com.br/nominatim/lookup?osm_ids=${osm_ids}&format=json&addressdetails=true&polygon_geojson=1`,
  nominatinReverseUrl: (lat, long) => `https://beta.tensai.com.br/nominatim/reverse?format=json&lat=${lat}&lon=${long}&addressdetails=true&polygon_geojson=1`,
  viaCepUrl: (cep: string) => `https://viacep.com.br/ws/${cep}/json/`,
  show_errors: ['ERROR_USER'],
  movidesk: {
    path: 'https://chat.movidesk.com/Scripts/chat-widget.min.js',
    mdChatClientCode: '9E20CBEAA909424C956296BD4921C70B'
  }
};

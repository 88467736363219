/**
 * Simula um enum TipoTokenPesquisa
 */
export class TipoTokenPesquisa {
    static readonly READ = new TipoTokenPesquisa("READ");
    static readonly WRITE = new TipoTokenPesquisa("WRITE");

    private constructor(public readonly value: string) {}

    equals(other: TipoTokenPesquisa): boolean {
        return this.value === other.value;
    }
}
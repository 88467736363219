import {
  CellAlignTypes,
  CellStyleBehavior,
  CustomStyles,
  MultiStatusCellValue,
  TableCell,
  TooltipData
} from "../interfaces/tCell";

export function checkboxCellFactory() {
  const cell = new TableCell();

  cell.type = "checkbox";

  const o = {
    id: (v: number) => {
      cell.id = v;

      return o;
    },
    checked: (c: boolean) => {
      cell.checked = c;
      return o;
    },
    checkable: (c: boolean) => {
      cell.checkable = c;

      return o;
    },
    coordinates: (c: number[]) => {
      cell.coordinates = c;

      return o;
    },
    customStyles: (cs: CustomStyles) => {
      cell.customStyles = cs;
      return o;
    },
    alignType: (alignType: CellAlignTypes) => {
      cell.alignType = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      cell.styleBehavior = bh;

      return o;
    },
    build: () => cell,
  };

  return o;
}

export function imageCellFactory(imageUrl: string, text: string, id?: number) {
  const cell = new TableCell();

  cell.type = "image";
  cell.id = id;

  cell.imageValue = {
    image: imageUrl,
    label: text,
  };

  const o = {
    metadata: (m: any) => {
      cell.metadata = m;

      return o;
    },
    customStyles: (cs: CustomStyles) => {
      cell.customStyles = cs;
      return o;
    },
    alignType: (alignType: CellAlignTypes) => {
      cell.alignType = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      cell.styleBehavior = bh;

      return o;
    },
    build: () => cell,
  };

  return o;
}

export function iconCellFactory(
  icon?: string[],
  behavior?: "button" | "normal",
  dataTooltip?: TooltipData,
  id?: number
) {
  const cell = new TableCell();

  cell.type = "icon";
  cell.id = id;

  if (icon) {
    cell.iconValue = {
      icon: icon,
      behavior,
      dataTooltip,
    };
  }

  const o = {
    metadata: (m: any) => {
      cell.metadata = m;

      return o;
    },
    class: (className: string) => {
      cell.className = className;
      return o;
    },
    customStyles: (cs: CustomStyles) => {
      cell.customStyles = cs;
      return o;
    },
    alignType: (alignType: CellAlignTypes) => {
      cell.alignType = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      cell.styleBehavior = bh;

      return o;
    },
    build: () => cell,
  };

  return o;
}

export function statusCellFactory(
  color: string,
  label: string,
  background: string,
  id?: number
) {
  const cell = new TableCell();

  cell.type = "status";
  cell.id = id;

  cell.statusValue = {
    color,
    label,
    backgroundColor: background,
  };

  const o = {
    metadata: (m: any) => {
      cell.metadata = m;

      return o;
    },
    customStyles: (cs: CustomStyles) => {
      cell.customStyles = cs;
      return o;
    },
    alignType: (alignType: CellAlignTypes) => {
      cell.alignType = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      cell.styleBehavior = bh;

      return o;
    },
    build: () => cell,
  };

  return o;
}

export function multiStatusCellFactory(
  cellId?: number, ...options: MultiStatusCellValue[]
) {
  const cell = new TableCell();

  cell.type = "multi-status"
  cell.id = cellId;

  cell.multiStatusValue = [...options];

  const o = {
    metadata: (m: any) => {
      cell.metadata = m;

      return o;
    },
    customStyles: (cs: CustomStyles) => {
      cell.customStyles = cs;
      return o;
    },
    alignType: (alignType: CellAlignTypes) => {
      cell.alignType = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      cell.styleBehavior = bh;

      return o;
    },
    build: () => cell,
  };

  return o;
}

export function htmlTemplateCellFactory(template?: string, id?: number) {
  const cell = new TableCell();

  cell.type = "html-template"
  cell.id = id;
  cell.htmlTemplateValue = template;

  const o = {
    metadata: (m: any) => {
      cell.metadata = m;

      return o;
    },
    customStyles: (cs: CustomStyles) => {
      cell.customStyles = cs;
      return o;
    },
    alignType: (alignType: CellAlignTypes) => {
      cell.alignType = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      cell.styleBehavior = bh;

      return o;
    },
    build: () => cell,
  };

  return o;
}

export function textCellFactory(text: string, id?: number) {
  const cell = new TableCell();

  cell.type = "text";
  cell.id = id;

  cell.textValue = text;

  const o = {
    metadata: (m: any) => {
      cell.metadata = m;

      return o;
    },
    class: (className: string) => {
      cell.className = className;
      return o;
    },
    customStyles: (cs: CustomStyles) => {
      cell.customStyles = cs;
      return o;
    },
    alignType: (alignType: CellAlignTypes) => {
      cell.alignType = alignType;

      return o;
    },
    cellStyleBehavior: (bh: CellStyleBehavior) => {
      cell.styleBehavior = bh;

      return o;
    },
    build: () => cell,
  };

  return o;
}

export function meatballCellFactory() {
  const cell = new TableCell();

  cell.type = "meatball";

  const o = {
    build: () => cell,
  }

  return o;
}
export function componentCellFactory() {
  const cell = new TableCell();

  cell.type = "Component";
  cell.componentValue = {
    data: null,
    component: null
  }

  const o = {
    component: (component: any) => {
      cell.componentValue.component = component;
      return o
    },
    data: (data: any) => {
      cell.componentValue.data = data;
      return o
    },
    build: () => cell,
  };

  return o;
}

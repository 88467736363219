<header>
  <p> {{questionData.ordem}} - {{ questionData.enunciado }}</p>
  <div class="badges">
    <small *ngIf="questionData.obrigatoria" [ngClass]="{ error: !selected.length }">Pergunta obrigatória</small>
    <small [ngClass]="{ error: limitLineError }">Mínimo {{ questionData.definicoes.numero_minimo_respostas }} opção por
      linha</small>
    <small>Máximo {{ questionData.definicoes.numero_maximo_respostas }} opção por
      linha</small>
  </div>
</header>

<section #gridQuestion [ngClass]="{
    'margin-bottom': isOverflown.width,
    'margin-right': isOverflown.height
  }">
  <header>
    <span></span>
    <span *ngFor="let col of questionData.alternativas">{{
      col.enunciado
      }}</span>
  </header>

  <article [ngClass]="{
      't-body': true,
      'blockActions': actionsBlockedBySituation
    }" *ngFor="
      let row of questionData.definicoes.titulo_alternativas;
      let i = index
    ">
    <div class="line-quest">
      <span>{{ row.enunciado }}</span>
    </div>
    <div *ngFor="let col of questionData.alternativas" class="check-item">
      <div class="checkbox" [ngClass]="{
          selected: getIndex(col.id, row.id) >= 0,
          'default-value': getIndex(col.id, row.id, 'anwares-selected') >= 0
        }" (click)="handleGridSelect(row.id, col.id, i)" [style.borderRadius]="
          questionData.tipo_pergunta === 'GRADE_UNICA' ? '10px' : '4px'
        "></div>
    </div>
  </article>
</section>
<app-questionnaire-answer-reset *ngIf="hasChanged" (reset)="resetAnswers()"></app-questionnaire-answer-reset>
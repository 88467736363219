<div class="pesquisas-content">
  <app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>

  <!-- header -->
  <app-info-card [title]="infoCardData.title" [text]="infoCardData.text" [data]="infoCardData.data">
  </app-info-card>
  <!-- fim do header -->
  <div class="list-actions">
    <!-- header -->
    <div class="filtros">
      <ng-container *ngIf="pesquisasSelecionadas.length === 0; else showSurveyActions">
        <div class="busca">
          <input type="text" placeholder="Busque pelo título ou nome do cliente" (keyup)="handleInputChange($event)"
            [ngModel]="searchInput" [disabled]="isLoading" />
          <i class="fa-light fa-magnifying-glass"></i>
        </div>

        <app-filter-select [type]="filterConf.type" [icon]="filterConf.icon" placeholder="Situação"
          [options]="filterOptions" [selectedOptions]="selectedFilterOptions"
          (changeFilter)="handleSelectOptionClick($event)"
          [disabledOptions]="handleDisableOption()"></app-filter-select>

        <button class="new-survey-btn" type="button" [routerLink]="'/avaliacao/cadastro-beta'">
          <i class="fa-regular fa-plus"></i>
          <h3>Nova avaliação</h3>
        </button>
      </ng-container>

      <ng-template #showSurveyActions>
        <div class="deletar-e-arquivar">
          <button class="archive-surveys" (click)="handleMostrarPromptDeArquivarPesquisasSelecionadas()">
            <i class="fa-light fa-box-archive"></i>
            <span>Arquivar</span>
          </button>

          <button class="delete-surveys" (click)="handleMostrarPromptDeExcluirPesquisasSelecionadas()"
            [disabled]="disableSelectedSurveysPrompt()">
            <i class="fa-regular fa-trash"></i>
            <span>Excluir</span>
          </button>
        </div>

      </ng-template>
    </div>
    <!-- end of filtros -->
  </div>

  <section class="table-wrapper">
    <div class="table">
      <div class="thead">
        <div class="checkbox-th th">
          <div [ngClass]="areAllPesquisaSelected() ? 'checkbox checked' : 'checkbox'"
            (click)="handleMainCheckboxClick()">
            <i class="fa fa-check"></i>
          </div>
        </div>
        <div class="titulo-th th">
          <h4>Título da avaliação</h4>
        </div>
        <div class="clientes-th th">
          <h4>Cliente</h4>
        </div>
        <div class="presencial-th th">
          <h4>Presencial</h4>
        </div>
        <div class="online-th th">
          <h4>Online</h4>
        </div>
        <div class="status-th th">
          <h4>Situação</h4>
        </div>
      </div>
      <!-- TABELA REGULAR -->
      <div class="tbody" *ngIf="handleCanShowRegularTable()">
        <pesquisa-item #pesquisaitem class="pesquisa-item" *ngFor="let pesquisa of pesquisas" [pesquisaId]="pesquisa.id"
          [tituloCurto]="pesquisa.tituloCurto" [cliente]="pesquisa.cliente" [isDuplicated]="pesquisa.isDuplicated"
          [amostrasOnline]="pesquisa.amostrasOnline" [amostrasPresenciais]="pesquisa.amostrasPresenciais"
          [entrevistasOnlineRealizadas]="pesquisa.entrevistasOnlineRealizadas"
          [entrevistasPresenciaisRealizadas]="pesquisa.entrevistasPresenciaisRealizadas" [status]="pesquisa.status"
          [statusText]="pesquisa.statusTexto" [isPesquisaSelecionada]="isPesquisaSelecionada(pesquisa.id)"
          [estadoArquivada]="filterSelectorState.arquivado" [estadoRascunho]="pesquisa.status === 'RASCUNHO'"
          [estadoExecucao]="pesquisa.status === 'EXECUCAO'" [estadoConcluida]="pesquisa.status === 'CONCLUIDO'"
          [possuiAmostrasOnline]="pesquisa.amostrasOnline > 0" [possuiLocalidadeVinculo]="pesquisa.possuiVinculos"
          (onCheckboxClick)="handleCheckboxClick($event)"
          (click)="handleClickInsideDuplicatedItem($event, pesquisa.isDuplicated)"
          (onKebabClick)="handleKebabClick($event)"></pesquisa-item>
      </div>

      <!-- TABELA VIRTUAL -->
      <div class="tbody" *ngIf="handleCanShowVirtualTable()">
        <pesquisa-item #pesquisaitem class="pesquisa-item" *ngFor="let pesquisa of paginateVirtualPesquisa[currentPage]"
          [pesquisaId]="pesquisa.id" [tituloCurto]="pesquisa.tituloCurto" [cliente]="pesquisa.cliente"
          [isDuplicated]="pesquisa.isDuplicated" [amostrasOnline]="pesquisa.amostrasOnline"
          [amostrasPresenciais]="pesquisa.amostrasPresenciais"
          [entrevistasOnlineRealizadas]="pesquisa.entrevistasOnlineRealizadas"
          [entrevistasPresenciaisRealizadas]="pesquisa.entrevistasPresenciaisRealizadas" [status]="pesquisa.status"
          [isFlagged]="pesquisa.isFlagged ? pesquisa.isFlagged : null" [statusText]="pesquisa.statusTexto"
          [isPesquisaSelecionada]="isPesquisaSelecionada(pesquisa.id)"
          [estadoArquivada]="pesquisa.status === 'ARQUIVADO'" [estadoRascunho]="pesquisa.status === 'RASCUNHO'"
          [estadoExecucao]="pesquisa.status === 'EXECUCAO'" [estadoConcluida]="pesquisa.status === 'CONCLUIDO'"
          [possuiAmostrasOnline]="pesquisa.amostrasOnline > 0" [possuiLocalidadeVinculo]="pesquisa.possuiVinculos"
          (onCheckboxClick)="handleCheckboxClick($event)"
          (click)="handleClickInsideDuplicatedItem($event, pesquisa.isDuplicated)"
          (onKebabClick)="handleKebabClick($event)"></pesquisa-item>
      </div>
    </div>
    <div class="pagination-container">
      <app-pagination [placeholder]="'avaliações'" [entriesPerPage]="entriesPerPage" [totalOfEntries]="totalPesquisas"
        [currentPage]="currentPage" [totalPages]="totalOfPages" (changePage)="handleChangePage($event)">
      </app-pagination>
    </div>
  </section>
</div>

<div #qrCodeContainer></div>

<app-loading *ngIf="isLoading"></app-loading>

<app-modal-select-options *ngIf="canShowModalSelectOptions" [data]="optionsToChoose"
  (handleChangeById)="handleChangeCheckedById($event)" (handleCloseModal)="handleCloseModal()"
  (handleExportSurvey)="handleExportSurvey()"></app-modal-select-options>

<app-qrcode-generator></app-qrcode-generator>

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  IAnswaresList,
  IQuestion,
} from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { AnswerOutput } from "../questions.component";

@Component({
  selector: "app-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent implements OnInit, AfterViewInit {
  @Input() questionData: IQuestion;
  @Input() actionsBlockedBySituation: boolean = false;
  @Output() onChange = new EventEmitter<AnswerOutput>(null);
  @ViewChild("gridQuestion") grid: ElementRef<HTMLElement>;

  constructor() {}

  ngAfterViewInit(): void {
    const { clientHeight, scrollHeight, clientWidth, scrollWidth } =
      this.grid.nativeElement;
    this.isOverflown = {
      height: !(scrollWidth > clientWidth),
      width: !(scrollHeight > clientHeight),
    };
  }

  selected: IAnswaresList[] = [];
  limitLineError = false;
  auditorCheckedList = null;
  auditorAnswerWasReset: boolean = false;
  hasChanged: boolean = false;

  private setHasChangedState(state: boolean) {
    if (this.hasChanged !== state) {
      this.hasChanged = state;
    }
  }

  public resetAnswers() {
    this.auditorAnswerWasReset = true;
    this.initAnswers(this.interviewerAnswer as IAnswaresList[]);
    this.setHasChangedState(false);
    this.auditorCheckedList = [];
  }

  private get auditorAnswer() {
    return this.questionData.respostas.auditor;
  }

  private get interviewerAnswer() {
    return this.questionData.respostas.entrevistador;
  }

  private getAnswer(from?: IAnswaresList[]) {
    return from || this.auditorAnswer || this.interviewerAnswer;
  }

  private emitAnswer() {
    this.onChange.emit({
      type: this.isSingleGrid()
        ? TipoPergunta.GRADE_UNICA
        : TipoPergunta.GRADE_MULTIPLA,
      questionId: this.questionData.id,
      answer: this.buildAnswerOutput(this.selected),
      respondida: this.auditorAnswerWasReset
        ? false
        : this.auditorCheckedList === null
        ? true
        : !!this.auditorCheckedList.length,
    });
  }

  /**
   * Adapta a estrutura de output do componente
   */
  private buildAnswerOutput(selected: IAnswaresList[]) {
    // Ordena as alternativas selecionadas pelo id_titulo_alternativa
    const orderedSelected = [...selected].sort(
      (a, b) => a.id_titulo_alternativa - b.id_titulo_alternativa
    );

    let interviewerAnswer = this.interviewerAnswer as IAnswaresList[];
    interviewerAnswer = [...interviewerAnswer].sort(
      (a, b) => a.id_titulo_alternativa - b.id_titulo_alternativa
    );
    const output = orderedSelected.map((item, index) => {
      return {
        id_alternativa: interviewerAnswer[index]
          ? interviewerAnswer[index].id_alternativa
          : null,
        id_alternativa_auditada: item.id_alternativa,
        id_titulo_alternativa: item.id_titulo_alternativa,
        ordem: item.ordem,
      };
    });

    return output;
  }

  initAuditorAnswers() {
    const auditorAnswers = this.auditorAnswer as IAnswaresList[];
    this.auditorCheckedList = this.auditorAnswer?.length
      ? auditorAnswers.map((answ) => answ.id_alternativa)
      : [];
  }

  private initAnswers(from?: IAnswaresList[]) {
    this.validateGridType();
    this.selected = [...this.getAnswer(from)] as IAnswaresList[];
    this.selected = this.selected.map((answer, index) => {
      return {
        id_alternativa: answer.id_alternativa,
        id_titulo_alternativa: answer.id_titulo_alternativa,
        ordem: index + 1,
      };
    });
    this.emitAnswer();
  }

  ngOnInit(): void {
    this.initAnswers();
    this.initAuditorAnswers();
    if (this.auditorAnswer?.length) {
      this.setHasChangedState(true);
    }
  }

  isOverflown = {
    height: true,
    width: true,
  };

  private isSingleGrid() {
    return this.questionData.tipo_pergunta === TipoPergunta.GRADE_UNICA;
  }

  /**
   * Persiste as seleções realizadas pelo auditor
   */
  handleAuditorCheckedList(id_alternativa, selectedAltIndex: number) {
    if (selectedAltIndex === -1) {
      this.auditorCheckedList?.push(id_alternativa);
    } else {
      this.auditorCheckedList = this.auditorCheckedList?.filter(
        (id) => id !== id_alternativa
      );
    }
  }

  /**
   * Metódo de selecão da grade baseado no regra de quantidade máxima de alterantivas selecionadas
   * @param idRow id da linha selecionada
   * @param idColumn id da coluna
   */
  handleGridSelect(idRow: number, idColumn: number, index: number) {
    const alternativaPreviamenteSelecionadaIndex = this.getIndex(
      idColumn,
      idRow
    );
    this.handleAuditorCheckedList(
      idColumn,
      alternativaPreviamenteSelecionadaIndex
    );
    this.auditorAnswerWasReset = false;

    if (alternativaPreviamenteSelecionadaIndex === -1 || this.isSingleGrid()) {
      this.selected.push({
        id_alternativa: idColumn,
        id_titulo_alternativa: idRow,
        ordem: index + 1,
      });

      // pega os itens da linha atual
      const linhaAtual = this.getActualSelectedRow(idRow);

      // guarda os valores das outras linhas
      const outrasLinhas = this.selected.filter(
        (item) => item.id_titulo_alternativa !== idRow
      );

      if (
        linhaAtual.length > this.questionData.definicoes.numero_maximo_respostas
      ) {
        // é mesclado os arrays
        // removendo a primeira posição da linha selecionada
        const novasAlternativasSelecionadas = [
          ...linhaAtual.filter((el) => ![linhaAtual[0]].includes(el)),
          ...outrasLinhas,
        ];
        this.selected = [...novasAlternativasSelecionadas];
      }
    } else {
      this.selected = [
        ...this.selected.filter(
          (_, index) => index !== alternativaPreviamenteSelecionadaIndex
        ),
      ];
    }
    this.validateRows();
    this.emitAnswer();
    this.setHasChangedState(true);
  }

  /**
   * devolve o indice para comparação de acordo com o array selecionado
   * @param idColumn id da alternativa
   * @param idRow id do titulo da alternativa
   * @param arrayKey rótulo do array a ser iterado
   * @returns retorna o indice
   */
  getIndex(idColumn: number, idRow: number, arrayKey = "selected"): number {
    const arrayList = {
      ["selected"]: this.selected,
      ["anwares-selected"]: this.interviewerAnswer,
    };
    return arrayList[arrayKey].findIndex(
      ({
        id_titulo_alternativa: idRowSelected,
        id_alternativa: idColumnSelected,
      }) => idRow === idRowSelected && idColumn === idColumnSelected
    );
  }

  /**
   * @param idRow linha atual
   * @returns array com as alternativas selecionadas daquela linha
   */
  getActualSelectedRow(idRow: number) {
    return this.selected.filter(
      ({ id_titulo_alternativa: rowId }) => rowId === idRow
    );
  }

  /**
   * Valida se a quantidade de items por linha é menor que o limite mínimo
   */
  validateRows() {
    this.limitLineError = this.questionData.definicoes.titulo_alternativas
      .map(({ id: idRow }) => this.getActualSelectedRow(idRow).length)
      .some((i) => i < this.questionData.definicoes.numero_minimo_respostas);
  }

  /**
   * Garante que se caso o tipo da pergunta seja grade única o máximo de alterantivas seja 1
   */
  validateGridType() {
    this.questionData.definicoes.numero_maximo_respostas;
    if (this.isSingleGrid()) {
      this.questionData.definicoes = {
        ...this.questionData.definicoes,
        numero_maximo_respostas: 1,
        numero_minimo_respostas: 1,
      };
    }
  }
}

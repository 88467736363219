import { TipoAlternativa } from "app/modulos/auditoria/resposta-pergunta-listagem/tipoAlternativa";
import { SituacaoListagemDeColetas } from "../constant";
import { ICriterio } from "./audit-collections-section";

export interface ISurveyCollectionSummary {
  id: number;
  horaInicio: string;
  horaFim: string;
  data: string;
  situacao: string;
  operador: string;
  localidade: string;
  criterios: ICriterio[];
  pesquisa?: string;
}

export interface IQuestionResponse {
  audio: string;
  perguntas: IQuestion;
  // Só estará disponível após o review da TC-2298
  situacao_coleta?: SituacaoListagemDeColetas;
}

export interface IQuestion {
  id: number;
  ordem: number;
  enunciado: string;
  obrigatoria: boolean;
  tipo_pergunta: string;
  alternativas?: IAlternative[];
  definicoes?: ISettings;
  respostas: IAnswares;
}

export interface IAlternative {
  id: number;
  enunciado: string;
  ordem: number;
  tipo_alternativa: string;
}

export interface ISettings {
  numero_maximo_respostas: number;
  numero_minimo_respostas: number;
  titulo_alternativas: IAlternativeTitle[];
}

export interface IAlternativeTitle {
  id: number;
  enunciado: string;
  ordem: number;
}

export interface IAnswares {
  auditor: IAnswaresList[] | string;
  entrevistador: IAnswaresList[] | string;
}

export interface IAnswaresList {
  id_alternativa?: number;
  id_alternativa_original?: number;
  id_alternativa_auditada?: number;
  id_titulo_alternativa?: number;
  numero?: number;
  texto?: string;
  tipo?: TipoAlternativa;
  respondida?: boolean;
  ordem?: number;
  ordem_selecao?: number;
}

export enum CollectionSituation {
  APROVADA = "APROVADA",
  EM_ANALISE = "EM_ANALISE",
  REPROVADA = "REPROVADA",
}

export interface ICollectionRespostaAuditor {
  id_alternativa_auditada: number;
  id_alternativa_original?: number | null;
  texto?: string;
  id_titulo_alternativa?: number;
  ordem_selecao?: number;
}

export interface ICollectionRespostaPergunta {
  id_pergunta: number;
  respondida?: boolean;
  respostas: string | ICollectionRespostaAuditor[] | null;
}

export interface IAuditCollectionVerdictPayload {
  situacao_coleta: CollectionSituation;
  justificativa_rejeicao: string;
  respostas_auditor: ICollectionRespostaPergunta[];
}

<div class="contractor-container" [formGroup]="formGroup">
  <span class="title-wrapper">
    <i class="fa-regular fa-wand-magic-sparkles fa-lg"></i>
    <h3>Customização</h3>
  </span>

  <hr class="line" />

  <span class="subtitle-wrapper">
    <h4>Customização do logotipo</h4>
    <i class="fa-regular fa-circle-info fa-sm"></i>
  </span>

  <section class="img-container">
    <div class="img-wrapper">
      <div class="display-img">
        <img [src]="renderImage('logotipo_light')" alt="icone" class="logo-img" />
      </div>
      <div class="btn-wrapper" *ngIf="imageManager.logotipo_light === 'NEW'">
        <span class="label-wrapper">
          <p>Logotipo do contratante</p>
          <label>Suporta PNG. Tamanho máximo: 5 MB</label>
        </span>
        <label for="img-1" class="upload-btn" type="button">
          Carregar logo
          <input
            id="img-1"
            type="file"
            accept="image/*"
            style="display: none"
            (change)="getImagePath('logotipo_light', $event)"
          />
        </label>
      </div>

      <div class="btn-wrapper" *ngIf="imageManager.logotipo_light !== 'NEW'">
        <span class="label-wrapper" *ngIf="imageManager.logotipo_light === 'EDIT'">
          <p>Logotipo do contratante</p>
          <label>Suporta PNG. Tamanho máximo: 5 MB</label>
        </span>
        <p *ngIf="imageManager.logotipo_light === 'LOADING'" class="loading-msg">Salvando logotipo do contratante...</p>
        <div class="edit-content">
          <span
            (click)="removeImage('logotipo_light')"
            [ngClass]="{
              'img-btn-disabled': imageManager.logotipo_light === 'LOADING',
              'delete-btn': true
            }"
            >Excluir</span
          >
          <label
            for="img-1"
            class="upload-btn"
            type="button"
            [ngClass]="{
              'img-btn-disabled img-upload-btn-disabled':
                imageManager.logotipo_light === 'LOADING'
            }"
          >
            Editar
            <input
              id="img-1"
              type="file"
              accept="image/*"
              style="display: none"
              (change)="getImagePath('logotipo_light', $event)"
            />
          </label>
        </div>
      </div>
    </div>

    <div class="img-wrapper">
      <div class="display-img">
        <img [src]="renderImage('login')" alt="icone" class="login-img" />
      </div>
      <span>
        <div class="btn-wrapper" *ngIf="imageManager.login === 'NEW'">
          <span class="label-wrapper">
            <p>Imagem da tela de login</p>
            <label>Suporta PNG ou JPG. Tamanho máximo: 5 MB</label>
          </span>
          <label for="img-5" class="upload-btn" type="button">
            Carregar imagem
            <input
              id="img-5"
              type="file"
              accept="image/*"
              style="display: none"
              (change)="getImagePath('login', $event)"
            />
          </label>
        </div>

        <div class="btn-wrapper" *ngIf="imageManager.login === 'LOADING'">
          <p class="loading-msg">Salvando imagem da tela de login...</p>
        </div>

        <div class="btn-wrapper" *ngIf="imageManager.login === 'EDIT'">
          <span class="label-wrapper">
            <p>Imagem da tela de login</p>
            <label>Suporta PNG ou JPG. Tamanho máximo: 5 MB</label>
          </span>
          <div class="edit-content">
            <span
              (click)="removeImage('login')"
              [ngClass]="{
                'img-btn-disabled': imageManager.logotipo_light === 'LOADING',
                'delete-btn': true
              }"
              >Excluir</span>
            <label for="img-5" class="upload-btn" type="button">
              Editar
              <input
                id="img-5"
                type="file"
                accept="image/*"
                style="display: none"
                (change)="getImagePath('login', $event)"
              />
            </label>
          </div>
        </div>
      </span>
    </div>

  </section>

  <span class="subtitle-wrapper">
    <h4>Customização do tema</h4>
  </span>

  <section class="color-container">
    <div class="color-wrapper">
      <div class="theme-wrapper" (click)="handleCheck('default')">
        <img src="assets/images/contratantes/blue.svg" />
        <span class="select-wrapper">
          <label class="radioContainer">
            <input type="radio" [checked]="colors['default']" />
            <span class="check"></span>
          </label>
          <p>Padrão</p>
        </span>
      </div>

      <div class="theme-wrapper" (click)="handleCheck('verde')">
        <img src="assets/images/contratantes/green.svg" />
        <span class="select-wrapper">
          <label class="radioContainer">
            <input type="radio" [checked]="colors['verde']" />
            <span class="check"></span>
          </label>
          <p>Verde</p>
        </span>
      </div>

      <div class="theme-wrapper" (click)="handleCheck('amarelo')">
        <img src="assets/images/contratantes/yellow.svg" />
        <span class="select-wrapper">
          <label class="radioContainer">
            <input type="radio" [checked]="colors['amarelo']" />
            <span class="check"></span>
          </label>
          <p>Amarelo</p>
        </span>
      </div>

      <div class="theme-wrapper" (click)="handleCheck('laranja')">
        <img src="assets/images/contratantes/orange.svg" />
        <span class="select-wrapper">
          <label class="radioContainer">
            <input type="radio" [checked]="colors['laranja']" />
            <span class="check"></span>
          </label>
          <p>Laranja</p>
        </span>
      </div>

      <div class="theme-wrapper" (click)="handleCheck('vermelho')">
        <img src="assets/images/contratantes/red.svg" />
        <span class="select-wrapper">
          <label class="radioContainer">
            <input type="radio" [checked]="colors['vermelho']" />
            <span class="check"></span>
          </label>
          <p>Vermelho</p>
        </span>
      </div>
    </div>

  </section>
</div>

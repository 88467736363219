export interface CustomStyles {
  [key: string]: string | number;
}

export type CellAlignTypes = "flex-start" | "center" | "flex-end";

export type CellTypes =
  | "icon"
  | "text"
  | "status"
  | "multi-status"
  | "image"
  | "selectable-status"
  | "multi-tag"
  | "checkbox"
  | "html-template"
  | "meatball"
  | "Component"

export type CellStyleBehavior = "UNDERLINE_ON_HOVER";

export class StatusCellValue {
  color: string;
  label: string;
  backgroundColor?: string;
}

export class MultiStatusCellValue extends StatusCellValue {};

export class SelectableStatusCellValue {
  options: {
    color: string;
    label: string;
  }[];

  selected: number;
}

export class MultiTagValue {
  backgroundColor: string;
  color: string;
  label: string;
}

export class ImageCellValue {
  image: string;
  label: string;
}

export class IconCellValue {
  icon: string | string[];
  behavior?: "button" | "normal"
  dataTooltip?: TooltipData;
}

export class TooltipData {
  label?: string;
  position?: "left" | "right"
}

export class ComponentCellValue {
  component: any;
  data: any;
}

export class TableCell {
  id?: number;
  type?: CellTypes;
  iconValue?: IconCellValue;
  componentValue?: ComponentCellValue;
  statusValue?: StatusCellValue;
  multiStatusValue?: MultiStatusCellValue[];
  textValue?: string;
  className?: string;
  selectableStatusValue?: SelectableStatusCellValue;
  multiTagValue?: MultiTagValue[];
  imageValue?: ImageCellValue;
  htmlTemplateValue?: string;
  customStyles?: CustomStyles;
  alignType?: CellAlignTypes;
  datatooltip?: TooltipData;
  inlineStyles?: string;
  coordinates?: number[];
  checkable?: boolean;
  checked?: boolean;
  metadata?: any;
  styleBehavior?: CellStyleBehavior;
}

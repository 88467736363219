import { SurveyListSituations, surveyListSituationView } from "../../constant";

export class SituacaoPesquisaAuditoriaParser {
    /**
     * Retorna o nome amigável dado a chave de uma situação de uma pesquisa auditoria 
     */
    static parse(status: SurveyListSituations) {
        return surveyListSituationView[status] || "";
    }
    
}
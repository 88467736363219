<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>
<app-audited-collections-header [surveyTitle]="auditedCollectionsData.surveyTitle"
  [surveyStatus]="auditedCollectionsData.surveyStatus"
  [auditedCollections]="auditedCollectionsData.auditedCollections"></app-audited-collections-header>
<section>
  <ul class="tabs">
    <li class="tab-item" [ngClass]="currentTab === TABS.OVERVIEW && 'active'" (click)="handleChangeTab(TABS.OVERVIEW)">
      Visão geral
    </li>
    <li class="tab-item" [ngClass]="currentTab === TABS.COLLECTIONS && 'active'"
      (click)="handleChangeTab(TABS.COLLECTIONS)">
      Coletas
    </li>
    <li class="tab-item" [ngClass]="currentTab === TABS.OPEN_ANSWERS && 'active'"
      (click)="handleChangeTab(TABS.OPEN_ANSWERS)">
      Resposta abertas
    </li>
    <li class="tab-item" [ngClass]="currentTab === TABS.SURVEY_MAP && 'active'"
      (click)="handleChangeTab(TABS.SURVEY_MAP)" *ngIf="possuiAmostrasPresenciais">
      Mapa da avaliação
    </li>
  </ul>
  <div class="indicator"></div>
  <!-- Adicionando o indicador de posição -->

  <div class="content">

    <ng-container *ngIf="currentTab === TABS.OVERVIEW">
      <app-audit-overview *ngIf="!isLoading" [overviewData]="overviewData"
        [surveyStatus]="auditedCollectionsData.surveyStatus"
        [auditedCollections]="auditedCollectionsData.auditedCollections?.auditadas"
        (updateSurveyStatus)="updateSurveyStatus()"></app-audit-overview>
    </ng-container>
    <ng-container *ngIf="currentTab === TABS.COLLECTIONS">
      <app-audit-collections-section></app-audit-collections-section>
    </ng-container>
    <ng-container *ngIf="currentTab === TABS.OPEN_ANSWERS">
      <app-open-answers [auditSurveySituation]="auditSurveySituation"></app-open-answers>
    </ng-container>
    <ng-container *ngIf="currentTab === TABS.SURVEY_MAP">
      <app-survey-map [surveyId]="surveyId"></app-survey-map>
    </ng-container>

  </div>
</section>

<app-loading *ngIf="isLoading"></app-loading>
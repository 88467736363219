import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TableEvents } from "app/util/componente/genericTable/interfaces/table";
import { PesquisaService } from "../pesquisa-beta/listagem/servico/pesquisas";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import { Pesquisa, Stats } from "../pesquisa-beta/listagem/pesquisas.component";
import { StatusPesquisa } from "../pesquisa-old/status/statusPesquisa";
import { ConfiguracoesTabela } from "./configuracoes-gerais";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { errorMessages } from "./messages";
import { InfoCardDataProps } from "app/componentes/info-card/info-card.component";

const PAGE_SIZE = 10;

@Component({
  selector: "app-resultados",
  templateUrl: "./resultados.component.html",
  styleUrls: ["./resultados.component.scss"],
})
export class ResultadosComponent extends ConfiguracoesTabela implements OnInit {
  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private pesquisaService: PesquisaService
  ) {
    super();
  }

  ngOnInit(): void {
    this.inicializarFiltros();

    this.loadData();
    this.buildInfoCardData();
  }

  /**
   * Lista de pesquisas tabuladas atuais
   */
  pesquisas: Pesquisa[] = [];

  statusDeFiltro: string[] = [
    StatusPesquisa.CONCLUIDO,
    StatusPesquisa.EXECUCAO,
  ];

  keywords: PalavraChave = new PalavraChave([""]);

  requestLoadingState = {
    totalResultados: false,
    pesquisas: false,
    infoCard: false,

    setTotalResultadosState(v: boolean) {
      this.totalResultados = v;
    },

    setPesquisaState(v: boolean) {
      this.pesquisas = v;
    },

    setInfoCardState(v: boolean) {
      this.infoCard = v;
    },

    allDataLoaded() {
      return !this.totalResultados && !this.pesquisas && !this.infoCard;
    },
  };

  // Controi a propriedade data do infoCard para renderização dos valores
  async buildInfoCardData() {
    this.requestLoadingState.setInfoCardState(true);

    const resultados = await this.getTotalSurveyByStatus(); 

    this.infoCardData.data = Object.entries(resultados).map(([key, value]) => {
      return {
        title: this.newStatsColumnsReference[key],
        value: value,
      };
    });

    this.requestLoadingState.setInfoCardState(false);
  }

  // Obtem o total de pesquisas para cada status correspondente
  async getTotalSurveyByStatus() {
    const keywords = new PalavraChave([""]);

    const totalDeResultadosPesquisasConcluidas = await this.pesquisaService
      .getTotalPesquisasComStatus([StatusPesquisa.CONCLUIDO], false, keywords)
      .toPromise();

    const totalDeResultadosPesquisasEmExecucao = await this.pesquisaService
      .getTotalPesquisasComStatus([StatusPesquisa.EXECUCAO], false, keywords)
      .toPromise();

    const totalPesquisaEmAndamento = totalDeResultadosPesquisasEmExecucao;
    const totalPesquisaEncerrada = totalDeResultadosPesquisasConcluidas;

    return { 
      totalPesquisaEmAndamento,
      totalPesquisaEncerrada
    } as Stats;
  }

  async retrievePesquisaResultList() {
    const dadosPaginacao = new DadosPaginacao(
      PAGE_SIZE,
      this.dadosPaginacao.current - 1
    );

    this.requestLoadingState.setPesquisaState(true);

    try {
      const pesquisasResposta = await this.pesquisaService
        .buscarPorPalavrasChavesEStatus(
          this.keywords,
          this.statusDeFiltro,
          false,
          dadosPaginacao
        )
        .toPromise();

      if (Array.isArray(pesquisasResposta)) {
        this.pesquisas = [...pesquisasResposta];
        this.tableData = this.construisLinhas(this.pesquisas);
      }
    } catch (err) {
      this.errorHandlerService.handleError(
        err,
        errorMessages.listagem.buscarPesquisa
      );
    } finally {
      this.requestLoadingState.setPesquisaState(false);
    }
  }

  async retrieveTotalResultados() {
    this.requestLoadingState.setTotalResultadosState(true);

    try {
      const totalDeResultados = await this.pesquisaService
        .getTotalPesquisasComStatus(this.statusDeFiltro, false, this.keywords)
        .toPromise();

      this.dadosPaginacao.totalOfEntries = totalDeResultados;

    } catch (err) {
      this.errorHandlerService.handleError(
        err,
        errorMessages.listagem.buscarTotalRegistros
      );
    } finally {
      this.requestLoadingState.setTotalResultadosState(false);
    }
  }

  loadData() {
    this.retrievePesquisaResultList();
    this.retrieveTotalResultados();
  }

  handleSearchChanges(event: TableEvents) {
    const { filterValue: keyword } = event;

    this.keywords = new PalavraChave([keyword]);

    this.resetarPaginacao();
    this.loadData();
  }

  handleStatusFilterChanges(event: TableEvents) {
    const {
      filterValue: { currentState },
    } = event;

    this.statusDeFiltro = currentState.map(({ key }) => key);

    if (currentState.length === 0) {
      this.statusDeFiltro = [StatusPesquisa.CONCLUIDO, StatusPesquisa.EXECUCAO];
    }

    this.resetarPaginacao();
    this.loadData();
  }

  redirecionarParaVisualizacaoDeResultados(surveyId: number) {
    this.router.navigate(["resultados/resultado-pesquisa/" + surveyId]);
  }

  onCellClick(event: TableEvents) {
    const { colIndex } = event;

    // Indica que ocorreu um clique no titulo da pesquisa
    if (colIndex === 0) {
      // Para este caso, o row id será equivalente ao id da pesquisa
      const { rowId: surveyId } = event;

      this.redirecionarParaVisualizacaoDeResultados(surveyId);
    }
  }

  /**
   * Controla todos os eventos da tabela
   */
  handleEventTable($event) {
    const { eventType } = $event;
    const eventTable = {
      ["PAGINATION_CHANGED"]: this.changePage.bind(this),
      ["SEARCH_FILTER_CHANGED"]: this.handleSearchChanges.bind(this),
      ["COMPOSED_CHECK_FILTER_CHANGE"]:
        this.handleStatusFilterChanges.bind(this),
      ["CELL_CLICK"]: this.onCellClick.bind(this),
      ["CELL_ICON_CLICK"]: ({ rowId: surveyId }: TableEvents) => this.redirecionarParaVisualizacaoDeResultados(surveyId)
    };
    if (Object.keys(eventTable).includes(eventType)) {
      eventTable[eventType]($event);
    }
  }

  /**
   * Controla a paginação
   */
  changePage({ requestedPage, previousPage }) {
    this.dadosPaginacao.current = requestedPage;
    this.dadosPaginacao.previous = previousPage;

    this.retrievePesquisaResultList();
  }

  resetarPaginacao() {
    this.dadosPaginacao.current = 1;
    this.dadosPaginacao.previous = 1;
  }
}

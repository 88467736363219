import {
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import {
  IFilterSelect,
  IFilterSelectEvent,
} from "app/componentes/filter-select/filter-select";
import { InfoCardDataProps } from "app/componentes/info-card/info-card.component";
import { ModalData } from "app/componentes/modal/modal";
import { ModalService } from "app/componentes/modal/modal.service";
import { formsLocation, reporterUrl } from "app/infraestrutura/apiLocation";
import { JWTService } from "app/infraestrutura/security/service/jwtService";
import { SecurityService } from "app/infraestrutura/security/service/securityService";
import {
  Vinculo as VinculoEdicao,
  VinculoOperador,
} from "app/modulos/pesquisa-old/cadastro/model/vinculoEdicao";
import {
  StatusPesquisa,
  StatusPesquisaNomeAmigavel,
} from "app/modulos/pesquisa-old/status/statusPesquisa";
import { NotificatorService } from "app/notificador/notificator.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { DadosPaginacao } from "app/util/componente/paginacao/dadosPaginacao";
import { PalavraChave } from "app/util/componente/tabela/tabela-filtravel/filter/palavraChave";
import { ClipboardService } from "ngx-clipboard";
import { SurveyListModalTexts } from "./constants/modal-texts";
import { PesquisaService } from "./servico/pesquisas";
import { TokenType } from "./constants/surveyStatus";
import { QrCodeGeneratorService } from "app/componentes/qrcode-generator/qrcode-generator.service";

export type Status = "RASCUNHO" | "EXECUCAO" | "CONCLUIDO" | "ARQUIVADO";

const PAGE_SIZE = 10;

interface InputChangeEvent {
  target: {
    value: string;
  };
}

interface FilterSelector {
  statusArray: Status[];
  arquivado: boolean;
}

interface PesquisaItem {
  id: number;
  nome: string;
  cliente: string;
  amostrasPresenciais: number;
  amostrasOnline: number;
  entrevistasOnlineRealizadas: number;
  entrevistasPresenciaisRealizadas: number;
  status: Status;
  title: string;
  tituloCurto: string;
  statusTexto: string;
  possuiVinculos: boolean;
  isDuplicated?: boolean;
  isFlagged?: number;
}

export interface Pesquisa {
  id: number;
  cliente: string;
  tituloCurto: string;
  status: Status;
  amostrasPresenciais: number;
  amostrasOnline: number;
  entrevistasOnlineRealizadas: number;
  entrevistasPresenciaisRealizadas: number;
  title: string;
  statusTexto: string;
  possuiVinculos: boolean;
  isDuplicated?: boolean;
  isFlagged?: number;
  statusAuditoria?: string;
}

export interface Stats {
  totalPesquisaEmAndamento: number;
  totalPesquisaEncerrada: number;
  totalPesquisaRealizada?: number;
}

export interface OptionChoose {
  nameDescription: string;
  type: string;
  value: string;
  disabled: boolean;
  checked: boolean;
}

//Interface para array de controle de paginação
interface ArquivadaWithFilteredStatusBody {
  data: Pesquisa[];
  pages: {
    flagged: {
      size: number;
      page: number;
    };
    filteredWithFlagged: {
      size: number;
      page: number;
    };
  };
}

enum SurveySituations {
  ARQUIVADA = "ARQUIVADO",
  EXECUCAO = "EXECUCAO",
  RASCUNHO = "RASCUNHO",
  CONCLUIDO = "CONCLUIDO",
}

type CurrentModeForSurvey = "ARQUIVAR" | "DESARQUIVAR";

@Component({
  selector: "app-pesquisas",
  templateUrl: "./pesquisas.component.html",
  styleUrls: ["./pesquisas.component.scss"],
})
export class PesquisasComponent implements OnInit {
  constructor(
    public pesquisaService: PesquisaService,
    protected router: Router,
    protected securityService: SecurityService,
    private _clipboardService: ClipboardService,
    private notificatorService: NotificatorService,
    private route: ActivatedRoute,
    private jwtService: JWTService,
    private errorHandlerService: ErrorHandlerService,
    private modalService: ModalService,
    private qrCodeService: QrCodeGeneratorService,
  ) {}

  // Variáveis relativas ao modal de exportação
  // Array de Opções
  optionsToChoose: OptionChoose[] = [
    {
      nameDescription: ".XLSX",
      type: "XLSX",
      value: "xlsx",
      disabled: false,
      checked: false,
    },
    {
      nameDescription: ".CSV",
      type: "CSV",
      value: "csv",
      disabled: false,
      checked: false,
    },
    {
      nameDescription: "ANALYZE",
      type: "ANALYZE",
      value: "",
      disabled: true,
      checked: false,
    },
  ];

  // Variável de controle para mostrar o modal
  canShowModalSelectOptions: boolean = false;

  // Armazenando o ID atual da pesquisa
  currentPesquisaIdRef: number;

  // String template para titulo de botão arquivar/desarquivar
  templateStringForArquivarDesarquivarButton: string = "Arquivar pesquisas";
  currentModeForArquivarDesarquivar: CurrentModeForSurvey = "ARQUIVAR";

  // identificador de timeout
  timeoutId = 0;

  // prompt de component
  modalData = new ModalData();

  palavraChave = new PalavraChave();
  ultimoDadoPaginacao: DadosPaginacao;

  // Paginas e quantificação de pesquisas
  totalOfPages: number = 1;
  currentPage: number = 1;
  virtualCurrentPage: number = 1;
  totalPesquisas: number = 0;

  // Estado para controlar pesquisas selecionadas

  pesquisas: Pesquisa[] = [];
  virtualPesquisas: Pesquisa[] = [];
  paginateVirtualPesquisa: Pesquisa[][] = [];
  pesquisaAux: Pesquisa[] = [];
  pesquisasSelecionadas: Pesquisa[] = [] as Pesquisa[];
  surveyId: number;
  // Estado para controlar o valor do input do filtro
  searchInput: string = "";

  // Estado para controlar opcoes do filtro
  filterSelectorState: FilterSelector = {
    statusArray: [] as Status[],
    arquivado: false,
  };

  // Estado para armazenar a informação se o seletor do filtro está vazio
  selectStateIsEmpty: boolean =
    this.filterSelectorState.statusArray.length === 0 &&
    !this.filterSelectorState.arquivado;

  // Estado para controlar label do seletor
  selectLabel: string = this.selectStateIsEmpty
    ? "Selecione"
    : this.filterSelectorState.statusArray.reduce(
        (accumulator, currentStatus, currentIndex, statusArr) => {
          if (this.filterSelectorState.arquivado) {
            return currentIndex === 0
              ? `${this.convertStatusToLabel(currentStatus)} e ${accumulator}`
              : `${this.convertStatusToLabel(currentStatus)}, ${accumulator}`;
          } else {
            return currentIndex === 0
              ? `${this.convertStatusToLabel(currentStatus)}${accumulator}`
              : currentIndex === 1
              ? `${this.convertStatusToLabel(currentStatus)} e ${accumulator}`
              : `${this.convertStatusToLabel(currentStatus)}, ${accumulator}`;
          }
        },
        this.filterSelectorState.arquivado ? "Arquivada" : ""
      );

  // Estado para congtrolar a visibilidade das opções do seletor
  selectOptionsAreVisible: boolean = false;

  // Booleano usado para armazenar o estado se ha algo em execucao ou nao
  isLoading: boolean = false;

  // estatísticas
  stats: Stats = {
    totalPesquisaRealizada: 0,
    totalPesquisaEmAndamento: 0,
    totalPesquisaEncerrada: 0,
  };

  // nome das colunas das estatísticas parseados para a nova nomenclatura
  newStatsColumnsReference = {
    totalPesquisaRealizada: "Realizadas",
    totalPesquisaEmAndamento: "Em execução",
    totalPesquisaEncerrada: "Concluídas",
  };

  // info card data model
  infoCardData: InfoCardDataProps = {
    data: [],
    title: "Avaliação",
    text: "Cadastre, visualize e gerencie avaliações de clientes",
  };

  //
  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "Início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Avaliação",
      itemLink: `/avaliacao`,
      active: true,
    },
  ];

  // Variáveis de inicialização do filtro de situação
  filterConf = {
    icon: FilterIcon.FUNNEL,
    type: FilterTypes.CHECK,
  };

  // Configuração de filtragem das pesquisas por situação
  filterOptions: IFilterSelect[] = [
    { id: 1, label: "Rascunho", key: SurveySituations.RASCUNHO },
    { id: 2, label: "Execução", key: SurveySituations.EXECUCAO },
    { id: 3, label: "Arquivadas", key: SurveySituations.ARQUIVADA },
    { id: 4, label: "Concluído", key: SurveySituations.CONCLUIDO },
  ];

  // Para garantir a persistência das seleções de filtro selecionadas, mesmo quando o componente de filtragem
  // for destruído, guardamos aqui o estado de seleção corrente.
  selectedFilterOptions: IFilterSelect[] = [];

  // Variavel de controle que verifica se um evento de clique foi feito numa pesquisa duplicada
  insideDuplicatedItem: boolean = false;

  // Modal Select Options

  // Parametros para paginação e request a API quando
  // a flag arquivada está true e com filtros
  arquivadaWithFilteredStatus: ArquivadaWithFilteredStatusBody = {
    data: [],
    pages: {
      flagged: {
        size: 10,
        page: 1,
      },
      filteredWithFlagged: {
        size: 10,
        page: 1,
      },
    },
  };

  // Atributo criado para identificar quando a ordem de exclusão vem do menu kebab
  // Essa informação é utilizada para validar a flash message de exclusão
  action: string = "";

  // Função para modificar status do canShowModalSelectOptions
  handleCanShowModalSelectOptions(surveyStatus: string): void {
    this.canShowModalSelectOptions = !this.canShowModalSelectOptions;
    if (this.canShowModalSelectOptions) {
      this.handleExportOptions(surveyStatus);
    }
  }

  /**
   * Atualiza o status do seletor ANALYZE no modal de exportação
   */
  updateAnalyzeOption(newStatus: boolean) {
    const index = this.optionsToChoose.findIndex(
      (opt) => opt.type === "ANALYZE"
    );
    this.optionsToChoose[index].disabled = newStatus;
  }

  /**
   * Verifica se @param surveyStatus é === 'CONCLUIDO' para habilitar a
   * opção 'ANALYZE' no menu de exportação e obter o id da pesquisa
   * que será exportada
   */
  handleExportOptions(surveyStatus: string): void {
    if (surveyStatus === StatusPesquisaNomeAmigavel.CONCLUIDO) {
      this.updateAnalyzeOption(false);
    }
  }

  // Função para modificar o checked de uma opção
  handleChangeCheckedById(id: number): void {
    // Só permite uma seleção das opções de checkboxes por vez.
    this.optionsToChoose.map((item, index) => {
      if (index !== id) {
        item.checked = false;
      }
    });

    this.optionsToChoose[id].checked = !this.optionsToChoose[id].checked;
  }

  // Função para cancelar o modal
  handleCloseModal(): void {
    this.optionsToChoose.map((item) => (item.checked = false));
    this.canShowModalSelectOptions = false;
    this.updateAnalyzeOption(true);
  }

  // Metodo para exportar a pesquisa para o analyze
  handleExportSurveyToAnalyze(): void {
    this.isLoading = true;
    if (this.surveyId) {
      this.pesquisaService.exportSurveyToAnalyze(this.surveyId).subscribe({
        next: () => {
          this.isLoading = false;
          this.notificatorService.showInfo(
            "Dados exportados",
            "exportação concluída"
          );
        },
        error: (err) => {
          this.isLoading = false;
          this.errorHandlerService.handleError(
            err,
            "Erro ao exportar pesquisa"
          );
        },
      });
    }
  }

  // Metodo para exportar os arquivos
  handleExportFile(type): void {
    this.pesquisaService
      .exportarPesquisa(this.currentPesquisaIdRef, "CODIGO_TEXTO", type)
      .subscribe((response) => {});

    this.notificatorService.showInfo(
      "Dados exportados",
      "exportação concluída"
    );
  }

  handleExportSurvey(): void {
    this.optionsToChoose.map(({ checked, type }) => {
      if (checked) {
        if (type === "ANALYZE") {
          this.handleExportSurveyToAnalyze();
        } else {
          this.handleExportFile(type);
        }
      }
    });
    this.handleCloseModal();
  }

  // Função que converte status para versão legível
  convertStatusToLabel(status: Status) {
    if (status === "EXECUCAO") return "Execução";
    else if (status === "CONCLUIDO") return "Concluído";
    else if (status === "RASCUNHO") return "Rascunho";
  }

  /**
   * Remove a seleção em caso de uma ação em massa (Excluir ou Arquivar)
   */

  clearSelectedSurveys() {
    this.pesquisasSelecionadas.length = 0;
    this.isLoading = false;
  }

  /** Em caso de uma ação do usuário enquanto uma pesquisa está selecionada
   * Ações: Excluir, Arquivar e Executar individualmente uma pesquisa que está selecionada, ela deve
   * ser removida do array de selecionadas
   */
  removeSurveyFromSelected(pesquisa: Pesquisa) {
    const surveyIndex = this.pesquisasSelecionadas.indexOf(pesquisa);
    this.pesquisasSelecionadas.splice(surveyIndex, 1);
    this.isLoading = false;
  }

  /**
   * função para buscar os status das pesquisas
   * TODO: esta função foi copiada do serviço de dashboard, precisam ser únicas em algum ponto (via refactory)
   */
  updateStatsPesquisas() {
    const statusList: string[] = ["EXECUCAO", "CONCLUIDO"];
    statusList.forEach((status) => {
      this.pesquisaService.getPesquisasByStatus(status).subscribe((dado) => {
        if (status === "EXECUCAO") {
          this.stats.totalPesquisaEmAndamento = dado;
        }
        if (status === "CONCLUIDO" || status === "ARQUIVADO") {
          this.stats.totalPesquisaEncerrada =
            this.stats.totalPesquisaEncerrada + dado;
        }

        this.parseStatsToInfoCardData(this.stats);
      });
    });
    this.pesquisaService
      .getTotalEntidades(new PalavraChave())
      .subscribe((dado) => {
        this.stats.totalPesquisaRealizada = dado;
        this.parseStatsToInfoCardData(this.stats);
      });
  }

  /**
   * Com a necessidade de atualização do layout da listagem de pesquisas, agora utilizamos o componente
   * app-info-card, responsável por fazer o display das estatísticas de um determinado escopo. Um dos
   * atributos desse componente é o "data", que é um array de objetos com as propriedades "title" e "value",
   * que não é compatível com o antigo componente responsável por fazer o display desses valores, assim sendo
   * necessário um parse.
   */
  parseStatsToInfoCardData(stats: Stats) {
    this.infoCardData.data = Object.entries(stats).map(([key, value]) => {
      return {
        title: this.newStatsColumnsReference[key],
        value: value,
      };
    });
  }

  async updatePaging() {
    if (
      this.filterSelectorState.arquivado &&
      this.filterSelectorState.statusArray.length === 0
    ) {
      const keywords = this.searchInput.split(" ");
      this.pesquisaService
        .getTotalPesquisasComStatus(
          this.filterSelectorState.statusArray,
          this.filterSelectorState.arquivado,
          { palavrasChave: keywords }
        )
        .subscribe((dado) => {
          this.currentPage = !!this.ultimoDadoPaginacao
            ? this.ultimoDadoPaginacao.page + 1
            : 1;
          this.totalOfPages =
            Math.floor(dado / PAGE_SIZE) + this.checksMultipleForPages(dado);
          this.totalPesquisas = dado;
        });
    } else if (
      this.filterSelectorState.arquivado &&
      this.filterSelectorState.statusArray.length > 0
    ) {
      const keywords = this.searchInput.split(" ");

      // PAGINAÇÃO COM FLAG ATIVA E SEM OS FILTROS
      await this.pesquisaService
        .getTotalPesquisasComStatus([], this.filterSelectorState.arquivado, {
          palavrasChave: keywords,
        })
        .toPromise()
        .then((dado) => {
          this.totalPesquisas = dado;

          // Dados da Paginação para a API
          this.arquivadaWithFilteredStatus.pages.flagged.page = 0;
          this.arquivadaWithFilteredStatus.pages.flagged.size = dado;
        })
        .catch((error) => {
          console.error(error.message);
        });

      // PAGINAÇÃO COM FLAG DESATIVADA E COM OS FILTROS
      await this.pesquisaService
        .getTotalPesquisasComStatus(
          this.filterSelectorState.statusArray,
          !this.filterSelectorState.arquivado,
          { palavrasChave: keywords }
        )
        .toPromise()
        .then((dado) => {
          this.currentPage = !!this.ultimoDadoPaginacao
            ? this.ultimoDadoPaginacao.page + 1
            : 1;
          this.totalOfPages = Math.floor(
            (dado + this.totalPesquisas) / PAGE_SIZE +
              this.checksMultipleForPages(dado)
          );
          this.totalPesquisas += dado;

          // Dados da Paginação para a API
          this.arquivadaWithFilteredStatus.pages.filteredWithFlagged.page = 0;
          this.arquivadaWithFilteredStatus.pages.filteredWithFlagged.size =
            dado;
        })
        .catch((error) => {
          console.error(error.message);
        });
    } else {
      const keywords = this.searchInput.split(" ");
      this.pesquisaService
        .getTotalPesquisasComStatus(
          this.filterSelectorState.statusArray,
          this.filterSelectorState.arquivado,
          { palavrasChave: keywords }
        )
        .subscribe((dado) => {
          this.currentPage = !!this.ultimoDadoPaginacao
            ? this.ultimoDadoPaginacao.page + 1
            : 1;
          this.totalOfPages =
            Math.floor(dado / PAGE_SIZE) + this.checksMultipleForPages(dado);
          this.totalPesquisas = dado;
        });
    }
  }

  /**
   * Verifica se dado é maior do que zero e um multiplo de 10
   * para o cálculo de páginas a serem exibidas
   * TRUE retorna 0
   * FALSE retorna 1
   * @param dado: número de pesquisas
   */
  checksMultipleForPages(dado): number {
    if (dado > 0 && Number.isInteger(dado / 10)) {
      return 0;
    } else {
      return 1;
    }
  }

  resetPaging() {
    this.totalOfPages = 1;
    this.currentPage = 1;
    this.virtualCurrentPage = 1;
    this.totalPesquisas = 0;
    this.ultimoDadoPaginacao = new DadosPaginacao(
      PAGE_SIZE,
      this.currentPage - 1
    );
  }

  // Callback para exibir mensagem de sucesso ao arquivar as pesquisas
  getOnArquivarSuccessCallback(successCallback: Function) {
    return () => {
      this.notificatorService.showInfo(
        "Avaliação arquivada",
        "Filtre por arquivadas"
      );
      this.getResearchs(this.searchInput.split(" "));
      successCallback();
    };
  }

  // Callback para exibir mensagem de sucesso ao desarquivar as pesquisas
  getOnDesarquivarSuccessCallback() {
    return () => {
      this.notificatorService.showInfo(
        "Avaliação desarquivada",
        "Busque pelo título da avaliação"
      );
      this.getResearchs(this.searchInput.split(" "));
    };
  }

  // Callback para exibir mensagem de sucesso ao excluir as pesquisas
  getOnExcluirSuccessCallback(successCallback: Function) {
    const messages =
      this.action === "excluir" || this.pesquisasSelecionadas.length === 1
        ? {
            title: "Avaliação excluída ",
            description: "Avaliação excluída com sucesso",
          }
        : {
            title: "Avaliações excluídas ",
            description: "As avaliações selecionadas foram excluídas",
          };

    return () => {
      this.notificatorService.showInfo(messages.title, messages.description);
      this.getResearchs(this.searchInput.split(" "));
      // Limpar seleção mediante o tipo de exclusão;
      successCallback();
    };
  }

  // função que recarrega as pesquisas
  refresh() {
    this.filterSelectorState = {
      statusArray: [],
      arquivado: false,
    };
    this.refreshResearch();
  }

  // Callback para exibir mensagem de erro ao arquivar pesquisas
  onArquivarError(error) {
    this.errorHandlerService.handleError(error, "Arquivar avaliação(s)");
  }

  // Callback para exibir mensagem de erro ao arquivar pesquisas
  onDesarquivarError() {
    this.notificatorService.showInfo(
      "Avaliação cadastrada",
      "Mensagem positiva"
    );
  }

  // Callback para exibir mensagem de erro ao excluir pesquisas
  onExcluirError(error) {
    this.errorHandlerService.handleError(error, "Excluir avaliação(s)");
  }

  // Função que exibe prompt de arquivamento de pesquisas selecionadas
  handleMostrarPromptDeArquivarPesquisasSelecionadas() {
    let title = `${
      this.currentModeForArquivarDesarquivar === "ARQUIVAR"
        ? this.pesquisasSelecionadas.length > 1
          ? SurveyListModalTexts.archiveMultipleSurveys.title
          : SurveyListModalTexts.archiveUniqueSurvey.title
        : this.pesquisasSelecionadas.length > 1
        ? "Desarquivar avaliações"
        : "Desarquivar avaliação"
    }`;
    this.modalData.icon = "fa-light fa-box-archive";
    this.modalData.title = `${
      this.currentModeForArquivarDesarquivar === "ARQUIVAR"
        ? this.pesquisasSelecionadas.length > 1
          ? SurveyListModalTexts.archiveMultipleSurveys.body(
              this.pesquisasSelecionadas.length
            )
          : SurveyListModalTexts.archiveUniqueSurvey.body
        : `Ao ${
            this.pesquisasSelecionadas.length > 1
              ? "desarquivar em massa, todas as avaliações voltarão para o status  anterior"
              : "desarquivar uma avaliação, ela voltará para o status  anterior"
          }. Você poderá encontra-la${
            this.pesquisasSelecionadas.length > 1 ? "s" : ""
          } buscando pelo título.`
    }`;

    let iconChoice = `${
      this.currentModeForArquivarDesarquivar === "ARQUIVAR"
        ? "fa-solid fa-arrow-down-to-square"
        : "fa-solid fa-arrow-up-from-square"
    }`;

    let messageModal = `${
      this.currentModeForArquivarDesarquivar === "ARQUIVAR"
        ? `Ao ${
            this.pesquisasSelecionadas.length > 1
              ? "arquivar em massa as avaliações serão apenas guardadas"
              : "arquivar uma avaliação ela será apenas guardada"
          }. Caso queira consultar, basta filtrar por <b class="info-gray">arquivadas</b>.`
        : `Ao ${
            this.pesquisasSelecionadas.length > 1
              ? "desarquivar em massa, todas as avaliações voltarão para o status  anterior"
              : "desarquivar uma avaliação, ela voltará para o status  anterior"
          }. Você poderá encontra-la${
            this.pesquisasSelecionadas.length > 1 ? "s" : ""
          } buscando pelo título.`
    }`;

    let btnTitlePositive = `${
      this.currentModeForArquivarDesarquivar === "ARQUIVAR"
        ? "Arquivar"
        : "Desarquivar"
    }`;

    this.modalService.showModal({
      title,
      messageModal,
      btnTitlePositive,
      btnTitleNegative: "Cancelar",
      icon: iconChoice,
      positiveCallback: () => {
        if (this.currentModeForArquivarDesarquivar === "ARQUIVAR") {
          this.handleArquivarEmMassa(
            this.pesquisasSelecionadas.map((pesquisa) => pesquisa.id)
          );
        } else {
          this.handleDesarquivarEmMassa(
            this.pesquisasSelecionadas.map((pesquisa) => pesquisa.id)
          );
        }
      },
    });
  }

  // Função para arquivar em massa
  handleArquivarEmMassa(pesquisasIds: number[]) {
    this.pesquisaService.arquivarPesquisas(pesquisasIds).subscribe({
      next: this.getOnArquivarEmMassaSuccessCallback(),
      error: this.onArquivarError,
    });
  }

  getOnArquivarEmMassaSuccessCallback() {
    return () => {
      this.notificatorService.showInfo(
        "Avaliações arquivadas",
        "Filtre por arquivadas"
      );
      // limpar seleção após arquivar em massa
      this.clearSelectedSurveys();
      this.getResearchs(this.searchInput.split(" "));
    };
  }

  // Função para desarquivar em massa
  handleDesarquivarEmMassa(pesquisasIds: number[]) {
    this.pesquisaService.arquivarPesquisas(pesquisasIds).subscribe({
      next: this.getOnDesarquivarEmMassaSuccessCallback(),
      error: this.onDesarquivarError,
    });
  }

  getOnDesarquivarEmMassaSuccessCallback() {
    return () => {
      this.notificatorService.showInfo(
        "Avaliações desarquivadas",
        "Filtre por status de origem"
      );

      this.getResearchs(this.searchInput.split(" "));
    };
  }

  // Função de arquivar Pesquisas
  handleArquivarPesquisas(pesquisasIds: number[]) {
    const pesquisa = this.pesquisas.find(
      (pesquisa) => pesquisa.id === pesquisasIds[0]
    );
    this.modalData.title = "Arquivar avaliação";
    this.modalData.icon = "fa-light fa-box-archive";
    this.modalData.messageModal =
      "Ao arquivar uma avaliação ela será apenas guardada, caso queira consultar basta filtrar por arquivadas.";
    this.modalData.btnTitlePositive = "Arquivar";
    this.modalData.btnTitleNegative = "Cancelar";
    this.modalData.positiveCallback = () => {
      this.isLoading = true;
      this.pesquisaService.arquivarPesquisas(pesquisasIds).subscribe({
        next: this.getOnArquivarSuccessCallback(() =>
          this.removeSurveyFromSelected(pesquisa)
        ),
        error: this.onArquivarError,
      });
    };

    this.modalService.showModal({ ...this.modalData });
  }

  handleDesarquivarPesquisas(pesquisasIds: number[], status: string) {
    this.modalData.title = "Desarquivar avaliação";
    this.modalData.icon = "fa-light fa-box-archive";
    this.modalData.messageModal = `Ao desarquivar essa avaliação ela voltará para o status de <b class='${
      status === "Concluído" ? "info-green" : "info"
    }'>${status}</b>. Você poderá encontra-la buscando pelo título.`;
    this.modalData.btnTitlePositive = "Desarquivar";
    this.modalData.btnTitleNegative = "Cancelar";
    this.modalData.positiveCallback = () => {
      this.isLoading = true;
      this.pesquisaService.arquivarPesquisas(pesquisasIds).subscribe({
        next: this.getOnDesarquivarSuccessCallback(),
        error: this.onDesarquivarError,
      });
    };

    this.modalService.showModal({ ...this.modalData });
  }

  // Função que exibe prompt de exclusão de pesquisas selecionadas
  handleMostrarPromptDeExcluirPesquisasSelecionadas() {
    this.modalData.icon = "fa-regular fa-trash";

    this.modalData.title =
      this.pesquisasSelecionadas.length > 1
        ? SurveyListModalTexts.deleteUniqueSurvey.title
        : SurveyListModalTexts.deleteMultipleSurveys.title;

    this.modalData.messageModal =
      this.pesquisasSelecionadas.length > 1
        ? SurveyListModalTexts.deleteMultipleSurveys.body(
            this.pesquisasSelecionadas.length
          )
        : SurveyListModalTexts.deleteUniqueSurvey.body;

    this.modalData.btnTitlePositive = "Sim";
    this.modalData.btnTitleNegative = "Cancelar";
    this.modalData.positiveCallback = () =>
      this.handleExcluirPesquisasSelecionadas();

    this.modalService.showModal({ ...this.modalData });
  }

  // Função de excluir pesquisas
  handleExcluirPesquisasSelecionadas() {
    this.handleExcluirPesquisas(
      this.pesquisasSelecionadas.map((pesquisa) => pesquisa.id),
      () => this.clearSelectedSurveys()
    );
  }

  // Função criada para mostrar prompt de comando quando o usuario tentar excluir uma pesquisa através do menu kebab
  mostrarPromptDeComandoDeExcluirPesquisa(pesquisaIds: number[]) {
    const pesquisa = this.pesquisas.find(
      (pesquisa) => pesquisa.id === pesquisaIds[0]
    );
    const titulo = pesquisa.tituloCurto;
    this.modalData.title = "Excluir avaliação";
    this.modalData.icon = "fa-regular fa-trash";
    this.modalData.messageModal = `Ao excluir a avaliação <b>${titulo}</b> você perderá todos os dados referente ao seu cadastro e coletas, caso exista.`;
    this.modalData.btnTitlePositive = "Excluir";
    this.modalData.btnTitleNegative = "Cancelar";
    this.modalData.positiveCallback = () => {
      this.handleExcluirPesquisas(pesquisaIds, () =>
        this.removeSurveyFromSelected(pesquisa)
      );
    };

    this.modalService.showModal({ ...this.modalData });
  }

  // Função de excluir pesquisas
  handleExcluirPesquisas(pesquisasIds: number[], successCallback: Function) {
    this.isLoading = true;
    this.pesquisaService.removerPesquisas(pesquisasIds).subscribe({
      next: this.getOnExcluirSuccessCallback(successCallback),
      error: this.onExcluirError,
    });
  }

  mostrarPromptDeComandoDeCopiarPesquisa(researchId: number) {
    this.modalData.title = "Duplicar avaliação";
    this.modalData.icon = "fa-regular fa-copy";
    this.modalData.messageModal =
      "Ao duplicar você cria uma cópia da avaliação selecionada com as mesmas configurações, porém sem dados coletados caso exista na avaliação original.";
    this.modalData.btnTitlePositive = "Duplicar";
    this.modalData.btnTitleNegative = "Cancelar";
    this.modalData.positiveCallback = () => {
      this.copyResearch(researchId);
    };

    this.modalService.showModal({ ...this.modalData });
  }

  async copyResearch(researchId: number) {
    this.isLoading = true;
    await this.pesquisaService
      .postDuplicarPesquisa(researchId)
      .toPromise()
      .then((observer) => {
        this.notificatorService.showInfo(
          "Avaliação duplicada",
          "Você duplicou a avaliação com sucesso"
        );
        this.getResearchs(this.searchInput.split(" "), true);
      })
      .catch((error) => {
        this.isLoading = false;
        this.errorHandlerService.handleError(
          error,
          "Erro ao duplicar avaliação"
        );
      });
    this.isLoading = false;
  }

  async getResearchs(keywords: string[], isDuplicated: boolean = false) {
    this.isLoading = true;
    try {
      if (
        this.filterSelectorState.arquivado &&
        this.filterSelectorState.statusArray.length === 0
      ) {
        this.virtualPesquisas = [];
        this.paginateVirtualPesquisa = [];

        await this.pesquisaService
          .buscarPorPalavrasChavesEStatus(
            { palavrasChave: keywords },
            this.filterSelectorState.statusArray,
            this.filterSelectorState.arquivado,
            this.ultimoDadoPaginacao
          )
          .toPromise()
          .then((item) => {
            if (isDuplicated && item[0].tituloCurto.includes("Cópia")) {
              item[0].isDuplicated = true;
            }

            this.pesquisas = item.map((data: PesquisaItem) => {
              return {
                id: data.id,
                title: data.nome,
                cliente: data.cliente,
                amostrasOnline: data.amostrasOnline,
                amostrasPresenciais: data.amostrasPresenciais,
                entrevistasOnlineRealizadas: data.entrevistasOnlineRealizadas,
                entrevistasPresenciaisRealizadas:
                  data.entrevistasPresenciaisRealizadas,
                status: data.status,
                statusTexto: data.statusTexto,
                tituloCurto: data.tituloCurto,
                possuiVinculos: data.possuiVinculos,
                ...(data.isDuplicated ? { isDuplicated: true } : {}),
              };
            });
            this.updatePaging();
          });
      } else if (
        this.filterSelectorState.arquivado &&
        this.filterSelectorState.statusArray.length > 0
      ) {
        this.virtualPesquisas = [];
        this.paginateVirtualPesquisa = [];

        await this.pesquisaService
          .buscarPorPalavrasChavesEStatus(
            { palavrasChave: keywords },
            this.filterSelectorState.statusArray,
            this.filterSelectorState.arquivado,
            this.ultimoDadoPaginacao
          )
          .toPromise()
          .then((item) => {
            if (isDuplicated && item[0].tituloCurto.includes("Cópia")) {
              item[0].isDuplicated = true;
            }

            this.pesquisas = item.map((data: PesquisaItem) => {
              return {
                id: data.id,
                title: data.nome,
                cliente: data.cliente,
                amostrasOnline: data.amostrasOnline,
                amostrasPresenciais: data.amostrasPresenciais,
                entrevistasOnlineRealizadas: data.entrevistasOnlineRealizadas,
                entrevistasPresenciaisRealizadas:
                  data.entrevistasPresenciaisRealizadas,
                status: data.status,
                statusTexto: data.statusTexto,
                tituloCurto: data.tituloCurto,
                possuiVinculos: data.possuiVinculos,
                ...(data.isDuplicated ? { isDuplicated: true } : {}),
              };
            });
            this.updatePaging();
          });
        this.virtualPesquisas = [];
        this.paginateVirtualPesquisa = [];

        await this.pesquisaService
          .buscarPorPalavrasChavesEStatus(
            { palavrasChave: keywords },
            this.filterSelectorState.statusArray,
            this.filterSelectorState.arquivado,
            this.ultimoDadoPaginacao
          )
          .toPromise()
          .then((item) => {
            if (isDuplicated && item[0].tituloCurto.includes("Cópia")) {
              item[0].isDuplicated = true;
            }

            this.pesquisas = item.map((data: PesquisaItem) => {
              return {
                id: data.id,
                title: data.nome,
                cliente: data.cliente,
                amostrasOnline: data.amostrasOnline,
                amostrasPresenciais: data.amostrasPresenciais,
                entrevistasOnlineRealizadas: data.entrevistasOnlineRealizadas,
                entrevistasPresenciaisRealizadas:
                  data.entrevistasPresenciaisRealizadas,
                status: data.status,
                statusTexto: data.statusTexto,
                tituloCurto: data.tituloCurto,
                possuiVinculos: data.possuiVinculos,
                ...(data.isDuplicated ? { isDuplicated: true } : {}),
              };
            });
            this.updatePaging();
          });
      } else {
        this.virtualPesquisas = [];
        this.paginateVirtualPesquisa = [];

        await this.pesquisaService
          .buscarPorPalavrasChavesEStatus(
            { palavrasChave: keywords },
            this.filterSelectorState.statusArray,
            this.filterSelectorState.arquivado,
            this.ultimoDadoPaginacao
          )
          .toPromise()
          .then((item) => {
            if (isDuplicated && item[0].tituloCurto.includes("Cópia")) {
              item[0].isDuplicated = true;
            }

            this.pesquisas = item.map((data: PesquisaItem) => {
              return {
                id: data.id,
                title: data.nome,
                cliente: data.cliente,
                amostrasOnline: data.amostrasOnline,
                amostrasPresenciais: data.amostrasPresenciais,
                entrevistasOnlineRealizadas: data.entrevistasOnlineRealizadas,
                entrevistasPresenciaisRealizadas:
                  data.entrevistasPresenciaisRealizadas,
                status: data.status,
                statusTexto: data.statusTexto,
                tituloCurto: data.tituloCurto,
                possuiVinculos: data.possuiVinculos,
                ...(data.isDuplicated ? { isDuplicated: true } : {}),
              };
            });
            this.updatePaging();
          });
      }
    } catch (err) {
      this.errorHandlerService.handleError(
        err,
        "Erro na listagem de avaliação"
      );
    } finally {
      this.isLoading = false;
    }
  }

  handleCanShowRegularTable(): boolean {
    if (
      this.filterSelectorState.arquivado &&
      this.filterSelectorState.statusArray.length === 0
    ) {
      return true;
    } else if (
      this.filterSelectorState.arquivado &&
      this.filterSelectorState.statusArray.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  handleCanShowVirtualTable(): boolean {
    if (
      this.filterSelectorState.arquivado &&
      this.filterSelectorState.statusArray.length === 0
    ) {
      return false;
    } else if (
      this.filterSelectorState.arquivado &&
      this.filterSelectorState.statusArray.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleBreakInPartsData(): void {
    //
    this.paginateVirtualPesquisa = this.virtualPesquisas.reduce(
      (acc, value, index) => {
        const pageIndex = Math.ceil((index + 1) / 10);

        acc[pageIndex] = [...(acc[pageIndex] ? acc[pageIndex] : []), value];
        return acc;
      },
      [] as Pesquisa[][]
    );
  }

  async refreshResearch() {
    await this.getResearchs(this.searchInput.split(" "));
    this.selectStateIsEmpty =
      this.filterSelectorState.statusArray.length === 0 &&
      !this.filterSelectorState.arquivado;
    this.selectLabel = this.selectStateIsEmpty
      ? "Selecione"
      : this.filterSelectorState.statusArray.reduce(
          (accumulator, currentStatus, currentIndex, statusArr) => {
            if (this.filterSelectorState.arquivado) {
              return currentIndex === 0
                ? `${this.convertStatusToLabel(currentStatus)} e ${accumulator}`
                : `${this.convertStatusToLabel(currentStatus)}, ${accumulator}`;
            } else {
              return currentIndex === 0
                ? `${this.convertStatusToLabel(currentStatus)}${accumulator}`
                : currentIndex === 1
                ? `${this.convertStatusToLabel(currentStatus)} e ${accumulator}`
                : `${this.convertStatusToLabel(currentStatus)}, ${accumulator}`;
            }
          },
          this.filterSelectorState.arquivado ? "Arquivada" : ""
        );
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get("search")) {
      this.searchInput = this.route.snapshot.paramMap.get("search");
    }
    this.refreshResearch();
    this.updateStatsPesquisas();
    this.updatePaging();
  }

  getTotalPesquisas(): number {
    return (this.totalPesquisas =
      this.stats.totalPesquisaEmAndamento +
      this.stats.totalPesquisaRealizada +
      this.stats.totalPesquisaEncerrada);
  }

  get entriesPerPage(): number {
    return PAGE_SIZE;
  }

  // Função para alterar o estado do input de busca
  handleInputChange(event: any) {
    if (this.handleCanShowRegularTable()) {
      // TODO: resolver acionamento de teclado (nem todos podem participar desta função)
      this.searchInput = event.target.value;
      window.clearTimeout(this.timeoutId);
      this.timeoutId = window.setTimeout(() => {
        this.resetPaging();
        this.refreshResearch();
      }, 2000);
    } else {
      // TODO: Busca de array de arrays e substituir a página
      this.searchInput = event.target.value;

      window.clearTimeout(this.timeoutId);
      this.timeoutId = window.setTimeout(() => {
        this.getResearchs(this.searchInput.split(" "));
      }, 2000);
    }
  }

  // Função para alterar estado de seleção do seletor
  handleSelectOptionClick($event: IFilterSelectEvent) {
    let option: string = $event.clickedOption.key;

    this.selectedFilterOptions = [...$event.currentState];

    if (option === SurveySituations.ARQUIVADA) {
      this.filterSelectorState.arquivado = !this.filterSelectorState.arquivado;
    } else {
      if (this.filterSelectorState.statusArray.includes(option as Status)) {
        this.filterSelectorState.statusArray =
          this.filterSelectorState.statusArray.filter(
            (currentOption) => currentOption !== option
          );
      } else {
        this.filterSelectorState.statusArray.push(option as Status);
      }
    }

    if (
      this.filterSelectorState.arquivado &&
      this.filterSelectorState.statusArray.map(
        (item) => item === SurveySituations.ARQUIVADA
      )
    ) {
      this.templateStringForArquivarDesarquivarButton =
        "Desarquivar avaliações";
      this.currentModeForArquivarDesarquivar = "DESARQUIVAR";
    } else {
      this.templateStringForArquivarDesarquivarButton = "Arquivar avaliações";
      this.currentModeForArquivarDesarquivar = "ARQUIVAR";
    }

    this.resetPaging();
    this.refreshResearch();
  }

  // Função para alterar a visibilidade das opções do seletor
  toggleSelectOptionsVisibility() {
    this.selectOptionsAreVisible = !this.selectOptionsAreVisible;
  }

  // Função que vai ser chamada ao clicar no checkbox de selecionar pesquisa
  handleCheckboxClick(pesquisaId: number) {
    const pesquisaIndex = this.pesquisasSelecionadas.findIndex(
      (pesquisaAtual) => pesquisaAtual.id === pesquisaId
    );
    if (pesquisaIndex > -1) {
      this.pesquisasSelecionadas = this.pesquisasSelecionadas.filter(
        (pesquisaSelecionada) => pesquisaSelecionada.id !== pesquisaId
      );
    } else {
      const selectedSurvey = this.paginateVirtualPesquisa[this.currentPage]
        ? [...this.pesquisas, ...this.paginateVirtualPesquisa[this.currentPage]]
        : this.pesquisas;
      this.pesquisasSelecionadas.push(
        selectedSurvey.find(({ id }) => pesquisaId === id)
      );
    }
  }

  /**
   * Verifica se alguma pesquisa selecionada possui status diferente
   * de rascunho
   * @returns bool para desabilitar prompt de exclusão em massa
   * @description Alteração de RN solicitada na task TC-2408
   */
  disableSelectedSurveysPrompt(): boolean {
    return this.pesquisasSelecionadas.some(
      (survey) => survey.status !== SurveySituations.RASCUNHO
    );
  }

  // Função que retorna se a pesquisa foi selecionada ou não
  isPesquisaSelecionada(pesquisaId: number) {
    return this.pesquisasSelecionadas.some(
      (pesquisa) => pesquisa.id === pesquisaId
    );
  }

  // Função que verifica se todas as pesquisas foram selecionadas
  areAllPesquisaSelected() {
    if (this.handleCanShowRegularTable()) {
      const pesquisasSelecionaveis = this.pesquisas.filter(
        (pesquisa) => pesquisa.status !== "EXECUCAO"
      );
      if (pesquisasSelecionaveis.length === this.pesquisasSelecionadas.length) {
        return pesquisasSelecionaveis.every(({ id }, index) => {
          if (this.pesquisasSelecionadas.length > 0)
            return id === this.pesquisasSelecionadas[index].id;
          return false;
        });
      }
      return false;
    } else {
      if (this.paginateVirtualPesquisa[this.currentPage]) {
        const pesquisasSelecionaveis = this.paginateVirtualPesquisa[
          this.currentPage
        ].filter((pesquisa) => pesquisa.status !== "EXECUCAO");
        return pesquisasSelecionaveis.every((pesquisa) =>
          this.pesquisasSelecionadas.includes(pesquisa)
        );
      } else {
        return [];
      }
    }
  }

  // Função para alterar a visibilidade das opções do seletor
  handleMainCheckboxClick() {
    if (this.handleCanShowRegularTable()) {
      if (this.areAllPesquisaSelected()) {
        this.pesquisasSelecionadas = [] as Pesquisa[];
      } else {
        this.pesquisasSelecionadas = this.pesquisas.filter(
          (pesquisa) => pesquisa.status !== "EXECUCAO"
        );
      }
    } else {
      if (this.areAllPesquisaSelected()) {
        this.pesquisasSelecionadas = [] as Pesquisa[];
      } else {
        this.pesquisasSelecionadas = this.paginateVirtualPesquisa[
          this.currentPage
        ].filter((pesquisa) => pesquisa.status !== "EXECUCAO");
      }
    }
  }

  // exibe o prompt de executar
  mostrarPromptDeComandoDeExecutarPesquisa(pesquisaId: number) {
    this.modalData.title = "Alterando status para execução";
    this.modalData.icon = "fa-regular fa-play";
    this.modalData.messageModal =
      "Alterar o status para execução indica que a avaliação já está disponível para receber coletas dentro do período configurado.";
    this.modalData.btnTitlePositive = "Executar";
    this.modalData.btnTitleNegative = "Cancelar";
    this.modalData.positiveCallback = () => this.handleRunSurvey(pesquisaId);

    this.modalService.showModal({ ...this.modalData });
  }

  /**
   * Função que busca a pesquisa, após isso coloca o status da pesquisa para execução
   * @param pesquisaId id da pesquisa a ser colocada em execução
   */
  handleRunSurvey(pesquisaId: number) {
    this.isLoading = true;

    this.pesquisaService.buscarEditar(pesquisaId).subscribe({
      next: (survey) => {
        // Verificação se existe vinculos na pesquisa
        if (survey.vinculos && survey.vinculos.length) {
          /**
           * Caso haja o valor de vinculos é formatado para o valor esperado pelo backend,
           * removendo atributos desnecessários e reorganizando novos valores esperados pelo
           * endpoint.
           */
          survey.vinculos.forEach((vinculos) => {
            delete vinculos.localidade.possuiFilhos;
            delete vinculos.localidade.nome;
            delete vinculos.localidade.idLocalidadePai;
            delete vinculos.localidade.nomeLocalidadePai;
            vinculos.operadores.forEach((operador) => {
              (operador["operador"] = { id: operador.idOperador }),
                delete operador.idOperador;
            });
          });

          if (
            survey.configuracaoPesquisa &&
            survey.configuracaoPesquisa.amostrasPresenciais === 0
          ) {
            survey.vinculos = [];
          }
        }
        this.pesquisaService
          .atualizarPesquisa({ ...survey, status: StatusPesquisa.EXECUCAO })
          .subscribe({
            next: () => {
              this.notificatorService.showInfo(
                "Status alterado",
                "Avaliação em execução"
              );
            },

            error: (err) => {
              this.isLoading = false;
              if (err.error.status === 409) {
                this.modalService.showModal({
                  title: "Erro ao processar solicitação",
                  messageModal:
                    "Algumas informações da avaliação foram atualizadas enquanto processávamos sua solicitação. <br><br>Recarregue a página e tente novamente. <br><br>Se o problema persistir, entre em contato com o time de suporte",
                  btnTitlePositive: "Entendi",
                  isOnlyConfirmation: true,
                  icon: "fa-regular fa-circle-exclamation",
                });
              } else {
                this.errorHandlerService.handleError(err, "Erro no servidor");
              }
            },
            complete: () => {
              this.isLoading = false;
              this.refresh();
            },
          });

        this.removeSurveyFromSelected(
          this.pesquisas.find((pesquisa) => pesquisa.id === pesquisaId)
        );
      },

      error: (err) => {
        this.isLoading = false;
        this.errorHandlerService.handleError(err, "Erro na avaliação");
      },

      complete: () => {
        this.isLoading = false;
        this.refresh();
      },
    });
  }

  // exibe o prompt de comando para finalizar a pesquisa e chama a função para finalizar
  handlePromptFinishSearch(pesquisaId: number): void {
    this.modalData.title = "Alterando status para concluído";
    this.modalData.icon = "fa-regular fa-trophy";
    this.modalData.messageModal =
      "Alterar o status para concluído indica que sua avaliação foi finalizada.";
    this.modalData.btnTitlePositive = "Concluir";
    this.modalData.btnTitleNegative = "Cancelar";
    this.modalData.positiveCallback = () => {
      this.handleFinishSearch(pesquisaId);
    };

    this.modalService.showModal({ ...this.modalData });
  }

  promptConflictModaWhenFinishingSurvey() {
    this.modalService.showModal({
      title: "Erro ao processar solicitação",
      messageModal:
        "Algumas informações da avaliação foram atualizadas enquanto processávamos sua solicitação. <br><br>Recarregue a página e tente novamente. <br><br>Se o problema persistir, entre em contato com o time de suporte",
      btnTitlePositive: "Entendi",
      isOnlyConfirmation: true,
      icon: "fa-regular fa-circle-exclamation",
    });
  }

  // função para finalizar uma pesquisa
  async handleFinishSearch(pesquisaId: number) {
    this.isLoading = true;
    await this.pesquisaService
      .buscarEditar(pesquisaId)
      .toPromise()
      .then(async (item) => {
        let newResearch = item;

        newResearch.status = StatusPesquisa.CONCLUIDO;
        if (newResearch.vinculos) {
          let newBonds = [];
          for (let i = 0; i < newResearch.vinculos.length; i++) {
            // cria nova estrutura de dados
            const newBond = {
              id: newResearch.vinculos[i].id,
              localidade: {
                id: newResearch.vinculos[i].localidade.id,
                versao: newResearch.vinculos[i].localidade.versao,
              },
              cotaPercentual: newResearch.vinculos[i].cotaPercentual,
              cotaValor: newResearch.vinculos[i].cotaValor,
              hash: newResearch.vinculos[i].hash,
              operadores: (
                newResearch.vinculos[i].operadores as VinculoOperador[]
              ).map((o) => ({
                id: o.idVinculoOperador,
                operador: {
                  id: o.idOperador,
                },
                cotaPercentual: o.cotaPercentual,
                cotaValor: o.cotaValor,
              })),
            };
            // Coloca novo valor no array
            newBonds.push(newBond);
            // caso haja filhos
            const vinculo = newResearch.vinculos[i] as VinculoEdicao;
            if (vinculo.filhos.length > 0) {
              for (
                let vinculoIndex = 0;
                vinculoIndex < vinculo.filhos.length;
                vinculoIndex++
              ) {
                // cria nova estrutura de dados
                const newBond = {
                  id: vinculo.filhos[vinculoIndex].id,
                  localidade: {
                    id: vinculo.filhos[vinculoIndex].localidade.id,
                    versao: vinculo.filhos[vinculoIndex].localidade.versao,
                  },
                  cotaPercentual: vinculo.filhos[vinculoIndex].cotaPercentual,
                  cotaValor: vinculo.filhos[vinculoIndex].cotaValor,
                  hash: vinculo.filhos[vinculoIndex].hash,
                  operadores: (
                    vinculo.filhos[vinculoIndex].operadores as VinculoOperador[]
                  ).map((o) => ({
                    id: o.idVinculoOperador,
                    operador: {
                      id: o.idOperador,
                    },
                    cotaPercentual: o.cotaPercentual,
                    cotaValor: o.cotaValor,
                  })),
                };
                // Coloca novo valor no array
                newBonds.push(newBond);
              }
            }
          }
          newResearch.vinculos = newBonds;
        }
        await this.pesquisaService
          .atualizarPesquisa(newResearch)
          .toPromise()
          .then((result) => {
            this.notificatorService.showInfo(
              "Status alterado",
              "Avaliação concluída"
            );
          })
          .catch((error) => {
            if (error?.error?.status === 409) {
              this.promptConflictModaWhenFinishingSurvey();
            } else {
              this.errorHandlerService.handleError(error, "Erro no servidor");
            }
          })
          .finally(() => {
            // recarrega pesquisas
            this.refresh();
          });
      });

    this.isLoading = false;
  }

  // Função que lida com cliques de opções de pesquisa
  handleKebabClick(event: {
    action: string;
    pesquisaId: number;
    pesquisaEmExecucao: boolean;
    status: string;
  }) {
    this.action = event.action;
    this.surveyId = event.pesquisaId;
    switch (event.action) {
      case "excluir":
        this.mostrarPromptDeComandoDeExcluirPesquisa([event.pesquisaId]);
        break;
      case "arquivar":
        this.handleArquivarPesquisas([event.pesquisaId]);
        break;
      case "executar":
        this.mostrarPromptDeComandoDeExecutarPesquisa(event.pesquisaId);
        break;
      case "view-allocations":
        const tokenString = this.jwtService.getToken();
        const urlLink = `${reporterUrl}/?id=${event.pesquisaId}&token=${tokenString}`;

        window.open(urlLink, "_blank");
        break;
      case "desarquivar":
        this.handleDesarquivarPesquisas([event.pesquisaId], event.status);
        break;
      case "duplicar":
        this.mostrarPromptDeComandoDeCopiarPesquisa(event.pesquisaId);
        break;
      case "editar":
        this.router.navigate(["/avaliacao/cadastro-beta"], {
          queryParams: { id: event.pesquisaId },
        });
        break;
      case "information":
        this.router.navigate(["/avaliacao/informacoes-beta"], {
          queryParams: { id: event.pesquisaId },
        });
        break;
      case "exportar":
        this.currentPesquisaIdRef = event.pesquisaId;
        this.handleCanShowModalSelectOptions(event.status);
        break;
      case "concluir":
        this.handlePromptFinishSearch(event.pesquisaId);
        break;
      case "qr-code":
        this.getFormsSurveyLink(event.pesquisaId, TokenType.EXECUCAO)
          .then((url: string) => this.qrCodeService.baixarQrCode(url))
        break;
      case "link-open":
        this.getFormsSurveyLink(event.pesquisaId, TokenType.VISUALIZACAO)
          .then((url: string) => {
            window.open(url, "_blank");
          });
        break;
      case "link-copy":
        this.getFormsSurveyLink(event.pesquisaId, TokenType.EXECUCAO)
          .then((url: string) => {
            this._clipboardService.copy(
              url
            );
        
            this.notificatorService.showInfo(
              "Link copiado",
              "Compartilhe sua avaliação"
            );
          });
        break;
    }
  }

  // Recupera o link da pesquisa com base no token informado
  getFormsSurveyLink(
    surveyId: number,
    tokenType: TokenType,
  ) {
    return new Promise((resolve) => {
      this.pesquisaService
      .buscarEditar(surveyId)
      .toPromise()
      .then((survey) => {
        const token =
          tokenType === TokenType.EXECUCAO
            ? survey.configuracaoPesquisa.token
            : survey.configuracaoPesquisa.tokenVisualizacao;
          
        this.pesquisaService
          .getFriendlyToken(token)
          .toPromise()
          .then((item) => {
            const url = `${formsLocation}/${item.token_amigavel}`;
            resolve(url);
          })
          .catch((err) =>
            this.errorHandlerService.handleError(err, "Erro ao copiar link")
          );
      })
      .catch((err) =>
        this.errorHandlerService.handleError(err, "Erro ao copiar link")
      );
    });
  }

  handlePreviousPage() {
    if (this.handleCanShowRegularTable()) {
      this.ultimoDadoPaginacao = new DadosPaginacao(
        PAGE_SIZE,
        this.virtualCurrentPage - 1
      );

      this.refreshResearch();
    } else {
      if (this.currentPage === 0) {
        return;
      } else {
        this.pesquisasSelecionadas = [] as Pesquisa[];
        this.currentPage = this.virtualCurrentPage;
      }
    }
  }

  handleChangePage(page: number) {
    this.virtualCurrentPage = page;

    if (page > this.currentPage) {
      this.handleNextPage();
    } else if (page !== this.currentPage) {
      this.handlePreviousPage();
    }
  }

  handleNextPage() {
    if (this.handleCanShowRegularTable()) {
      this.ultimoDadoPaginacao = new DadosPaginacao(
        PAGE_SIZE,
        this.virtualCurrentPage - 1
      );

      this.refreshResearch();
    } else {
      this.pesquisasSelecionadas = [] as Pesquisa[];
      this.currentPage = this.virtualCurrentPage;
    }
  }

  handleRemoveClickedItemHover() {
    const duplicatedItem = this.pesquisas.find(
      (pesquisaItem) => pesquisaItem.isDuplicated
    );

    if (duplicatedItem) {
      delete duplicatedItem.isDuplicated;
    }
  }

  handleClickInsideDuplicatedItem(event: Event, isDuplicated: boolean = false) {
    if (isDuplicated) {
      this.insideDuplicatedItem = true;
    }

    if (isDuplicated && this.insideDuplicatedItem) {
      this.insideDuplicatedItem = false;
      return;
    }

    if (this.insideDuplicatedItem && !isDuplicated) {
      this.insideDuplicatedItem = false;
      return;
    }
  }

  /**
   * Desabilita o filtro de acordo com a opção selecionada
   * Se o usuário selecionar a opção ARQUIVADA todos outros filtros serão desabilitados
   * Se o usuário selecionar CONCLUIDA, EM EXECUÇÃO ou RASCUNHO a opção arquivada
   * será desabilitada
   * @returns opções desabilitadas
   */
  handleDisableOption(): Array<number> {
    const disabledOptions = [];
    this.selectedFilterOptions.forEach((option) => {
      if (option.key === SurveySituations.ARQUIVADA) {
        disabledOptions.push(1, 2, 4);
      } else {
        disabledOptions.push(3);
      }
    });
    return disabledOptions;
  }
}

<app-breadcrumb [data]="dataBreadcrumb" [goBackText]="'Voltar às avaliações'"></app-breadcrumb>

<main class="visao-geral-main">
  <app-header-produtividade *ngIf="header" [tituloHeader]="header?.titulo" [item1]="header.periodo"
    [item2]="header.resumo" [progresso]="header.progresso"></app-header-produtividade>

  <app-podium *ngIf="generalVisionData" [podiumData]="generalVisionData.podio"></app-podium>

  <div class="container-infos">
    <app-card-info-media *ngIf="generalVisionData" title="Média de coletas por dia" infoTitle="Média geral:"
      [infoValue]="generalVisionData?.coletas_por_dia.media" [surveyId]="surveyId" [type]="cardType.COLETAS_POR_DIA"
      [data]="formatterDataColetas()"></app-card-info-media>
    <app-card-info-media *ngIf="generalVisionData" title="Aprovação em auditoria" infoTitle="Média de aprovações:"
      [infoValue]="generalVisionData?.aprovacoes.media" [surveyId]="surveyId" [type]="cardType.APROVACOES"
      [data]="formatterDataAprovacoes()"></app-card-info-media>
    <app-card-info-media *ngIf="generalVisionData" title="Tempo médio de coleta" infoTitle="Média geral:"
      [infoValue]="generalVisionData?.tempo_medio_coleta.media" [surveyId]="surveyId"
      [type]="cardType.TEMPO_MEDIO_COLETA" [data]="formatterDataTempoMedio()"></app-card-info-media>
  </div>
</main>

<app-loading *ngIf="isLoading"></app-loading>
import { SurveyListSituations } from "../../constant";

/**
 * Expõe um método útil para resolver a cor associada
 * à uma situação de uma pesquisa auditoria, isto foi criado
 * pois existem diversos pontos do sistema que acabam por ter que
 * reutilizar essa lógica.
 */
export class PesquisaAuditoriaStatusColorResolver {

    private static mapeamento = {
        [SurveyListSituations.APROVADA]: "var(--status-done)",
        [SurveyListSituations.ARCHIVED]: "var(--alert200)",
        [SurveyListSituations.AUDITADA]: "var(--status-done)",
        [SurveyListSituations.EM_ANALISE]: "var(--status-in-progress)",
        [SurveyListSituations.INAUDIT]: "var(--status-in-progress)",
        [SurveyListSituations.NOTAUDITED]: "var(--gray200)",
        [SurveyListSituations.REPROVADA]: "var(--status-error)"
    };

    private static defaultColor = "var(--status-error)";

    /**
     * 
     * @param status Devolve a cor associada à situação de uma pesquisa auditoria
     */
    static resolve(status: SurveyListSituations) {
        return this.mapeamento[status] || this.defaultColor;
    }
}
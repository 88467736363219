<app-breadcrumb [data]="dataBreadcrumb"></app-breadcrumb>

<app-info-card
  [title]="infoCardData.title"
  [text]="infoCardData.text"
  [data]="infoCardData.data"
  [textMaxWidth]="'65%'"
  >
</app-info-card>

<div class="table-list">
  <app-generic-table
    [tableConfig]="tableConfig"
    [tHead]="tableHead"
    [data]="tableData"
    [enableFilters]="true"
    [enableSearch]="true"
    [searchInputConf]="searchConfig"
    [disabledEvents]="['ROW_CLICK']"
    [filters]="filters"
    (events)="handleEventTable($event)"
    [currentPage]="dadosPaginacao.current"
    [totalOfEntries]="dadosPaginacao.totalOfEntries"
    paginationLabel="Avaliações"
    [showTotalOfEntries]="true"
  ></app-generic-table>
</div>
<app-loading *ngIf="!requestLoadingState.allDataLoaded()"></app-loading>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  IAnswaresList,
  IQuestion,
} from "app/modulos/auditoria-beta/interfaces/audit-question-collections";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";
import { AnswerOutput } from "../questions.component";

@Component({
  selector: "app-numeric-scale-question",
  templateUrl: "./numeric-scale-question.component.html",
  styleUrls: ["./numeric-scale-question.component.scss"],
})
export class NumericScaleQuestionComponent implements OnInit {
  selectedAlternative: number = null;
  userChoice = -1;
  isAnswerReseted: boolean = false;
  hasChanged: boolean = false;

  @Input() questionData: IQuestion = null;
  @Output() onChange = new EventEmitter<AnswerOutput>(null);

  constructor() {}

  private setHasChangedState(state: boolean) {
    if (this.hasChanged !== state) {
      this.hasChanged = state;
    }
  }

  private get auditorAnswer() {
    return [
      ...((this.questionData.respostas.auditor || [null]) as IAnswaresList[]),
    ].pop();
  }

  private get interviewerAnswer() {
    return [
      ...((this.questionData.respostas.entrevistador || [
        null,
      ]) as IAnswaresList[]),
    ].pop();
  }

  private getAnswer(from: IAnswaresList) {
    if (from) return from?.id_alternativa || null;
    return (
      this.auditorAnswer?.id_alternativa ||
      this.interviewerAnswer?.id_alternativa ||
      null
    );
  }

  ngOnInit(): void {
    this.initAnswers();

    if (this.auditorAnswer) {
      this.setHasChangedState(true);
    }
  }

  private initAnswers(from?: IAnswaresList) {
    if (
      this.questionData?.respostas?.auditor ||
      this.questionData?.respostas?.entrevistador
    ) {
      this.selectedAlternative = this.getIndex(this.getAnswer(from));
      this.userChoice = this.getIndex(
        this.interviewerAnswer && this.interviewerAnswer.id_alternativa
      );
      this.emitAnswer();
    }
  }

  private emitAnswer() {
    this.onChange.emit({
      type: TipoPergunta.ESCALA_NUMERICA,
      questionId: this.questionData.id,
      answer: this.getId(),
    });
  }

  public resetAnswer() {
    this.isAnswerReseted = true;
    this.initAnswers(this.interviewerAnswer);
    this.setHasChangedState(false);
  }

  /**
   * Controls user selection
   * @param index: clicked question index
   */
  handleCheck(index: number): void {
    this.isAnswerReseted = false;
    this.selectedAlternative = index;
    this.emitAnswer();
    this.setHasChangedState(true);
  }

  /**
   * Clear user selection
   */
  handleClearCheck(): void {
    this.isAnswerReseted = false;
    this.selectedAlternative = null;
    this.emitAnswer();
  }

  getId() {
    if (this.selectedAlternative === null) return null;
    return {
      id_alternativa: this.questionData.alternativas[this.userChoice].id,
      id_alternativa_auditada:
        this.questionData.alternativas[this.selectedAlternative].id,
      ordem: this.questionData.alternativas[this.selectedAlternative].ordem,
      respondida: !this.isAnswerReseted,
    } as IAnswaresList;
  }

  /**
   * Procura o indice de uma alternativa pelo id no array de alternativas
   * @param alternativeId id da alternativa que se deseja encontrar a posição
   * @returns retorna um indice da posição da alternativa
   */
  getIndex(alternativeId: number): number {
    return this.questionData.alternativas.findIndex(
      ({ id }) => alternativeId === id
    );
  }
}

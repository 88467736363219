<div [ngClass]="{
    'alternative': true,
    'blockActions': actionsBlockedBySituation,
    selected: isChecked,
    preselect: !!preSelected,
    checkbox: renderType === 'checkbox'
  }">
  <input *ngIf="renderCheckbox" (click)="handleCheck(alternativa.id)" type="button" name="selectable-alternatives" />

  <textarea [ngClass]="!renderCheckbox && 'singleRenderMode'" (click)="handleInputFocus(alternativa.id)" #textareaRef
    class="text-input" maxlength="255" [placeholder]="alternativa.enunciado" (input)="handleInput($event)"
    [disabled]="!this.selecionavel && !this.isChecked" [(ngModel)]="value"></textarea>
</div>
<section>
  <div class="disabled-info" *ngIf="blockActionsBySurveySituation">
    <i class="fa-regular fa-circle-info"></i>
    <span>Essa pesquisa já foi auditada e não pode mais ser alterada</span>
  </div>
  <div class="selector-card">
    <h5>Análise de respostas abertas</h5>
    <p>Selecione uma pergunta para edição das respostas</p>
    <div class="selector-wrapper">
      <app-filterable-select filterPlaceholder="Buscar..." label="" placeholder="Selecione uma pergunta..."
        [data]="questionData" usingBy="AUDITORIA" (changeSelectedItem)="changeSelectedItem($event)"
        [selectedItem]="null">
      </app-filterable-select>
    </div>
  </div>
  <div *ngIf="!selectedQuestionId" class="empty-anwser">
    <p>
      {{ messageContent }}
    </p>
  </div>

  <div *ngIf="selectedQuestionId" class="consolidation-container">
    <app-step-one *ngIf="requestCompleted" [groupList]="groupArray" [citationList]="avulsesArray" [surveyId]="surveyId"
      [questionId]="selectedQuestionId" [blockActionsBySurveySituation]="blockActionsBySurveySituation"></app-step-one>
    <app-step-two [groups]="groupArray" (matchAnswers)="matchCitationAndGroup($event)"
      [blockActionsBySurveySituation]="blockActionsBySurveySituation"></app-step-two>
  </div>
</section>
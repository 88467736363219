import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  SelectDataItem,
  SelectedItemEvent,
} from "app/componentes/filterable-select/filterable-select-component";
import {
  MINIUM_ELEMENTS_FOR_CONSOLIDATION,
  SituacaoPesquisa,
} from "app/modulos/auditoria-beta/constant";
import {
  ICitation,
  ICitationAndGroupResponse,
  IGroup,
  IRemoveAnswerOrChild,
  ISelectedAvulsesAndGroup,
  RemovedElementType,
} from "app/modulos/auditoria-beta/interfaces/audit-open-answer";
import { IQuestionOpenAnswer } from "app/modulos/auditoria-beta/interfaces/open-answers";
import { AuditoriaBetaService } from "app/modulos/auditoria-beta/services/auditoria-beta.service";
import { AutomatchService } from "app/modulos/auditoria-beta/services/consolidacao/automatch.service";
import { ConsolidationService } from "app/modulos/auditoria-beta/services/consolidacao/consolidation.service";
import { MatchOperationsService } from "app/modulos/auditoria-beta/services/consolidacao/match-operations.service";
import { MatchPersistenceService } from "app/modulos/auditoria-beta/services/consolidacao/match-persistance.service";
import { getIndexByList } from "app/modulos/auditoria-beta/utils/getIndexByArray";
import {
  parseAvulseQuote,
  parseConsolidationData,
} from "app/modulos/auditoria-beta/utils/parsers/consolidacaoParser";
import { parseQuestionToAccordenModel } from "app/modulos/auditoria-beta/utils/parsers/openAnswers";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";

@Component({
  selector: "app-open-answers",
  templateUrl: "./open-answers.component.html",
  styleUrls: ["./open-answers.component.scss"],
})
export class OpenAnswersComponent implements OnInit, OnDestroy {
  @Input() auditSurveySituation: SituacaoPesquisa;
  groupArray: Array<IGroup> = [];
  avulsesArray: Array<ICitation> = [];
  surveyId: number = null;
  requestCompleted: boolean = false;
  blockActionsBySurveySituation: boolean = false;

  messageContent = "Selecione uma pergunta para iniciar a análise";
  selectedQuestionId: number = null;
  selectedAvulsesAndGroups: ISelectedAvulsesAndGroup = null;

  constructor(
    private auditoriaService: AuditoriaBetaService,
    private route: ActivatedRoute,
    private automatchService: AutomatchService,
    private matchOperationsService: MatchOperationsService,
    private matchPersistenceService: MatchPersistenceService,
    private consolidationService: ConsolidationService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  questionData: SelectDataItem[] = [];

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.params.id;
    this.verifySurveySituation();
    this.getQuestions();

    this.matchOperationsService.initSubscriptions({
      onUpdateAvulseTitle: this.editAvulseTitle.bind(this),
      onUpdateGroupTitle: this.editGroupTitle.bind(this),
      onUpdateSelectedCitationAndGroup: this.selectAvulsesAndGroup.bind(this),
      onUpdateConsolidationElementRemoved:
        this.handleRemoveCitationOrConsolidation.bind(this),
    });
  }

  ngOnDestroy(): void {
    this.matchOperationsService.unsubscribe();
  }

  /**
   * Bloqueia as ações do usuário com base na situação da pesquisa
   */
  private verifySurveySituation(): void {
    if (
      this.auditSurveySituation === SituacaoPesquisa.AUDITADA ||
      this.auditSurveySituation === SituacaoPesquisa.ARQUIVADA
    ) {
      this.blockActionsBySurveySituation = true;
    } else {
      this.blockActionsBySurveySituation = false;
    }
  }

  // Recupera as perguntas da pesquisa
  getQuestions(): void {
    this.auditoriaService.getSurveyQuestions(this.surveyId).subscribe({
      next: (data: IQuestionOpenAnswer[]) => {
        this.questionData = parseQuestionToAccordenModel(data);
      },
      error: (error) => {
        this.errorHandlerService.handleError(
          error,
          "Erro ao recuperar as perguntas da pesquisa"
        );
      },
    });
  }

  // Recupera as citações avulsas e consolidações
  getAvulsesAndConsolidations(): void {
    this.auditoriaService
      .getAvulsesAndConsolidations(this.surveyId, this.selectedQuestionId)
      .subscribe({
        next: (data: ICitationAndGroupResponse) => {
          this.avulsesArray = parseAvulseQuote(data.avulsas);
          this.groupArray = parseConsolidationData(data.consolidacoes);
        },
        error: (err) => {
          this.errorHandlerService.handleError(
            err,
            "Erro ao recuperar as respostas"
          );
        },
        complete: () => {
          this.requestCompleted = true;
          this.autoMatchAvulses();
        },
      });
  }

  // Muda a pergunta da pesquisa
  changeSelectedItem(event: SelectedItemEvent): void {
    this.selectedQuestionId = event.item.value;
    this.getAvulsesAndConsolidations();
  }

  // Obtem os grupos e/ou respostas que estão selecionados
  selectAvulsesAndGroup(selectedItems: ISelectedAvulsesAndGroup): void {
    if (selectedItems) {
      this.selectedAvulsesAndGroups = selectedItems;
    }
  }

  // Salva a alteração do titulo de uma alternativa em seguida executa o algoritimo de consolidação automática
  editAvulseTitle(data): void {
    if (data && this.requestCompleted) {
      const editingIndex = getIndexByList(data.id, this.avulsesArray);
      this.avulsesArray[editingIndex].title = data.newTitle;
      this.autoMatchAvulses();
    }
  }

  // Salva a alteração do titulo de um grupo
  editGroupTitle(data): void {
    if (data && this.requestCompleted) {
      const editingIndex = getIndexByList(data.id, this.groupArray);
      const newGroup = { ...this.groupArray[editingIndex] };
      newGroup.title = data.newTitle;

      this.persistUserNewConsolidation(newGroup, false, true);
    }
  }

  // Cria uma nova consolidação MANUAL a partir de respostas e/ou grupos de respostas e persiste no back-end
  async matchCitationAndGroup(group: IGroup) {
    if (this.selectedAvulsesAndGroups) {
      const newGroup = await this.consolidationService.chooseMatchFlow(
        group,
        this.selectedAvulsesAndGroups,
        this.surveyId,
        this.selectedQuestionId
      );
      this.groupArray = this.matchOperationsService.removeGroups(
        this.groupArray,
        this.selectedAvulsesAndGroups
      );
      this.groupArray.push({ ...newGroup });
      this.avulsesArray =
        this.matchOperationsService.removeAvulseMatchedWithinGroups(
          this.avulsesArray,
          this.groupArray
        );
      await this.persistUserNewConsolidation(newGroup);
    }
  }

  // Persiste a consolidação manual que foi criada/editada
  persistUserNewConsolidation(
    newGroup: IGroup,
    isDeleteFlow: boolean = false,
    isTitleEditFlow: boolean = false
  ) {
    this.matchPersistenceService
      .getAndPersistUserConsolidation(
        { ...newGroup },
        this.groupArray,
        this.surveyId,
        this.selectedQuestionId,
        isDeleteFlow,
        isTitleEditFlow
      )
      .then(() => {
        this.getAvulsesAndConsolidations();
      });
  }

  // Cria uma consolidação automática e persiste no back-end
  autoMatchAvulses(): void {
    if (this.blockActionsBySurveySituation) {
      return;
    }
    const { groups, avulses } = this.automatchService.processAutoMatch(
      this.avulsesArray,
      this.groupArray
    );
    this.groupArray = groups;
    this.avulsesArray = avulses;
    const automatchGroups = this.automatchService.getAutoMatchGroups(
      this.groupArray
    );
    if (automatchGroups.length) {
      this.matchPersistenceService.getAndPersistConsolidation(
        automatchGroups,
        this.groupArray,
        this.surveyId,
        this.selectedQuestionId
      );
    }
  }

  /**
   * Remove uma citação ou consolidação de um grupo e dispara uma flash message e em seguida executa o algoritimo de consolidação automática
   */
  handleRemoveCitationOrConsolidation(data: IRemoveAnswerOrChild): void {
    if (data) {
      const { idElement, idGroup, type } = data;
      const groupIndex = getIndexByList(idGroup, this.groupArray);
      const editingGroup = { ...this.groupArray[groupIndex] };
      const elementIndex = getIndexByList(idElement, editingGroup.citation);

      if (type === RemovedElementType.CONSOLIDATION) {
        this.removeChildGroup(elementIndex, editingGroup);
      } else {
        this.removeCitation(elementIndex, editingGroup);
      }

      this.editOrDeleteConsolidation(groupIndex, editingGroup);
      this.autoMatchAvulses();
    }
  }

  /**
   * Responsável pela lógica de remoção da consolidação filha
   */
  removeChildGroup(childIndex: number, editingGroup: IGroup): void {
    const [removeChildGroup] = editingGroup.groups.splice(childIndex, 1);
    this.groupArray.push(removeChildGroup as IGroup);
  }

  /**
   * Responsável pela lógica de remoção da citação
   */
  removeCitation(citationIndex: number, editingGroup: IGroup): void {
    const [removedCitation] = editingGroup.citation.splice(citationIndex, 1);
    this.avulsesArray.push(removedCitation);
  }

  /**
   * Se a consolidacao tiver 3 ou mais elementos o endpoint chamada sera o [PATCH] - editando para remover uma citacação
   * Se a consolidacao tiver 2 elementos o endpoint chamado sera o [DELETE] - desfazendo a consolidação conforme RN
   */
  editOrDeleteConsolidation(groupIndex: number, editingGroup: IGroup): void {
    const groupLength =
      editingGroup.citation.length + editingGroup.groups.length;

    // remove o grupo se o numero de elementos internos for < 2
    if (groupLength < MINIUM_ELEMENTS_FOR_CONSOLIDATION) {
      this.handleUndoConsolidation(groupIndex);
    } else {
      this.persistUserNewConsolidation(editingGroup, true);
    }
  }

  /**
   * Realiza a request para desfazer uma consolidação
   * @param groupIndex: indice do grupo que está sendo alterado
   */
  handleUndoConsolidation(groupIndex: number): void {
    this.matchPersistenceService
      .undoGroupByIndex(
        this.surveyId,
        this.selectedQuestionId,
        this.groupArray,
        groupIndex
      )
      .then(() => {
        this.getAvulsesAndConsolidations();
      });
  }
}

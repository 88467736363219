import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiLocation } from "app/infraestrutura/apiLocation";
import { RequestService } from "app/servico/request.service";
import { CrudService } from "app/servico/requestService/crudService";
import { Observable } from "rxjs";
import {
  SurveyListOrderTypes,
  SurveyListSituations,
  SurveyListSortingAttributes,
} from "../constant";
import {
  IAuditCollectionsResponse,
  IAuditObservationsGetQuery,
  IAuditObservationsResponse,
  IAuditedCollectionsPayload,
  ICollectionSummaryQuery,
  ICollectionsQueryParams,
  IFilterSurveyResults,
  IInfoCardPayload,
  IOverviewData,
  ISurveyListResponse,
  ISurveyMapQuery,
  ISurveyResumoColetaResponse,
} from "../interfaces";
import { IFilterCollectionResults } from "../interfaces/audit-collections-section";
import {
  IAuditCollectionVerdictPayload,
  IQuestionResponse,
} from "../interfaces/audit-question-collections";
import { IQuestionOpenAnswer } from "../interfaces/open-answers";
import { QueryParamsResolver } from "app/util/formatter/queryParams";
import {
  ICitation,
  ICitationAndGroupResponse,
  ICitationResponse,
  IConsolidationBodyResponse,
  IConsolidationPayload,
} from "../interfaces/audit-open-answer";
import {
  IDateListResponse,
  ILocationListResponse,
  IMapResponsePayload,
  IOperatorsListResponse,
} from "../interfaces/survey-map";

@Injectable({
  providedIn: "root",
})
export class AuditoriaBetaService extends CrudService<any> {
  public baseUrl: string = this.resourcePath;

  constructor(
    protected requestService: RequestService,
    private http: HttpClient
  ) {
    super(requestService, `${apiLocation}`);
  }

  /**
   * Retorna os dados do banner de resumo de auditoria (info-card)
   */
  getInfoCardData(): Observable<IInfoCardPayload> {
    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/resumo`;
    const response = <Observable<IInfoCardPayload>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  /**
   * Retorna os dados relacionados à listagem de pesquisas em auditoria.
   */
  getSurveyListData(
    currentPage: number = 1,
    size: number = 10,
    attr?: SurveyListSortingAttributes | "",
    order?: SurveyListOrderTypes | "",
    keywords: string[] = [],
    status: SurveyListSituations[] = []
  ): Observable<ISurveyListResponse[]> {
    const pageParam = `page=${currentPage}`;
    const sizeParam = `&size=${size}`;
    const sortParam = attr ? `&sort=${attr}` : "";
    const directionParam = order ? `&direction=${order}` : "";

    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/filtrar/palavra-chave?${pageParam}${sizeParam}${sortParam}${directionParam}`;

    const requestPayload = {
      palavrasChave: keywords,
      situacoes: status,
    };

    return this.requestService.post(requestUrl, requestPayload) as Observable<
      ISurveyListResponse[]
    >;
  }

  /**
   * Arquiva uma ou mais pesquisas
   * @param surveyIds ids das pesquisas
   * @returns
   */
  archiveAuditSurvey(surveyIds: number[]): Observable<any> {
    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/arquivar`;
    const response = this.requestService.post(requestUrl, surveyIds);

    return response;
  }

  /**
   * Retorna os dados relacionados à visualização do mapa de uma pesquisa selecionada.
   */
  getSurveyMapDetails(
    query: ISurveyMapQuery
  ): Observable<HttpResponse<IMapResponsePayload>> {
    const {
      surveyId,
      id_operador,
      localidades,
      situacao,
      periodo_fim,
      periodo_inicio,
    } = query;

    const params = new QueryParamsResolver({
      id_operador,
      localidades: localidades?.join(","),
      situacoes: situacao?.join(","),
      periodo_inicio,
      periodo_fim,
    }).toObject();

    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/mapa`;
    return this.requestService.get(requestUrl, undefined, false, params);
  }

  /**
   * Recupera o periodo das coletas
   */
  getPeriod(idPesquisaAuditada: number): Observable<IDateListResponse[]> {
    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${idPesquisaAuditada}/agendamentos`;
    const response = this.requestService.get(requestUrl);

    return response;
  }

  /**
   * Recupera as localidades de uma pesquisa
   */
  getLocations(
    idPesquisaAuditada: number
  ): Observable<ILocationListResponse[]> {
    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${idPesquisaAuditada}/localidades`;
    const response = this.requestService.get(requestUrl);

    return response;
  }

  /**
   * Retorna a lista de operadores para o campo de busca utilizado no mapa
   */
  getAllOperators(
    idPesquisaAuditada: number
  ): Observable<IOperatorsListResponse> {
    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${idPesquisaAuditada}/operadores`;
    const response = this.requestService.get(requestUrl);

    return response;
  }

  /**
   * Retorna o resumo de uma coleta por pesquisa
   */
  getCollectionSumary(query: ICollectionSummaryQuery) {
    const { surveyId, collectionId } = query;
    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/coletas/${collectionId}/resumo`;

    return <Observable<ISurveyResumoColetaResponse>>(
      this.requestService.get(requestUrl)
    );
  }

  /**
   * Retorna as observações de uma coleta dado o id da pesquisa e o id da coleta.
   */
  getCollectionObservations(query: IAuditObservationsGetQuery) {
    const { surveyId, collectionId } = query;

    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/coletas/${collectionId}/observacoes`;
    return <Observable<IAuditObservationsResponse>>(
      this.requestService.get(requestUrl)
    );
  }

  /**
   * Atualiza as observações de uma coleta através do id da pesquisa e o id da coleta.
   */
  createCollectionObservations(
    query: IAuditObservationsGetQuery,
    payload: { conteudo: string }
  ) {
    const { surveyId, collectionId } = query;

    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/coletas/${collectionId}/observacoes/comentarios`;
    return this.requestService.post(requestUrl, { ...payload });
  }

  /**
   * Retorna os dados das coletas auditadas referente a uma pesquisa
   */
  getAuditedCollectionsHeaderData(
    surveyId: number
  ): Observable<IAuditedCollectionsPayload> {
    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${surveyId}`;
    const response = <Observable<IAuditedCollectionsPayload>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  /**
   * Retorna os dados relacionados à listagem de coletas de uma pesquisa em auditoria
   */
  getCollectionsListData(
    surveyId: number,
    options: ICollectionsQueryParams
  ): Observable<IAuditCollectionsResponse[]> {
    const {
      currentPage,
      surveysPerPage,
      filtrableAttributes,
      filtrableOrder,
      keywords,
      status,
    } = options;

    const pageParam = `page=${currentPage - 1}`;
    const sizeParam = `&size=${surveysPerPage}`;
    const attributeParam = filtrableAttributes
      ? `&atributo=${filtrableAttributes}`
      : "";
    const orderParam = filtrableOrder ? `&order=${filtrableOrder}` : "";

    const requestUrl = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/coletas/filtrar/palavra-chave?${pageParam}${sizeParam}${attributeParam}${orderParam}`;

    const requestPayload = {
      palavrasChave: keywords,
      situacoes: status,
    };

    return this.requestService.post(requestUrl, requestPayload) as Observable<
      IAuditCollectionsResponse[]
    >;
  }

  /**
   * Obtem o total de pesquisas cadastradas baseado no filtro informado
   * @param filters: filtros utiliizados pelo usuário
   * @returns Inteiro que representa o valor do total de pesquisas cadastradas
   */
  getTotalSurveys(filters: IFilterSurveyResults): Observable<number> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/filtrar/palavra-chave/total-registros`;

    const response = <Observable<number>>(
      this.requestService.post(requestUrl, filters)
    );
    return response;
  }

  /**
   * Obtem o total de coletas cadastradas baseado no filtro informado
   * @param filters: filtros utiliizados pelo usuário
   * @returns Inteiro que representa o valor do total de coletas cadastradas
   */
  getTotalCollections(
    filters: IFilterCollectionResults,
    surveyId
  ): Observable<number> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/coletas/filtrar/palavra-chave/total-registros`;

    const response = <Observable<number>>(
      this.requestService.post(requestUrl, filters)
    );
    return response;
  }

  /**
   * Obtem os dados da pesquisa na tela de visão geral
   * @param surveyId: id da pesquisa
   * @returns overviewData
   */
  getOverviewData(surveyId: number): Observable<IOverviewData> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/resumo`;

    const response = <Observable<IOverviewData>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  /**
   * Finaliza o processo de auditoria
   * @param surveyId: id da pesquisa
   */
  completeAuditProcess(surveyId: number): Observable<any> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}`;

    const response = <Observable<IOverviewData>>(
      this.requestService.put(requestUrl)
    );
    return response;
  }

  /**
   * Recupera os dados da coleta da pesquisa selecionada
   * @returns Retorna um obsevable do tipo QuestionResponse
   */
  getQuestionsToAudit(): Observable<IQuestionResponse> {
    const mockUrl: string = `/assets/mocks/audit-mocks/audit-questions-list.json`;
    const response: Observable<IQuestionResponse> =
      this.http.get<IQuestionResponse>(mockUrl);
    return response;
  }

  // Inicio da sessão de Consolidação de respostas Abertas

  /**
   * Recupera as perguntas de uma pesquisa
   * @param surveyId: id da Pesquisa
   * @returns Array Perguntas com respostas abertas
   */
  getSurveyQuestions(surveyId: number): Observable<IQuestionOpenAnswer[]> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/perguntas/abertas`;
    const response = <Observable<IQuestionOpenAnswer[]>>(
      this.requestService.get(requestUrl)
    );
    return response;
  }

  /**
   * Recupera citações avulsas e os grupos (consolidações)
   * @param surveyId: id da Pesquisa
   * @param questionId: id da Pergunta
   * @returns: Objeto com array de citações avulsas e array de consolidações
   */
  getAvulsesAndConsolidations(
    surveyId: number,
    questionId: number
  ): Observable<ICitationAndGroupResponse> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/perguntas/${questionId}/citacoes`;
    const response = this.requestService.get(requestUrl);

    return response;
  }

  /**
   * Cria e/ou edita uma nova consolidação
   * @param surveyId: id da Pesquisa
   * @param questionId: id da Pergunta
   */
  createOrEditConsolidations(
    surveyId: number,
    questionId: number,
    data: IConsolidationPayload
  ): Observable<IConsolidationBodyResponse> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/perguntas/${questionId}/consolidacoes`;
    const response = this.requestService.patch(requestUrl, data);

    return response;
  }

  /**
   * Remove uma consolidação com base no id informado
   * @param surveyId: id da Pesquisa
   * @param questionId: id da Pergunta
   * @param consolidationId: id da consolidação que será desfeita
   */
  undoConsolidation(
    surveyId: number,
    questionId: number,
    consolidationId: number
  ): Observable<any> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/perguntas/${questionId}/consolidacoes/${consolidationId}`;
    const response = this.requestService.delete(requestUrl);

    return response;
  }

  /**
   * Realiza a alteração do titulo de uma citação
   * @param surveyId: id da Pesquisa
   * @param questionId: id da Pergunta
   * @param newCitation: citação com o novo titulo
   */
  editCitationTitle(
    surveyId: number,
    questionId: number,
    newCitation: ICitationResponse
  ): Observable<any> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/perguntas/${questionId}/citacoes`;
    const response = this.requestService.patch(requestUrl, newCitation);

    return response;
  }

  // Fim da sessão de respostas abertas

  /**
   * Atualiza o status a coleta [Aprovada | Em análise | Reprovada]
   */
  updateCollectionState(
    surveyId: number,
    collectionId: number,
    collectionData: IAuditCollectionVerdictPayload
  ): Observable<any> {
    const requestUrl: string = `${this.resourcePath}/pesquisas/auditorias/${surveyId}/coletas/${collectionId}`;
    const response = this.requestService.put(requestUrl, collectionData);

    return response;
  }
}

<app-breadcrumb [data]="dataBreadcrumb" [goBackText]="'Voltar à lista de entrevistadores'"></app-breadcrumb>

<app-header-operador *ngIf="operador" [operador]="operador" [surveyTitle]="surveyTitle"></app-header-operador>

<div class="container-details-operator">

  <app-general-evaluation *ngIf="operador" [operador]="operador">
  </app-general-evaluation>

  <h2>Histórico de coletas</h2>

  <app-generic-table [tHead]="tHeadOperadorColetas" [data]="tableData" [enableFilters]="true" [filters]="tableFilter"
    [tableConfig]="tableConfig" [enableSearch]="true" [searchInputConf]="searchConfig" [paginationLabel]="'coletas'"
    [disabledEvents]="['ROW_CLICK']" [totalOfEntries]="totalCollections" [currentPage]="pagination.current"
    (events)="handleEventTable($event)"></app-generic-table>
</div>

<!-- modal de observações -->
<app-prompt-modal *ngIf="observationsModal.show" [modalData]="observationsModal">
  <app-modal-observations [surveyId]="surveyId" [collectionId]="collectionId">
  </app-modal-observations>
</app-prompt-modal>

<app-loading *ngIf="isLoading"></app-loading>

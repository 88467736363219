<section class="main-container">
  <h3>{{questionData.ordem}} - {{ questionData.enunciado }}</h3>
  <span *ngIf="questionData.obrigatoria" class="required-flag">
    <h4>Pergunta obrigatória</h4>
  </span>
  <div [ngClass]="{
    'numeric-container': true,
    'blockActions': actionsBlockedBySituation
  }">
    <div class="numeric-scale" *ngFor="let alternative; let i = index; of: questionData.alternativas">
      <div class="container-numbers" (click)="handleCheck(i)">
        <p [ngClass]="{
            'number-item': true,
            'selected-number-item': selectedAlternative === i
          }">
          {{ alternative.enunciado }}
        </p>
        <div [ngClass]="{
            'check-button': true,
            'user-choice': userChoice === i,
            'auditor-choice': selectedAlternative === i
          }"></div>
      </div>
    </div>
    <button *ngIf="!questionData.obrigatoria" (click)="handleClearCheck()" class="clean-btn">
      limpar
    </button>
  </div>
  <app-questionnaire-answer-reset *ngIf="hasChanged" (reset)="resetAnswer()"></app-questionnaire-answer-reset>
</section>
<nav class="navigation" (mouseover)="actionMenu()" (mouseout)="actionMenu()">
  <section class="logo">
    <div class="img" routerLinkActive="active" [routerLink]="'/'" [routerLinkActiveOptions]="{ exact: true }"
      (click)="onMenuItemClick(tipoMenu.DASHBOARDBETA)">
      <img *ngIf="!this.isShow" class="imagotipo" src="assets/icons/new-tensai-logo.svg" alt="logotipo tensai" />
      <img *ngIf="this.isShow" class="imagotipo" src="assets/icons/new-tensai-fulllogo.svg" alt="logotipo tensai" />
    </div>
  </section>

  <section class="menu">
    <ul>
      <li #dashboard title="Visão Geral" routerLinkActive="active" [routerLink]="'/'"
        [routerLinkActiveOptions]="{ exact: true }" (click)="onMenuItemClick(tipoMenu.DASHBOARDBETA)">
        <a class="newTab" routerLink="/">
          <i class="dashboard fa-regular fa-house"></i>
          <span>Visão geral</span>
        </a>
      </li>

      <li #analyze title="Analyze" routerLinkActive="active" *ngIf="showAnalyzeOption()"
        [class.disabled]="isActive(analyze)" (click)="onMenuItemClick(tipoMenu.ANALYZE)"
        (mouseenter)="analyseOptionIsHovered = true" (mouseleave)="analyseOptionIsHovered = false">
        <a class="newTab">
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.07147 21.5401C6.26332 21.5401 7.22694 20.6003 7.22694 19.438C7.22694 18.2757 6.26332 17.3359 5.07147 17.3359C3.87962 17.3359 2.916 18.2757 2.916 19.438C2.94136 20.6003 3.90498 21.5401 5.07147 21.5401Z" />
            <path
              d="M6.31462 16.1729C7.50647 16.1729 8.47009 15.2332 8.47009 14.0708C8.47009 12.9085 7.50647 11.9688 6.31462 11.9688C5.12277 11.9688 4.15915 12.9085 4.15915 14.0708C4.15915 15.2332 5.12277 16.1729 6.31462 16.1729Z" />
            <path
              d="M1.5206 10.5875C2.33207 10.5875 2.99139 9.94448 2.99139 9.15311C2.99139 8.36174 2.33207 7.71875 1.5206 7.71875C0.709125 7.71875 0.0498047 8.36174 0.0498047 9.15311C0.0498047 9.94448 0.709125 10.5875 1.5206 10.5875Z" />
            <path
              d="M9.00251 22.5773C9.58575 22.5773 10.0676 22.1075 10.0676 21.5387C10.0676 20.9699 9.58575 20.5 9.00251 20.5C8.41927 20.5 7.93745 20.9699 7.93745 21.5387C7.93745 22.1075 8.41927 22.5773 9.00251 22.5773Z" />
            <path
              d="M20.3617 17.5799C20.9956 17.5799 21.5282 17.0605 21.5282 16.4423C21.5282 15.824 20.9956 15.3047 20.3617 15.3047C19.7277 15.3047 19.1952 15.824 19.1952 16.4423C19.1952 17.0853 19.7024 17.5799 20.3617 17.5799Z" />
            <path
              d="M11.8422 15.7283C12.4762 15.7283 13.0087 15.209 13.0087 14.5907C13.0087 13.9725 12.4762 13.4531 11.8422 13.4531C11.2082 13.4531 10.6757 13.9725 10.6757 14.5907C10.6757 15.209 11.1829 15.7283 11.8422 15.7283Z" />
            <path
              d="M13.4146 12.1164C13.9978 12.1164 14.4796 11.6465 14.4796 11.0777C14.4796 10.5089 13.9978 10.0391 13.4146 10.0391C12.8313 10.0391 12.3495 10.5089 12.3495 11.0777C12.3495 11.6465 12.8313 12.1164 13.4146 12.1164Z" />
            <path
              d="M9.43317 12.218C10.0164 12.218 10.4982 11.7481 10.4982 11.1793C10.4982 10.6105 10.0164 10.1406 9.43317 10.1406C8.84993 10.1406 8.36812 10.6105 8.36812 11.1793C8.36812 11.7481 8.84993 12.218 9.43317 12.218Z" />
            <path
              d="M5.07147 7.32135C6.26332 7.32135 7.22694 6.38159 7.22694 5.21927C7.22694 4.05694 6.26332 3.11719 5.07147 3.11719C3.87962 3.11719 2.916 4.05694 2.916 5.21927C2.94136 6.38159 3.90498 7.32135 5.07147 7.32135Z" />
            <path
              d="M16.1045 21.0713C17.3471 21.0713 18.336 20.1068 18.336 18.895C18.336 17.6832 17.3471 16.7188 16.1045 16.7188C14.8619 16.7188 13.873 17.6832 13.873 18.895C13.873 20.1068 14.8873 21.0713 16.1045 21.0713Z" />
            <path
              d="M21.0726 13.5559C22.1377 13.5559 22.9999 12.7151 22.9999 11.6764C22.9999 10.6377 22.1377 9.79688 21.0726 9.79688C20.0076 9.79688 19.1454 10.6377 19.1454 11.6764C19.1707 12.7151 20.0329 13.5559 21.0726 13.5559Z" />
            <path
              d="M19.1699 6.6552C19.956 6.6552 20.6154 6.03694 20.6154 5.24557C20.6154 4.4542 19.9814 3.83594 19.1699 3.83594C18.3585 3.83594 17.7245 4.4542 17.7245 5.24557C17.7245 6.03694 18.3838 6.6552 19.1699 6.6552Z" />
            <path
              d="M14.1244 3.8038C14.809 3.8038 15.3416 3.25973 15.3416 2.61674C15.3416 1.94902 14.7837 1.42969 14.1244 1.42969C13.4397 1.42969 12.9072 1.97376 12.9072 2.61674C12.9072 3.28446 13.4397 3.8038 14.1244 3.8038Z" />
            <path
              d="M6.4922 2.35623C6.89794 2.35623 7.2276 2.03474 7.2276 1.63905C7.2276 1.24337 6.89794 0.921875 6.4922 0.921875C6.08647 0.921875 5.75681 1.24337 5.75681 1.63905C5.75681 2.03474 6.08647 2.35623 6.4922 2.35623Z" />
            <path
              d="M15.8737 6.65294C16.4062 6.65294 16.8373 6.23253 16.8373 5.71319C16.8373 5.19385 16.4062 4.77344 15.8737 4.77344C15.3412 4.77344 14.9101 5.19385 14.9101 5.71319C14.9101 6.23253 15.3412 6.65294 15.8737 6.65294Z" />
            <path d="M2.0537 16.4453L4.79241 19.7344" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M5.24899 19.4343L6.31405 14.4141" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M4.46305 13.5766L1.52147 13.0078" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M1.521 10.0703L1.14062 13.0132" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M5.68063 10.0703L6.31459 12.7412" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M5.24899 7.07812C5.32507 7.27597 5.68009 9.57589 5.68009 9.57589" stroke-width="0.239149"
              stroke-miterlimit="10" />
            <path d="M1.70018 6.20937L3.3992 5.49219" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M6.49312 4.42295L8.59787 3.80469" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M8.57218 3.56318L6.99995 1.90625" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M7.5312 15.0625C7.58192 15.2851 9.43309 17.6345 9.43309 17.6345" stroke-width="0.239149"
              stroke-miterlimit="10" />
            <path d="M9.73843 17.6016L9.12982 21.781" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M10.4738 17.8047C10.3977 17.9036 14.1 18.5961 14.1 18.5961" stroke-width="0.239149"
              stroke-miterlimit="10" />
            <path d="M13.415 21.3184L14.9365 19.7109" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M14.937 17.6057L12.2997 14.8359" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M11.6401 14.4234L9.73822 11.4062" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M17.27 13.7973L12.2997 14.5887L13.4155 11.5469" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M14.1239 10.7065L17.7248 9.29688" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M17.2704 13.4531L19.8062 16.3466" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M18.3094 9.57031L20.3635 11.4004" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M21.352 8.60938L21.0731 11.0824" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M17.7245 8.97647L19.1699 5.24219" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M17.0917 2.35156L18.5625 4.65148" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M14.1239 2.625C14.3268 2.74865 15.9244 5.86467 15.9244 5.86467" stroke-width="0.239149"
              stroke-miterlimit="10" />
            <path d="M14.0227 2.99219L11.9687 6.30605" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M6.49312 5.71094C6.54384 5.7604 10.8801 7.09584 10.8801 7.09584" stroke-width="0.239149"
              stroke-miterlimit="10" />
            <path d="M9.76263 11.0846L10.422 9.84809L11.6392 7.52344" stroke-width="0.239149" stroke-miterlimit="10" />
            <path d="M10.4736 0.945312L13.4152 2.7259" stroke-width="0.239149" stroke-miterlimit="10" />
            <path
              d="M11.6397 9.10254C12.8823 9.10254 13.8712 8.13806 13.8712 6.92627C13.8712 5.71448 12.8823 4.75 11.6397 4.75C10.3971 4.75 9.40814 5.71448 9.40814 6.92627C9.40814 8.13806 10.4225 9.10254 11.6397 9.10254Z" />
            <path
              d="M9.76339 19.0666C10.6002 19.0666 11.2595 18.4236 11.2595 17.6075C11.2595 16.7914 10.6002 16.1484 9.76339 16.1484C8.92656 16.1484 8.26724 16.7914 8.26724 17.6075C8.24188 18.4236 8.92656 19.0666 9.76339 19.0666Z" />
            <path
              d="M9.00329 4.74911C9.68797 4.74911 10.2205 4.20504 10.2205 3.56206C10.2205 2.91907 9.66261 2.375 9.00329 2.375C8.31861 2.375 7.78609 2.91907 7.78609 3.56206C7.78609 4.20504 8.31861 4.74911 9.00329 4.74911Z" />
            <path
              d="M17.7256 10.5354C18.5117 10.5354 19.1457 9.91716 19.1457 9.15052C19.1457 8.38388 18.5117 7.76562 17.7256 7.76562C16.9395 7.76562 16.3056 8.38388 16.3056 9.15052C16.3056 9.91716 16.9395 10.5354 17.7256 10.5354Z" />
            <path
              d="M21.3503 9.10612C21.8575 9.10612 22.2379 8.71044 22.2379 8.24056C22.2379 7.77069 21.8321 7.375 21.3503 7.375C20.8431 7.375 20.4628 7.77069 20.4628 8.24056C20.4628 8.71044 20.8685 9.10612 21.3503 9.10612Z" />
            <path
              d="M1.14113 14.1164C1.77509 14.1164 2.28226 13.6217 2.28226 13.0035C2.28226 12.3852 1.77509 11.8906 1.14113 11.8906C0.507169 11.8906 0 12.3852 0 13.0035C0 13.6217 0.507169 14.1164 1.14113 14.1164Z" />
            <path
              d="M5.68016 10.4132C6.31412 10.4132 6.82129 9.91862 6.82129 9.30037C6.82129 8.68211 6.31412 8.1875 5.68016 8.1875C5.0462 8.1875 4.53903 8.68211 4.53903 9.30037C4.53903 9.91862 5.0462 10.4132 5.68016 10.4132Z" />
            <path
              d="M10.7018 1.87951C11.2344 1.87951 11.6655 1.45909 11.6655 0.939753C11.6655 0.420416 11.2344 0 10.7018 0C10.1693 0 9.73822 0.420416 9.73822 0.939753C9.76358 1.45909 10.1693 1.87951 10.7018 1.87951Z" />
            <path
              d="M2.05371 17.1842C2.56088 17.1842 2.94126 16.7886 2.94126 16.3187C2.94126 15.8241 2.53552 15.4531 2.05371 15.4531C1.54654 15.4531 1.16617 15.8488 1.16617 16.3187C1.14081 16.7886 1.54654 17.1842 2.05371 17.1842Z" />
            <path
              d="M12.8824 22.8261C13.4656 22.8261 13.9474 22.3562 13.9474 21.7874C13.9474 21.2186 13.4656 20.7734 12.8824 20.7734C12.2991 20.7734 11.8173 21.2433 11.8173 21.8121C11.8173 22.3809 12.2991 22.8261 12.8824 22.8261Z" />
            <path
              d="M17.2685 3.34061C17.6742 3.34061 18.0039 3.01912 18.0039 2.62343C18.0039 2.22774 17.6742 1.90625 17.2685 1.90625C16.8628 1.90625 16.5331 2.22774 16.5331 2.62343C16.5331 3.01912 16.8628 3.34061 17.2685 3.34061Z" />
            <path
              d="M1.14165 6.92655C1.54738 6.92655 1.87704 6.60505 1.87704 6.20937C1.87704 5.81368 1.54738 5.49219 1.14165 5.49219C0.73591 5.49219 0.40625 5.81368 0.40625 6.20937C0.40625 6.60505 0.73591 6.92655 1.14165 6.92655Z" />
            <path
              d="M17.27 14.6164C17.8533 14.6164 18.3351 14.1465 18.3351 13.5777C18.3351 13.0089 17.8533 12.5391 17.27 12.5391C16.6868 12.5391 16.205 13.0089 16.205 13.5777C16.205 14.1465 16.6868 14.6164 17.27 14.6164Z" />
          </svg>
          <span>Analyze</span>
        </a>
      </li>


      <li #clientes title="Clientes" routerLink="/cliente-beta" routerLinkActive="active"
        *ngIf="showMenu(tipoMenu.CLIENTE)" (click)="onMenuItemClick(tipoMenu.CLIENTE)">
        <a class="newTab" routerLink="/cliente-beta">
          <i class="fa-regular fa-folder-user client-icon"></i>
          <span>Clientes</span>
        </a>
      </li>

      <li #localidades title="Localidades Beta" routerLinkActive="active" [routerLink]="'/localidade-beta'"
        *ngIf="showMenu(tipoMenu.LOCALIDADE)" [class.disabled]="isActive(localidades)"
        (click)="onMenuItemClick(tipoMenu.LOCALIDADE)">
        <a class="newTab" routerLink="/localidade-beta">
          <i class="fa-regular fa-location-dot localidade"></i>
          <span>Localidades</span>
        </a>
      </li>

      <!-- falta ver permissões -->

      <li #pesquisas title="Avaliação" routerLinkActive="active" [routerLink]="'/avaliacao'"
        *ngIf="showMenu(tipoMenu.PESQUISABETA)" (click)="onMenuItemClick(tipoMenu.PESQUISABETA)">
        <a class="newTab" routerLink="/avaliacao">
          <i class="pesquisas fa-regular fa-file-pen"></i>
          <span>Avaliação</span>
        </a>
      </li>

      <li #resultados title="Resultados" routerLinkActive="active" [routerLink]="'/resultados'"
        *ngIf="showMenu(tipoMenu.RESULTADOS)" (click)="onMenuItemClick(tipoMenu.RESULTADOS)">
        <a class="newTab" routerLink="/resultados">
          <i class="fa-regular fa-chart-pie-simple result-icon"></i>
          <span>Resultados</span>
        </a>
      </li>

      <!-- falta ver permissões -->

      <li #produtividade title="Produtividade" routerLinkActive="active" [routerLink]="'/produtividade-beta'"
        *ngIf="showMenu(tipoMenu.PRODUTIVIDADE)" [class.disabled]="isActive(produtividade)"
        (click)="onMenuItemClick(tipoMenu.PRODUTIVIDADE)">
        <a class="newTab" routerLink="/produtividade-beta">
          <i class="fa-regular fa-file-chart-column produtividade"></i>
          <span>Produtividade</span>
        </a>
      </li>

      <!-- falta ver permissões -->

      <li #auditoria title="Auditoria" routerLinkActive="active" *ngIf="showMenu(tipoMenu.AUDITORIA)"
        [routerLink]="'/auditoria-beta'" (click)="onMenuItemClick(tipoMenu.AUDITORIA)">
        <a class="newTab" routerLink="/auditoria-beta">
          <i class="fa-sharp fa-regular fa-magnifying-glass-chart audit-icon"></i>
          <span>Auditoria</span>
        </a>
      </li>

      <li #grupos [routerLink]="'/grupo'" routerLinkActive="active" title="Grupos de permissão"
        *ngIf="showMenu(tipoMenu.GRUPO)" (click)="onMenuItemClick(tipoMenu.GRUPO)">
        <a class="newTab" routerLink="/grupo">
          <i class="fa-regular fa-ballot-check permissoes"></i>
          <span>Permissões</span>
        </a>
      </li>

      <li #operadores title="Operadores" [routerLink]="'/entrevistador'" routerLinkActive="active"
        *ngIf="showMenu(tipoMenu.OPERADOR)" (click)="onMenuItemClick(tipoMenu.OPERADOR)">
        <a class="newTab" routerLink="/entrevistador">
          <i class="fa-regular fa-street-view operadores"></i>
          <span>Entrevistadores</span>
        </a>
      </li>

      <li #funcionario title="Funcionários" routerLinkActive="active" [routerLink]="'/funcionario-beta'"
        *ngIf="showMenu(tipoMenu.FUNCIONARIO)" (click)="onMenuItemClick(tipoMenu.FUNCIONARIO)">
        <a class="newTab" routerLink="/funcionario-beta">
          <i class="fa-regular fa-id-card-clip funcionario"></i>
          <span>Funcionários</span>
        </a>
      </li>

      <li #contratante [routerLink]="'/contratante-beta'" routerLinkActive="active" title="Contratantes"
        *ngIf="showOptionTenantTensai() && showMenu(tipoMenu.CONTRATANTE)"
        (click)="onMenuItemClick(tipoMenu.CONTRATANTE)">
        <a class="newTab" routerLink="/contratante-beta">
          <i class="fa-regular fa-file-contract contratante"></i>
          <span>Contratante</span>
        </a>
      </li>

      <li #comunicacao routerLink="/comunicacao" routerLinkActive="active" title="Comunicação"
        *ngIf="showMenu(tipoMenu.COMUNICACAO)" (click)="onMenuItemClick(tipoMenu.COMUNICACAO)">
        <a class="newTab" routerLink="comunicacao">
          <i class="fa-regular fa-memo-circle-check comunicacao"></i>
          <span>Comunicação</span>
        </a>
      </li>
    </ul>
  </section>
</nav>
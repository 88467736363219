import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SecurityContext,
  SimpleChanges,
} from "@angular/core";
import { QRCodeComponent } from "angularx-qrcode";
import { QrCodeGeneratorService } from "./qrcode-generator.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Subscription } from "rxjs";

@Component({
  selector: "app-qrcode-generator",
  templateUrl: "./qrcode-generator.component.html",
  styleUrls: ["./qrcode-generator.component.scss"],
})
export class QrCodeGeneratorComponent implements OnInit, OnDestroy {
  constructor(
    private qrCodeService: QrCodeGeneratorService,
    private sanitizer: DomSanitizer
  ) {}

  public sourceLink: string = "";

  @Input() width: number = 500;
  @Input() filename: string = "qrcode.png";

  private subscriptions: Subscription[] = [];

  private qrUrl: SafeUrl = null;

  private pendingRequests = [];

  private getQrUrl() {
    return this.qrUrl;
  }

  private setQrUrl(url: SafeUrl) {
    this.qrUrl = url;
  }

  private startListeners() {
    this.subscriptions.push(
      this.qrCodeService.downloadQrCodeObservable.subscribe(
        this.handleDownloadRequest.bind(this)
      )
    );
  }

  private executePendingJobs() {
    this.pendingRequests.forEach((task) => task());

    // clear all jobs
    this.pendingRequests.length = 0;
  }

  private taskWrapper(url: string, task: () => void) {
    if (url === null) {
      return;
    }

    if (this.isSourceLinkChanged(url)) {
      this.pendingRequests.push(task);
    } else {
      task();
    }

    this.sourceLink = url;
  }

  private handleDownloadRequest(requestedQrUrl: string) {
    const fn = () => {
      const url: string | null = this.sanitizer.sanitize(
        SecurityContext.URL,
        this.getQrUrl()
      );

      const link = document.createElement("a");
      link.href = url as string;
      link.download = this.filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return this.taskWrapper(requestedQrUrl, () => fn());
  }

  ngOnInit(): void {
    this.startListeners();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onChangeURL(url: SafeUrl) {
    this.setQrUrl(url);

    if (!this.isSourceLinkEmpty()) {
      this.executePendingJobs();
    }
  }

  /**
   * Utils
   */
  private isSourceLinkEmpty() {
    return typeof this.sourceLink === "string" && this.sourceLink.length === 0;
  }

  private isSourceLinkChanged(url: string) {
    return this.sourceLink !== url;
  }
}

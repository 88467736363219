import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { FilterConfigs, SearchInputConfig } from "app/util/componente/genericTable/interfaces/filters";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import { TableGlobalConfig } from "app/util/componente/genericTable/interfaces/table";
import { SurveyListSituations, auditSurveyStatus } from "../auditoria-beta/constant";
import { FilterIcon, FilterTypes } from "app/componentes/filter-select/constants";
import { StatusPesquisa } from "../pesquisa-old/status/statusPesquisa";
import { Pesquisa, Stats } from "../pesquisa-beta/listagem/pesquisas.component";
import { iconCellFactory, multiStatusCellFactory, textCellFactory } from "app/util/componente/genericTable/utils/cellFactory";
import { SituacaoPesquisaAuditoriaParser } from "../auditoria-beta/utils/parsers/situacaoPesquisaAuditoriaParser";
import { tRowFactory } from "app/util/componente/genericTable/utils/tRowFactory";
import { PesquisaAuditoriaStatusColorResolver } from "../auditoria-beta/utils/component/statusColorResolver";
import { InfoCardDataProps } from "app/componentes/info-card/info-card.component";

export class ConfiguracoesTabela {
  /**
   * Configurações gerais da tabela
   */
  tableData: TableRow[] = [];

  dadosPaginacao = {
    totalOfEntries: 1,
    current: 1,
    previous: 1,
  };

  tableHead: TableHead[] = [
    {
      type: "text",
      label: "Título da avaliação",
    },
    {
      type: "text",
      label: "Cliente",
    },
    {
      type: "text",
      label: "Presencial",
    },
    {
      type: "text",
      label: "Online",
    },
    {
      type: "text",
      label: "Situação da avaliação",
    },
    {
      type: "text",
      label: "",
    },
  ];

  tableConfig: TableGlobalConfig = {
    colWidths: [".2", ".2", ".1", ".1", ".3", ".1"],
    rowStyles: { "font-size": "14px" },
  };

  searchConfig: SearchInputConfig = {
    delay: 2000,
    placeholder: "Busque pelo título ou nome da avaliação",
  };

  filters: FilterConfigs[];

  dataBreadcrumb: IItemBreadcrumb[] = [
    {
      itemName: "Início",
      itemLink: "/",
      active: false,
    },
    {
      itemName: "Resultados",
      itemLink: `/resultados`,
      active: true,
    },
  ];

  newStatsColumnsReference = {
    totalPesquisaEmAndamento: "Em execução",
    totalPesquisaEncerrada: "Concluídas",
  };

  infoCardData: InfoCardDataProps = {
    data: [],
    title: "Resultados",
    text: "Verifique os resultados de suas avaliações concluídas e em execução",
  };

  /**
   * Inicializa os filtros da tabela de resultados
   */
  inicializarFiltros() {
    const auditoriaOptions = {
      title: "Auditoria",
      data: [
        {
          id: 3,
          label: "Auditada",
          key: SurveyListSituations.AUDITADA,
        },
        {
          id: 4,
          label: "Em auditoria",
          key: SurveyListSituations.INAUDIT,
        },
        {
          id: 5,
          label: "Não auditada",
          key: SurveyListSituations.NOTAUDITED,
        },
      ],
    };

    const pesquisaOptions = {
      type: FilterTypes.COMPOSED_CHECK,
      icon: FilterIcon.FUNNEL,
      placeholder: "Filtrar por",
      composedOptions: [
        {
          title: "Pesquisa",
          data: [
            { id: 1, label: "Concluída", key: StatusPesquisa.CONCLUIDO },
            { id: 2, label: "Em execução", key: StatusPesquisa.EXECUCAO },
          ],
        },
        ,
      ],
    };

    /**
     * O endpoint de listagem de pesquisa não possui integração com filtro pela situação de auditoria no momento
     * portanto, só terá suporte à situação da pesquisa (concluida, etc)
     */
    this.filters = [pesquisaOptions];
  }

  /**
   * Constrói as linhas da tabela baseado no array de operadores
   */
  construisLinhas(pesquisas: Pesquisa[]): TableRow[] {
    return pesquisas.map((pesquisa) => {
      const pesquisaStatusColor = ({
        [StatusPesquisa.CONCLUIDO]: "var(--status-done)",
        [StatusPesquisa.EXECUCAO]: "var(--status-in-progress)"
      })[pesquisa.status];

      const pesquisaAuditoriaStatusColor = PesquisaAuditoriaStatusColorResolver.resolve(pesquisa.statusAuditoria as SurveyListSituations);

      const statusCell = multiStatusCellFactory(
        null,
        ...[
          {
            label: pesquisa.statusTexto,
            color: pesquisaStatusColor,
            backgroundColor: "var(--white)",
          },
          {
            label: SituacaoPesquisaAuditoriaParser.parse(
              pesquisa.statusAuditoria as SurveyListSituations
            ),
            color: pesquisaAuditoriaStatusColor,
            backgroundColor: "var(--white)",
          },
        ]
      ).build();

      const row = tRowFactory(
        textCellFactory(pesquisa.tituloCurto)
          .cellStyleBehavior("UNDERLINE_ON_HOVER")
          .build(),
        textCellFactory(pesquisa.cliente).build(),
        textCellFactory(
          `${pesquisa.entrevistasPresenciaisRealizadas}/${pesquisa.amostrasPresenciais}`
        ).build(),
        textCellFactory(
          `${pesquisa.entrevistasOnlineRealizadas}/${pesquisa.amostrasOnline}`
        ).build(),
        statusCell,
        iconCellFactory(["fa fa-eye"], "button", {
          label: "Visualizar",
          position: "left",
        })
          .alignType("flex-end")
          .customStyles({ fontSize: "16px" })
          .build()
      )
        .id(pesquisa.id)
        .metadata({})
        .build();

      return row;
    });
  }
}

<div
  class="tcell-container"
  [ngClass]="{ underlineOnHover: data.styleBehavior === 'UNDERLINE_ON_HOVER' }"
>
  <ng-container [ngSwitch]="data.type">
    <span
      appTooltipIfEllipsis="{{ data.textValue }}"
      (click)="handleCellClick($event)"
      [ngStyle]="data.customStyles"
      [ngClass]="getTextClassName()"
      class="text-type"
      *ngSwitchCase="'text'"
    >
      {{ data.textValue }}
    </span>

    <ng-container *ngSwitchCase="'icon'">
      <div
        [attr.data-tooltip]="data.iconValue.dataTooltip?.label"
        class="icon-type"
        [ngClass]="getIconClassName()"
        [ngStyle]="data.customStyles"
      >
        <i
          (click)="handleCellClick($event)"
          *ngFor="let iconClass of data.iconValue.icon"
          [ngClass]="iconClass"
        ></i>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'image'">
      <div
        class="image-type"
        [ngStyle]="data.customStyles"
        [ngClass]="data.className"
      >
        <img [src]="data.imageValue.image" />
        <span appTooltipIfEllipsis="{{ data.textValue }}">{{
          data.imageValue.label
        }}</span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'status'">
      <div class="status-type" [ngClass]="data.className">
        <div class="item-status-wrapper" [ngStyle]="data.customStyles">
          <span
            class="status"
            [style.backgroundColor]="data.statusValue.color"
          ></span>
          {{ data.statusValue.label }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'multi-status'">
      <div class="status-type" [ngClass]="data.className">
        <div
          *ngFor="let status of data.multiStatusValue"
          class="item-status-wrapper"
          [ngStyle]="data.customStyles"
          [style.marginRight]="'8px'"
        >
          <span class="status" [style.backgroundColor]="status.color"></span>
          {{ status.label }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'multi-tag'">
      <div class="multiTag-type" [ngClass]="data.className">
        <span
          class="tag-wrapper"
          [style.backgroundColor]="tag.backgroundColor"
          [style.color]="tag.color"
          [ngStyle]="data.customStyles"
          *ngFor="let tag of data.multiTagValue"
        >
          {{ tag.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <div
        class="checkbox-type"
        [ngStyle]="data.customStyles"
        [ngClass]="data.className"
      >
        <app-checkbox
          [isChecked]="data.checked"
          [isDisabled]="!data.checkable"
          (click)="handleCellClick($event)"
        ></app-checkbox>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'meatball'">
      <ng-container [ngSwitch]="meatballConfig.type">
        <app-generic-table-meatball
          #genericMeatball
          *ngSwitchCase="'GENERIC'"
          [options]="meatballConfig.options"
          [rowRef]="rowRef"
          [disabledOptionsIds]="disabledMeatballIds"
          (handleItemClicked)="handleMeatballClick($event)"
        ></app-generic-table-meatball>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'html-template'">
      <div
        [innerHTML]="data.htmlTemplateValue | safeHtml"
        [ngStyle]="data.customStyles"
        [ngClass]="data.className"
      ></div>
    </ng-container>

    <ng-container *ngSwitchCase="'Component'">
      <div #vcr></div>
    </ng-container>
  </ng-container>
</div>

interface StatusColors {
  color: string;
  status: string;
}

export enum TokenType {
  EXECUCAO = "EXECUCAO",
  VISUALIZACAO = "VISUALIZACAO",
}

export const SURVEY_STATUS_COLORS: StatusColors[] = [
  {
    color: "var(--alert200)",
    status: "Arquivada",
  },
  {
    color: "var(--status-sucess)",
    status: "Concluído",
  },
  {
    color: "var(--status-in-progress)",
    status: "Em execução",
  },
  {
    color: "var(--gray200)",
    status: "Rascunho",
  },
];

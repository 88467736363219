import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import { ProdutividadeBetaService } from "../services/produtividade-beta.service";
import { ICardData, IGeneralVisionReponse } from "../interfaces";
import {
  cardDataDefault,
  CardInfoType,
  errorMessages,
  iCardResultsLength,
} from "../constants/visao-geral";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import {
  IOverviewData,
  IResumoAuditoria,
} from "app/modulos/auditoria-beta/interfaces";
import { getAuditResum, getCollectionsPercentage } from "../utils/headerUtils";

@Component({
  selector: "app-visao-geral",
  templateUrl: "./visao-geral.component.html",
  styleUrls: ["./visao-geral.component.scss"],
})
export class VisaoGeralComponent implements OnInit {
  surveyId: number | string;
  isLoading: boolean = false;
  cardType = {
    COLETAS_POR_DIA: CardInfoType.COLETAS_POR_DIA,
    APROVACOES: CardInfoType.APROVACOES,
    TEMPO_MEDIO_COLETA: CardInfoType.TEMPO_MEDIO_COLETA,
  };

  constructor(
    private route: ActivatedRoute,
    private produtividadeService: ProdutividadeBetaService,
    private errorHandler: ErrorHandlerService
  ) {}

  dataBreadcrumb: IItemBreadcrumb[] = [];

  header = null;

  generalVisionData: IGeneralVisionReponse = null;

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.paramMap.get("surveyId");
    this.getHeaderData();
    this.getGeneralVisionData();
  }

  // Atualiza o breadcrumb
  updateBreadcrumb() {
    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Produtividade",
        itemLink: "/produtividade-beta",
        active: false,
      },
      {
        itemName: this.header?.titulo,
        itemLink: `/produtividade-beta/visao-geral/${this.surveyId}`,
        active: true,
      },
    ];
  }

  // recupera os dados do header
  getHeaderData(): void {
    this.produtividadeService.getHeaderData(Number(this.surveyId)).subscribe({
      next: (overview: IOverviewData) => {
        const result = getAuditResum(overview.resumo_coletas.resumo_auditoria);
        const collectionsPercentage: number = getCollectionsPercentage(
          overview.resumo_coletas.efetuadas,
          overview.resumo_coletas.contratadas
        );
        this.header = {
          titulo: overview?.titulo,
          periodo: {
            titulo: "Período de avaliação",
            inicio: overview.data_inicial,
            fim: overview.data_final,
          },
          resumo: {
            titulo: "Resumo da auditoria",
            aprovadas: result.approved,
            reprovadas: result.refused,
          },
          progresso: {
            total: overview.resumo_coletas.contratadas,
            realizadas: overview.resumo_coletas.efetuadas,
            porcentagem: collectionsPercentage,
          },
        };
      },
      error: (err) => {
        this.errorHandler.handleError(err, errorMessages.headerDataError.title);
      },
      complete: () => {
        this.updateBreadcrumb();
      },
    });
  }

  // recupera os dados de visão geral
  getGeneralVisionData() {
    this.isLoading = true;
    this.produtividadeService.getGeneralVisionData(this.surveyId).subscribe({
      next: (data: IGeneralVisionReponse) => {
        this.generalVisionData = data;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.errorHandler.handleError(
          err,
          errorMessages.generalVisionError.title
        );
      },
    });
  }

  formatterDataColetas(): ICardData[] {
    const formatterData: ICardData[] =
      this.generalVisionData?.coletas_por_dia.operadores;

    // inserindo dados para completar a visualização
    while (formatterData.length < iCardResultsLength) {
      formatterData.push(cardDataDefault.collectionsPerDay);
    }

    return formatterData;
  }

  formatterDataAprovacoes(): ICardData[] {
    const formatterData: ICardData[] =
      this.generalVisionData?.aprovacoes.operadores;

    // inserindo dados para completar a visualização
    while (formatterData.length < iCardResultsLength) {
      formatterData.push(cardDataDefault.approvals);
    }

    return formatterData;
  }

  formatterDataTempoMedio(): ICardData[] {
    const formatterData: ICardData[] =
      this.generalVisionData?.tempo_medio_coleta.operadores;

    // inserindo dados para completar a visualização
    while (formatterData.length < iCardResultsLength + 1) {
      formatterData.push(cardDataDefault.averageTime);
    }

    return formatterData;
  }
}

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IQuestion } from "../../../interfaces/audit-question-collections";
import { AuditQuestionManager } from "./services/question-manager.service";
import { TipoPergunta } from "app/modulos/pesquisa-beta/cadastro/steps/pesquisas-questionario/pesquisas-questionario-secoes-pergunta-cadastro/pesquisas-questionario-secoes-pergunta-cadastro.model";

export interface AnswerOutput {
  type: TipoPergunta;
  questionId: number;
  answer: any;
  respondida?: boolean;
}

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
})
export class QuestionsComponent implements OnInit, OnDestroy {
  @Input() questionList: IQuestion[] = [];
  @Input() actionsBlockedBySituation: boolean = false;

  constructor(private questionManager: AuditQuestionManager) {}

  ngOnInit(): void {}

  /**
   * recebe a nova seleção feita pelo usuário
   * @param $event Valor da seleção
   */
  onChangeAnswer($event: AnswerOutput) {
    this.questionManager.update($event);
  }

  /**
   * Limpa as alternativas selecionadas ao mudar o questionario
   */
  ngOnDestroy(): void {
    this.questionManager.reset();
  }
}

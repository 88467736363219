export const infoCardDefaultValue = [
  {
    title: "Pesquisas",
    value: 0,
  },
  {
    title: "Auditadas",
    value: 0,
  },
  {
    title: "Em auditoria",
    value: 0,
  },
  {
    title: "Não auditada",
    value: 0,
  },
];

export const auditSurveyStatus = [
  {
    key: "AUDITADA",
    color: "var(--status-done)",
    status: "Auditada",
  },
  {
    key: "NAO_AUDITADA",
    color: "var(--gray200)",
    status: "Não auditada",
  },
  {
    key: "EM_AUDITORIA",
    color: "var(--status-in-progress)",
    status: "Em auditoria",
  },
  {
    key: "ARQUIVADA",
    color: "var(--alert200)",
    status: "Arquivada",
  },
  {
    key: "APROVADA",
    color: "var(--status-done)",
    status: "Aprovada",
  },
  {
    key: "REPROVADA",
    color: "var(--status-error)",
    status: "Reprovada",
  },
  {
    key: "EM_ANALISE",
    color: "var(--status-in-progress)",
    status: "Em análise",
  },
];

// cores do gráfico
export const colorPalette = ["#04bd00", "#eb5757", "#57b6eb", "#dfdfdf"];

export enum orderTypeEnum {
  ID = "ID",
  OPERADOR = "OPERADOR",
  LOCALIDADE = "LOCALIDADE",
}

export enum SurveyListSituations {
  AUDITADA = "AUDITADA",
  NOTAUDITED = "NAO_AUDITADA",
  INAUDIT = "EM_AUDITORIA",
  ARCHIVED = "ARQUIVADA",
  APROVADA = "APROVADA",
  REPROVADA = "REPROVADA",
  EM_ANALISE = "EM_ANALISE",
}

export enum SurveyListSortingAttributes {
  CLIENTE = "CLIENTE",
  TITULO = "TITULO",
  DATA_INICIAL = "DATA_INICIAL",
  DATA_CADASTRO = "DATA_CADASTRO",
}

export enum CollectionsListSortingAttributes {
  ID = "ID",
  OPERADORES = "OPERADORES",
  LOCALIDADES = "LOCALIDADES",
}

export enum SurveyListOrderTypes {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SituacaoListagemDeColetas {
  APROVADA = "Aprovada",
  EM_ANALISE = "Em análise",
  NAO_AUDITADA = "Não auditada",
  REPROVADA = "Reprovada",
}

export enum SituacaoPesquisa {
  AUDITADA = "AUDITADA",
  EM_AUDITORIA = "EM_AUDITORIA",
  NAO_AUDITADA = "NAO_AUDITADA",
  ARQUIVADA = "ARQUIVADA",
}

export enum CollectionsListSituation {
  APROVADA = "APROVADA",
  EM_ANALISE = "EM_ANALISE",
  NAO_AUDITADA = "NAO_AUDITADA",
  REPROVADA = "REPROVADA",
}

export enum SituacaoListagemDeColetasParams {
  APROVADA = "APROVADA",
  EM_ANALISE = "EM_ANALISE",
  NAO_AUDITADA = "NAO_AUDITADA",
  REPROVADA = "REPROVADA",
}

export enum ListagemDeColetasMenu {
  VER_COLETA = "Ver coleta",
  VER_OBSERVACOES = "Ver observações",
  VER_NO_MAPA = "Ver no mapa",
}

export enum AuditProgressInfo {
  VAMOS_COMECAR = "Vamos começar",
  AVANCANDO = "Estamos avançando!",
  FALTA_POUCO = "Falta pouco!",
  CONCLUIDA = "Auditoria concluída!",
}

export enum AuditConclusionText {
  INCOMPLETE = "Audite mais coletas para permitir a conclusão desta avaliação",
  CAN_CONCLUDE = "A auditoria desta avaliação já pode ser concluída!",
  CONCLUDE = "A auditoria da avaliação foi concluída com sucesso!",
}

export const surveyListSituationView = {
  AUDITADA: "Auditada",
  NAO_AUDITADA: "Não auditada",
  EM_AUDITORIA: "Em auditoria",
  ARQUIVADA: "Arquivada",
  APROVADA: "Aprovada",
  REPROVADA: "Reprovada",
  EM_ANALISE: "Em análise",
};

export const MINIUM_ELEMENTS_FOR_CONSOLIDATION = 2;
